/** @format */
export const jobCode = [
  {
    id: 1,
    label: "WATER LINES TESTED TO 150PSI",
    name: "WATER_LINES_TESTED_TO_150_PSI",
    value: "WATER_LINES_TESTED_TO_150_PSI",
    status: true,
    type: "text",
    formCode: "",
  },
  {
    id: 2,
    label: "DRAIN TEST",
    name: "DRAIN_TEST",
    value: "DRAIN_TEST",
    status: true,
    type: "radio",
  },
];
export const fakeUser = [
  {
    id: 1,
    name: "Leanne Graham",
    username: "Bret",
    email: "Sincere@april.biz",
    address: {
      street: "Kulas Light",
      suite: "Apt. 556",
      city: "Gwenborough",
      zipcode: "92998-3874",
      geo: {
        lat: "-37.3159",
        lng: "81.1496",
      },
    },
    phone: "1-770-736-8031 x56442",
    website: "hildegard.org",
    company: {
      name: "Romaguera-Crona",
      catchPhrase: "Multi-layered client-server neural-net",
      bs: "harness real-time e-markets",
    },
  },
  {
    id: 2,
    name: "Ervin Howell",
    username: "Antonette",
    email: "Shanna@melissa.tv",
    address: {
      street: "Victor Plains",
      suite: "Suite 879",
      city: "Wisokyburgh",
      zipcode: "90566-7771",
      geo: {
        lat: "-43.9509",
        lng: "-34.4618",
      },
    },
    phone: "010-692-6593 x09125",
    website: "anastasia.net",
    company: {
      name: "Deckow-Crist",
      catchPhrase: "Proactive didactic contingency",
      bs: "synergize scalable supply-chains",
    },
  },
  {
    id: 3,
    name: "Clementine Bauch",
    username: "Samantha",
    email: "Nathan@yesenia.net",
    address: {
      street: "Douglas Extension",
      suite: "Suite 847",
      city: "McKenziehaven",
      zipcode: "59590-4157",
      geo: {
        lat: "-68.6102",
        lng: "-47.0653",
      },
    },
    phone: "1-463-123-4447",
    website: "ramiro.info",
    company: {
      name: "Romaguera-Jacobson",
      catchPhrase: "Face to face bifurcated interface",
      bs: "e-enable strategic applications",
    },
  },
  {
    id: 4,
    name: "Patricia Lebsack",
    username: "Karianne",
    email: "Julianne.OConner@kory.org",
    address: {
      street: "Hoeger Mall",
      suite: "Apt. 692",
      city: "South Elvis",
      zipcode: "53919-4257",
      geo: {
        lat: "29.4572",
        lng: "-164.2990",
      },
    },
    phone: "493-170-9623 x156",
    website: "kale.biz",
    company: {
      name: "Robel-Corkery",
      catchPhrase: "Multi-tiered zero tolerance productivity",
      bs: "transition cutting-edge web services",
    },
  },
  {
    id: 5,
    name: "Chelsey Dietrich",
    username: "Kamren",
    email: "Lucio_Hettinger@annie.ca",
    address: {
      street: "Skiles Walks",
      suite: "Suite 351",
      city: "Roscoeview",
      zipcode: "33263",
      geo: {
        lat: "-31.8129",
        lng: "62.5342",
      },
    },
    phone: "(254)954-1289",
    website: "demarco.info",
    company: {
      name: "Keebler LLC",
      catchPhrase: "User-centric fault-tolerant solution",
      bs: "revolutionize end-to-end systems",
    },
  },
  {
    id: 6,
    name: "Mrs. Dennis Schulist",
    username: "Leopoldo_Corkery",
    email: "Karley_Dach@jasper.info",
    address: {
      street: "Norberto Crossing",
      suite: "Apt. 950",
      city: "South Christy",
      zipcode: "23505-1337",
      geo: {
        lat: "-71.4197",
        lng: "71.7478",
      },
    },
    phone: "1-477-935-8478 x6430",
    website: "ola.org",
    company: {
      name: "Considine-Lockman",
      catchPhrase: "Synchronised bottom-line interface",
      bs: "e-enable innovative applications",
    },
  },
  {
    id: 7,
    name: "Kurtis Weissnat",
    username: "Elwyn.Skiles",
    email: "Telly.Hoeger@billy.biz",
    address: {
      street: "Rex Trail",
      suite: "Suite 280",
      city: "Howemouth",
      zipcode: "58804-1099",
      geo: {
        lat: "24.8918",
        lng: "21.8984",
      },
    },
    phone: "210.067.6132",
    website: "elvis.io",
    company: {
      name: "Johns Group",
      catchPhrase: "Configurable multimedia task-force",
      bs: "generate enterprise e-tailers",
    },
  },
  {
    id: 8,
    name: "Nicholas Runolfsdottir V",
    username: "Maxime_Nienow",
    email: "Sherwood@rosamond.me",
    address: {
      street: "Ellsworth Summit",
      suite: "Suite 729",
      city: "Aliyaview",
      zipcode: "45169",
      geo: {
        lat: "-14.3990",
        lng: "-120.7677",
      },
    },
    phone: "586.493.6943 x140",
    website: "jacynthe.com",
    company: {
      name: "Abernathy Group",
      catchPhrase: "Implemented secondary concept",
      bs: "e-enable extensible e-tailers",
    },
  },
  {
    id: 9,
    name: "Glenna Reichert",
    username: "Delphine",
    email: "Chaim_McDermott@dana.io",
    address: {
      street: "Dayna Park",
      suite: "Suite 449",
      city: "Bartholomebury",
      zipcode: "76495-3109",
      geo: {
        lat: "24.6463",
        lng: "-168.8889",
      },
    },
    phone: "(775)976-6794 x41206",
    website: "conrad.com",
    company: {
      name: "Yost and Sons",
      catchPhrase: "Switchable contextually-based project",
      bs: "aggregate real-time technologies",
    },
  },
  {
    id: 10,
    name: "Clementina DuBuque",
    username: "Moriah.Stanton",
    email: "Rey.Padberg@karina.biz",
    address: {
      street: "Kattie Turnpike",
      suite: "Suite 198",
      city: "Lebsackbury",
      zipcode: "31428-2261",
      geo: {
        lat: "-38.2386",
        lng: "57.2232",
      },
    },
    phone: "024-648-3804",
    website: "ambrose.net",
    company: {
      name: "Hoeger LLC",
      catchPhrase: "Centralized empowering task-force",
      bs: "target end-to-end models",
    },
  },
];
export const qcTRIM002 = [
  {
    id: 2,
    label: "EQUIPMENT SET PROPERLY & LEVEL",
    name: "EQUIPMENT_SET_PROPERLY_&_LEVEL",
    value: "EQUIPMENT_SET_PROPERLY_&_LEVEL",
    status: true,
    type: "radio",
  },
  {
    id: 3,
    label: "PROPANE KIT INSTALLED IF REQUIRED",
    name: "PROPANE_KIT_INSTALLED_IF_REQUIRED",
    value: "PROPANE_KIT_INSTALLED_IF_REQUIRED",
    status: true,
    type: "radio",
  },
  {
    id: 4,
    label: "FLUES CONNECTED & TERMINATED / BIRD SCREEN IN TERMINATION",
    name: "FLUES_CONNECTED_&_TERMINATED___BIRD_SCREEN_IN_TERMINATION",
    value: "FLUES_CONNECTED_&_TERMINATED___BIRD_SCREEN_IN_TERMINATION",
    status: true,
    type: "radio",
  },
  {
    id: 5,
    label: "UNESET TIED IN and COMPLETE - ARMAFLEX CORRECT",
    name: "UNESET_TIED_IN_and_COMPLETE___ARMAFLEX_CORRECT",
    value: "UNESET_TIED_IN_and_COMPLETE___ARMAFLEX_CORRECT",
    status: true,
    type: "radio",
  },
  {
    id: 6,
    label: "ALL KNOCK-OUTS REMOVED",
    name: "ALL_KNOCK_OUTS_REMOVED",
    value: "ALL_KNOCK_OUTS_REMOVED",
    status: true,
    type: "radio",
  },
  {
    id: 7,
    label: "PAN SWITCH INSTALLED",
    name: "PAN_SWITCH_INSTALLED",
    value: "PAN_SWITCH_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 8,
    label: "EMERGENCY DRAIN PAN POSITIONED CORRECTLY/PITCHED",
    name: "EMERGENCY_DRAIN_PAN_POSITIONED_CORRECTLY_PITCHED",
    value: "EMERGENCY_DRAIN_PAN_POSITIONED_CORRECTLY_PITCHED",
    status: true,
    type: "radio",
  },
  {
    id: 9,
    label: "DRAIN CONNECTED PROPERLY ON EQUIP. & DRAIN PAN",
    name: "DRAIN_CONNECTED_PROPERLY_ON_EQUIP._&_DRAIN_PAN",
    value: "DRAIN_CONNECTED_PROPERLY_ON_EQUIP._&_DRAIN_PAN",
    status: true,
    type: "radio",
  },
  {
    id: 10,
    label: "EZ TRAP INSTALLED",
    name: "EZ_TRAP_INSTALLED",
    value: "EZ_TRAP_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 11,
    label: "DRAIN PITCHED IN PROPER DIRECTION AND SECURE/TEST WITH WATER",
    name: "DRAIN_PITCHED_IN_PROPER_DIRECTION_AND_SECURE_TEST_WITH_WATER",
    value: "DRAIN_PITCHED_IN_PROPER_DIRECTION_AND_SECURE_TEST_WITH_WATER",
    status: true,
    type: "radio",
  },
  {
    id: 12,
    label: "THERMOSTAT - WIRED - LEVEL",
    name: "THERMOSTAT___WIRED___LEVEL",
    value: "THERMOSTAT___WIRED___LEVEL",
    status: true,
    type: "radio",
  },
  {
    id: 13,
    label: "CHESAPEAKE STICKER APPLIED TO UNIT",
    name: "CHESAPEAKE_STICKER_APPLIED_TO_UNIT",
    value: "CHESAPEAKE_STICKER_APPLIED_TO_UNIT",
    status: true,
    type: "radio",
  },
  {
    id: 14,
    label: "ALL DOOR PANELS RE-INSTALLED CORRECTLY",
    name: "ALL_DOOR_PANELS_RE_INSTALLED_CORRECTLY",
    value: "ALL_DOOR_PANELS_RE_INSTALLED_CORRECTLY",
    status: true,
    type: "radio",
  },
  {
    id: 15,
    label: "GAS VALVE SET TO PROPER MANIFOLD PRESSURE AND PLUG REINSTALLED",
    name: "GAS_VALVE_SET_TO_PROPER_MANIFOLD_PRESSURE_AND_PLUG_REINSTALLED",
    value: "GAS_VALVE_SET_TO_PROPER_MANIFOLD_PRESSURE_AND_PLUG_REINSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 16,
    label: "OUTDOOR UNIT PAD / BRACKET LEVEL",
    name: "OUTDOOR_UNIT_PAD___BRACKET_LEVEL",
    value: "OUTDOOR_UNIT_PAD___BRACKET_LEVEL",
    status: true,
    type: "radio",
  },
  {
    id: 17,
    label: "SNOW LEGS ON HEAT PUMP",
    name: "SNOW_LEGS_ON_HEAT_PUMP",
    value: "SNOW_LEGS_ON_HEAT_PUMP",
    status: true,
    type: "radio",
  },
  {
    id: 18,
    label: "NITROGEN TESTED - LIST PRESSURE APPLIED",
    name: "NITROGEN_TESTED___LIST_PRESSURE_APPLIED",
    value: "NITROGEN_TESTED___LIST_PRESSURE_APPLIED",
    status: true,
    type: "radio",
  },
  {
    id: 19,
    label: "VACUUMED DOWN - TO 500 MICRONS",
    name: "VACUUMED_DOWN___TO_500_MICRONS",
    value: "VACUUMED_DOWN___TO_500_MICRONS",
    status: true,
    type: "radio",
  },
  {
    id: 20,
    label: "ADDED/ADJUSTED CHARGE-LIST AMOUNT ADDED/ADJUSTED",
    name: "ADDED_ADJUSTED_CHARGE_LIST_AMOUNT_ADDED_ADJUSTED",
    value: "ADDED_ADJUSTED_CHARGE_LIST_AMOUNT_ADDED_ADJUSTED",
    status: true,
    type: "radio",
  },
  {
    id: 21,
    label: "PROVIDE SUCTION PRESSURE / HEAD PRESSURE READINGS",
    name: "PROVIDE_SUCTION_PRESSURE___HEAD_PRESSURE_READINGS",
    value: "PROVIDE_SUCTION_PRESSURE___HEAD_PRESSURE_READINGS",
    status: true,
    type: "radio",
  },
  {
    id: 22,
    label: "ARMAFLEX INSTALLED COMPLETELY TO BOTH ENDS WITH NO TEARS",
    name: "ARMAFLEX_INSTALLED_COMPLETELY_TO_BOTH_ENDS_WITH_NO_TEARS",
    value: "ARMAFLEX_INSTALLED_COMPLETELY_TO_BOTH_ENDS_WITH_NO_TEARS",
    status: true,
    type: "radio",
  },
  {
    id: 23,
    label: "EXTERIOR VENTING WALL CAPS STRAIGHT AND SECURE",
    name: "EXTERIOR_VENTING_WALL_CAPS_STRAIGHT_AND_SECURE",
    value: "EXTERIOR_VENTING_WALL_CAPS_STRAIGHT_AND_SECURE",
    status: true,
    type: "radio",
  },
  {
    id: 24,
    label: "ALL FURNACE VENT PIPING SEALED AND SECURED WITH BIRD SCREEN",
    name: "ALL_FURNACE_VENT_PIPING_SEALED_AND_SECURED_WITH_BIRD_SCREEN",
    value: "ALL_FURNACE_VENT_PIPING_SEALED_AND_SECURED_WITH_BIRD_SCREEN",
    status: true,
    type: "radio",
  },
  {
    id: 25,
    label: " PLACE A STICKER ON THE FURNACE OR AC UNIT",
    name: "_PLACE_A_STICKER_ON_THE_FURNACE_OR_AC_UNIT",
    value: "_PLACE_A_STICKER_ON_THE_FURNACE_OR_AC_UNIT",
    status: true,
    type: "radio",
  },
  {
    id: 26,
    label: "HVAC FINAL INSPECTION",
    name: "HVAC_FINAL_INSPECTION",
    value: "HVAC_FINAL_INSPECTION",
    status: true,
    type: "radio",
  },
  {
    id: 27,
    label: "T-STAT MAKE, MODEL#, SERIAL#",
    name: "T_STAT_MAKE__MODEL#__SERIAL#",
    value: "T_STAT_MAKE__MODEL#__SERIAL#",
    status: true,
    type: "radio",
  },
  {
    id: 28,
    label: "EVAPORATOR COIL MAKE, MODEL #, SERIAL #",
    name: "EVAPORATOR_COIL_MAKE__MODEL_#__SERIAL_#",
    value: "EVAPORATOR_COIL_MAKE__MODEL_#__SERIAL_#",
    status: true,
    type: "radio",
  },
  {
    id: 29,
    label: "INDOOR MAKE, MODEL AND SERIAL #",
    name: "INDOOR_MAKE__MODEL_AND_SERIAL_#",
    value: "INDOOR_MAKE__MODEL_AND_SERIAL_#",
    status: true,
    type: "radio",
  },
  {
    id: 30,
    label: "OUTDOOR MAKE, MODEL AND SERIAL #",
    name: "OUTDOOR_MAKE__MODEL_AND_SERIAL_#",
    value: "OUTDOOR_MAKE__MODEL_AND_SERIAL_#",
    status: true,
    type: "radio",
  },
  {
    id: 31,
    label: "DEHUMIDIFER MAKE, MODEL AND SERIAL #",
    name: "DEHUMIDIFER_MAKE__MODEL_AND_SERIAL_#",
    value: "DEHUMIDIFER_MAKE__MODEL_AND_SERIAL_#",
    status: true,
    type: "radio",
  },
  {
    id: 32,
    label: "V AIRFLOW SET PROPERLY",
    name: "V_AIRFLOW_SET_PROPERLY",
    value: "V_AIRFLOW_SET_PROPERLY",
    status: true,
    type: "radio",
  },
  {
    id: 33,
    label: "ZONE DAMPER/BOARD/INSTALLED",
    name: "ZONE_DAMPER_BOARD_INSTALLED",
    value: "ZONE_DAMPER_BOARD_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 34,
    label: "1st STAGE GAS PRESSURE",
    name: "1st_STAGE_GAS_PRESSURE",
    value: "1st_STAGE_GAS_PRESSURE",
    status: true,
    type: "radio",
  },
  {
    id: 35,
    label: "2ND STAGE GAS PRESSURE",
    name: "2ND_STAGE_GAS_PRESSURE",
    value: "2ND_STAGE_GAS_PRESSURE",
    status: true,
    type: "radio",
  },
  {
    id: 36,
    label: "START SHEET COMPLETED?",
    name: "START_SHEET_COMPLETED?",
    value: "START_SHEET_COMPLETED?",
    status: true,
    type: "radio",
  },
  {
    id: 37,
    label: "",
    name: "",
    value: "",
    status: true,
    type: "radio",
  },
];
export const qcFormRoughIn = [
  {
    id: 1,
    label: "WATER LINES TESTED TO 150PSI",
    name: "WATER_LINES_TESTED_TO_150_PSI",
    value: "WATER_LINES_TESTED_TO_150_PSI",
    status: true,
    type: "text",
    formCode: "",
  },
  {
    id: 2,
    label: "DRAIN TEST",
    name: "DRAIN_TEST",
    value: "DRAIN_TEST",
    status: true,
    type: "radio",
  },
  {
    id: 3,
    label: "ALL TUBS / SHOWERS TRAPPED",
    name: "ALL_TUBS_SHOWERS_TRAPPED",
    value: "ALL_TUBS_SHOWERS_TRAPPED",
    status: true,
    type: "radio",
  },
  {
    id: 4,
    label: "ALL SHOWER HEADS TIGHT",
    name: "ALL_SHOWER_HEADS_TIGHT",
    value: "ALL_SHOWER_HEADS_TIGHT",
    status: true,
    type: "radio",
  },
  {
    id: 5,
    label: "HOT ON LEFT / COLD ON RIGHT",
    name: "HOT_ON_LEFT_COLD_ON_RIGHT",
    value: "HOT_ON_LEFT_COLD_ON_RIGHT",
    status: true,
    type: "radio",
  },
  {
    id: 6,
    label: "VALVES ON ALL TUBS & SHOWERS - IF APPLICABLE",
    name: "VALVES_ON_ALL_TUBS_SHOWERS_IF_APPLICABLE",
    value: "VALVES_ON_ALL_TUBS_SHOWERS_IF_APPLICABLE",
    status: true,
    type: "radio",
  },
  {
    id: 7,
    label: "SHOWER DIVERTER MOUNTED RIGHT SIDE UP",
    name: "SHOWER_DIVERTER_MOUNTED_RIGHT_SIDE_UP",
    value: "SHOWER_DIVERTER_MOUNTED_RIGHT_SIDE_UP",
    status: true,
    type: "radio",
  },
  {
    id: 8,
    label: "ALL T/S'S & R/T'S - TUB PROTECTOR SECURED WITH TAPE",
    name: "ALL_TS_S_R_TsTUB_PROTECTOR_SECURED_WITH_TAPE",
    value: "ALL_TS_S_R_TsTUB_PROTECTOR_SECURED_WITH_TAPE",
    status: true,
    type: "radio",
  },
  {
    id: 9,
    label: "TUBS ARE INSTALLED TO MANUFACTURERS SPECIFICATIONS",
    name: "TUBS_ARE_INSTALLED_TO_MANUFACTURERS_SPECIFICATIONS",
    value: "TUBS_ARE_INSTALLED_TO_MANUFACTURERS_SPECIFICATIONS",
    status: true,
    type: "radio",
  },
  {
    id: 10,
    label: "WATER MAIN PACK JOINT CLAMP INSPECTED",
    name: "WATER_MAIN_PACK_JOINT_CLAMP_INSPECTED",
    value: "WATER_MAIN_PACK_JOINT_CLAMP_INSPECTED",
    status: true,
    type: "radio",
  },
  {
    id: 11,
    label: "DRAINPIPE STUBBED OUTSIDE FOOTER &CAPPED",
    name: "DRAIN_PIPE_STUBBED_OUTSIDE_FOOTER_CAPPED",
    value: "DRAIN_PIPE_STUBBED_OUTSIDE_FOOTER_CAPPED",
    status: true,
    type: "radio",
  },
  {
    id: 12,
    label: "'4' MAIN DRAIN / FOUNDATION SEALED",
    name: "_4_MAIN_DRAIN_FOUNDATION_SEALED",
    value: "_4_MAIN_DRAIN_FOUNDATION_SEALED",
    status: true,
    type: "radio",
  },
  {
    id: 13,
    label: "ALL VENTS GLUED & THROUGH ROOF",
    name: "ALL_VENTS_GLUED_THROUGH_ROOF",
    value: "ALL_VENTS_GLUED_THROUGH_ROOF",
    status: true,
    type: "radio",
  },
  {
    id: 14,
    label: "ROOF BOOTS SUPPLIED IN GARAGE",
    name: "ROOF_BOOTS_SUPPLIED_IN_GARAGE",
    value: "ROOF_BOOTS_SUPPLIED_IN_GARAGE",
    status: true,
    type: "radio",
  },
  {
    id: 15,
    label: "ALL RISERS ADEQUATELY STRAPPED",
    name: "ALL_RISERS_ADEQUATELY_STRAPPED",
    value: "ALL_RISERS_ADEQUATELY_STRAPPED",
    status: true,
    type: "radio",
  },
  {
    id: 16,
    label: "CHECKED ALL NAIL PLATES",
    name: "CHECKED_ALL_NAIL_PLATES",
    value: "CHECKED_ALL_NAIL_PLATES",
    status: true,
    type: "radio",
  },
  {
    id: 17,
    label: "ALL HOSE BIBS INSTALLED",
    name: "ALL_HOSE_BIBS_INSTALLED",
    value: "ALL_HOSE_BIBS_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 18,
    label: "ALL TOILET FLANGES RAISED IF NECESSARY TO PROPER FLOORING FINISH",
    name: "ALL_TOILET_FLANGES_RAISED_IF_NECESSARY_TO_PROPER_FLOORING_FINISH",
    value: "ALL_TOILET_FLANGES_RAISED_IF_NECESSARY_TO_PROPER_FLOORING_FINISH",
    status: true,
    type: "radio",
  },
  {
    id: 19,
    label:
      "TOILET FLANGES SET TO PROPER HEIGHT IN ACCORDANCE WITH SELECTION SHEETS",
    name: "TOILET_FLANGES_SET_TO_PROPER_HEIGHT_IN_ACCORDANCE_WITH_SELECTION_SHEETS",
    value:
      "      TOILET_FLANGES_SET_TO_PROPER_HEIGHT_IN_ACCORDANCE_WITH_SELECTION_SHEETS",
    status: true,
    type: "radio",
  },
  {
    id: 20,
    label: "'KITCHEN C/O & ALL OTHER 3' & 4 'C/O WHERE APPLICABLE'",
    name: "KITCHEN_C_O_ALL_OTHER_3_4_C_O_WHERE_APPLICABLE",
    value: "KITCHEN_C_O_ALL_OTHER_3_4_C_O_WHERE_APPLICABLE",
    status: true,
    type: "radio",
  },
  {
    id: 21,
    label: "V DRAINS STRAPPED EVERY 4'",
    name: "V_DRAINS_STRAPPED_EVERY_4",
    value: "V_DRAINS_STRAPPED_EVERY_4",
    status: true,
    type: "radio",
  },
  {
    id: 22,
    label: "CLEAN OUTS PLUGGED",
    name: "CLEAN_OUTS_PLUGGED",
    value: "CLEAN_OUTS_PLUGGED",
    status: true,
    type: "radio",
  },
  {
    id: 23,
    label: "POWER VENT FOR WATER HEATER COMPLETE",
    type: "",
    name: "POWER_VENT_FOR_WATER_HEATER_COMPLETE",
    value: "POWER_VENT_FOR_WATER_HEATER_COMPLETE",
    status: true,
  },
  {
    id: 24,
    label: "SUMP PUMP STUBBED DOWN & TURNED OUT",
    type: "",
    name: "SUMP_PUMP_STUBBED_DOWN_TURNED_OUT",
    value: "SUMP_PUMP_STUBBED_DOWN_TURNED_OUT",
    status: true,
  },
  {
    id: 25,
    label: "WATER HEATER DRAIN COMPLETE",
    type: "",
    name: "WATER_HEATER_DRAIN_COMPLETE",
    value: "WATER_HEATER_DRAIN_COMPLETE",
    status: true,
  },
  {
    id: 26,
    label: "EMERGENCY DRAIN FOR WASHER COMPLETE",
    type: "",
    name: "EMERGENCY_DRAIN_FOR_WASHER_COMPLETE",
    value: "EMERGENCY_DRAIN_FOR_WASHER_COMPLETE",
    status: true,
  },
  {
    id: 27,
    label: "WASHER BOX TRIM ACCOUNTED FOR",
    type: "",
    name: "WASHER_BOX_TRIM_ACCOUNTED_FOR",
    value: "WASHER_BOX_TRIM_ACCOUNTED_FOR",
    status: true,
  },
  {
    id: 28,
    label: "ICE MAKER TRIM ACCOUNTED FOR",
    type: "",
    name: "ICE_MAKER_TRIM_ACCOUNTED_FOR",
    value: "ICE_MAKER_TRIM_ACCOUNTED_FOR",
    status: true,
  },
  {
    id: 29,
    label: "✓ WASTE & OVERFLOW TRIMS ACCOUNTED FOR & FINISH IS CORRECT",
    type: "",
    name: "WASTE_OVER_FLOW_TRIMS_ACCOUNTED_FOR_FINISH_IS_CORRECT",
    value: "WASTE_OVER_FLOW_TRIMS_ACCOUNTED_FOR_FINISH_IS_CORRECT",
    status: true,
  },
  {
    id: 30,
    label: "TEST TEE'S PLUGGED - IF APPLICABLE",
    type: "",
    name: "TEST_TEE_S_PLUGGED_IF_APPLICABLE",
    value: "TEST_TEE_S_PLUGGED_IF_APPLICABLE",
    status: true,
  },
  {
    id: 31,
    label:
      "OUTSIDE SHOWERS STUBBED OUT WITH WING 90'S UNLESS HOME IS ON CRAWL SPACE BLOCKING INSTALLED FOR WALL HUNG SINKS, WATER COOLERS, MOP SINKS, ETC. LAUNDRY SINK ROUGH-IN ONLY IS DRAIN-CAPPED OFF",
    type: "",
    name: "OUTSIDE_SHOWERS_STUBBED_OUT_WITH_WING_90",
    value: "OUTSIDE_SHOWERS_STUBBED_OUT_WITH_WING_90",
    status: true,
  },
  {
    id: 32,
    label: "WATER MAIN PACK JOINTS CHECKED; LOCK-NUT IS BRASS TO BRASS",
    type: "",
    name: "WATER_MAIN_PACK_JOINTS_CHECKED",
    value: "WATER_MAIN_PACK_JOINTS_CHECKED",
    status: true,
  },
  {
    id: 33,
    label:
      "ALL PLUMBING AREAS SWEPT CLEAN & PACKING/CUTTINGS PROPERLY DISPOSED OF ALL MATERIAL REMOVED FROM SITE (MATERIAL LEFT IN GARAGE IS UNACCEPTABLE) CHECK FALL ON 2 'DRAIN LINES'",
    type: "",
    name: "ALL_PLUMBING_AREAS_SWEPT_CLEAN_PACKING_CUTTINGS_PROPERLY ",
    value: "ALL_PLUMBING_AREAS_SWEPT_CLEAN_PACKING_CUTTINGS_PROPERLY",
    status: true,
  },
  {
    id: 34,
    label: "MAKE SURE TUBS HAVE CLIPS AND NOT SCREWED THROUGH FLANGE",
    type: "",
    name: "MAKE_SURE_TUBS_HAVE_CLIPS_AND_NOT_SCREWED_THROUGH_FLANGE",
    value: "MAKE_SURE_TUBS_HAVE_CLIPS_AND_NOT_SCREWED_THROUGH_FLANGE",
    status: true,
  },
];
// export const fcTrim = [
//   {
//     id: 1,
//     label: "LAVATORIES-$20.00",
//     text: "LAV",
//     name: "lavatories_$20.00",
//     value: "",
//   },
//   {
//     id: 2,
//     label: "PEDESTAL LAVATORIES-$30.00",
//     name: "pedestal_lavatories_$30.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 3,
//     label: "WATER CLOSETS-$20.00",
//     name: "water_closets_$20.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 4,
//     label: "SHOWERS-$15.00",
//     name: "showers_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 5,
//     label: "TUB/SHOWERS-$20.00",
//     name: "tub_showers_$20.00",
//     value: "",
//     status: true,
//   },
//   { id: 6, label: "WATER MAIN", name: "water_main", value: "", status: true },
//   {
//     id: 7,
//     label: "WASHER BOX, EMERGENCY PAN INCL-$10.00",
//     name: "washer_box,_emergency_pan_incl_$10.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 8,
//     label: "ROMAN TUB-$15.00",
//     name: "roman_tub_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 9,
//     label: "KITCHEN-$25.00",
//     name: "kitchen_$25.00",
//     value: "",
//     status: true,
//   },
//   { id: 10, label: "SUMP PUMP", name: "sump_pump", value: "", status: true },
//   {
//     id: 11,
//     label: "OUTDOOR SHOWER-$30.00",
//     name: "outdoor_shower_$30.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 12,
//     label: "WET BAR-$20.00",
//     name: "wet_bar_$20.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 13,
//     label: "SEWER EJECTOR PIT",
//     name: "sewer_ejector_pit",
//     value: "",
//     status: true,
//   },
//   {
//     id: 14,
//     label: "SEWER EJECTOR PUMP",
//     name: "sewer_ejector_pump",
//     value: "",
//     status: true,
//   },
//   {
//     id: 15,
//     label: "AREAWAY DRAIN",
//     name: "areaway_drain",
//     value: "",
//     status: true,
//   },
//   {
//     id: 16,
//     label: "LAUNDRY TUB-$20.00",
//     name: "laundry_tub_$20.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 17,
//     label: "WINDOW WELL",
//     name: "window_well",
//     value: "",
//     status: true,
//   },
//   {
//     id: 18,
//     label: "ELECTRIC WATER HEATER-$30.00",
//     name: "electric_water_heater_$30.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 19,
//     label: "GAS WATER HEATER-$35.00",
//     name: "gas_water_heater_$35.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 20,
//     label: "K-LESS W/H-HANG VENT /H20 TIE IN-$40.00",
//     name: "k_less_w_h_hang_vent_h20_tie_in_$40.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 21,
//     label: "LAWN IRRIGATION-$15.00",
//     name: "lawn_irrigation_$15.00",
//     value: "",
//     status: true,
//   },
//   { id: 22, label: "RADON", name: "radon", value: "", status: true },
//   {
//     id: 23,
//     label: "CRAWL SPRINKLER-$5.00",
//     name: "crawl_sprinkler_$5.00",
//     value: "",
//     status: true,
//   },
//   { id: 24, label: "HOSE BIB", name: "hose_bib", value: "", status: true },
//   {
//     id: 25,
//     label: "APOLLO THERMOSTATIC MIXING VALVE-$15.00",
//     name: "apollo_thermostatic_mixing_valve_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 26,
//     label: "LAMP HOLE COVER-$10.00",
//     name: "lamp_hole_cover_$10.00",
//     value: "",
//     status: true,
//   },
//   { id: 27, label: "POT FILLER", name: "pot_filler", value: "", status: true },
//   {
//     id: 28,
//     label: "HANDHELDS-$5.00",
//     name: "handhelds_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 29,
//     label: "RAIN HEADS-$5.00",
//     name: "rain_heads_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 30,
//     label: "BODY SPRAYS-$5.00 per head",
//     name: "body_sprays_$5.00_per_head",
//     value: "",
//     status: true,
//   },
//   {
//     id: 31,
//     label: "FURNACE GAS DROP TEST",
//     name: "furnace_gas_drop_test",
//     value: "",
//     status: true,
//   },
//   {
//     id: 32,
//     label: "FURNACE GAS DROP TIE IN",
//     name: "furnace_gas_drop_tie_in",
//     value: "",
//     status: true,
//   },
//   {
//     id: 33,
//     label: "FIRE PLACE GAS DROP TEST",
//     name: "fire_place_gas_drop_test",
//     value: "",
//     status: true,
//   },
//   {
//     id: 34,
//     label: "FIRE PLACE GAS DROP TIE IN",
//     name: "fire_place_gas_drop_tie_in",
//     value: "",
//     status: true,
//   },
//   {
//     id: 35,
//     label: "WATER HEATER GAS DROP TEST",
//     name: "water_heater_gas_drop_test",
//     value: "",
//     status: true,
//   },
//   {
//     id: 36,
//     label: "WATER HEATER GAS DROP TIE IN",
//     name: "water_heater_gas_drop_tie_in",
//     value: "",
//     status: true,
//   },
//   {
//     id: 37,
//     label: "STOVE GAS DROP TEST",
//     name: "stove_gas_drop_test",
//     value: "",
//     status: true,
//   },
//   {
//     id: 38,
//     label: "STOVE GAS DROP TIE IN",
//     name: "stove_gas_drop_tie_in",
//     value: "",
//     status: true,
//   },
//   {
//     id: 39,
//     label: "GRILL GAS DROP TEST",
//     name: "grill_gas_drop_test",
//     value: "",
//     status: true,
//   },
//   {
//     id: 40,
//     label: "GRILL GAS DROP TIE IN",
//     name: "grill_gas_drop_tie_in",
//     value: "",
//     status: true,
//   },
//   {
//     id: 41,
//     label: "OTHER GAS DROPS TEST",
//     name: "other_gas_drops_test",
//     value: "",
//     status: true,
//   },
//   {
//     id: 42,
//     label: "OTHER GAS DROP TIE IN",
//     name: "other_gas_drop_tie_in",
//     value: "",
//     status: true,
//   },
//   {
//     id: 43,
//     label: "DISHWASHER-$5.00",
//     name: "dishwasher_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 44,
//     label: "GARBAGE DISPOSAL-$5.00",
//     name: "garbage_disposal_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 45,
//     label: "ICE MAKER BOX-$5.00",
//     name: "ice_maker_box_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 46,
//     label: "OPTIONAL KITCHEN FAUCET-$20.00",
//     name: "optional_kitchen_faucet_$20.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 47,
//     label: "BIDET-$20.00",
//     name: "bidet_$20.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 48,
//     label: "EXTRA SHOWER DIVERTER-$15.00",
//     name: "extra_shower_diverter_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 49,
//     label: "SHOWER ROD (ONE PER DWELLING)-$5.00",
//     name: "shower_rod_one_per_dwelling_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 50,
//     label: "TANK LESS W/H-HUNG BY OTHER-$15.00",
//     name: "tank_less_w_h_hung_by_other_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 51,
//     label: "WATER HEATER MIXING VALVE-$15.00",
//     name: "water_heater_mixing_valve_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 52,
//     label: "GAS W/H FLUE POWER VENT-$30.00",
//     name: "gas_w_h_flue_power_vent_$30.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 53,
//     label: "3 FUNCTION MIXING VALVE-$5.00",
//     name: "_3_function_mixing_valve_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 54,
//     label: "'3' VENT EXTERIOR TERMINATION-$5.00",
//     name: "_3_vent_exterior_termination_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 55,
//     label: "EXTERIOR CLEAN OUT-$5.00",
//     name: "exterior_clean_out_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 56,
//     label: "MANIBLOC ACCESS PANEL-$15.00",
//     name: "manibloc_access_panel_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 57,
//     label: "REVERSE OSMOSIS-$20.00",
//     name: "reverse_osmosis_$20.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 58,
//     label: "SEWER CONNECTION-$15.00",
//     name: "sewer_connection_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 59,
//     label: "SPRINKLER MAIN TIE IN-$10.00",
//     name: "sprinkler_main_tie_in_$10.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 60,
//     label: "SUMP PUMP BATTERY BACKUP-$15.00",
//     name: "sump_pump_battery_backup_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 61,
//     label: "SUMP PUMP EXTERIOR TERMINATION-$5.00",
//     name: "sump_pump_exterior_termination_$5.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 62,
//     label: "WATER METER SPREAD-$45.00",
//     name: "water_meter_spread_$45.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 63,
//     label: "WATER METER PL-$20.00",
//     name: "water_meter_pl_$20.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 64,
//     label: "RECIRCULATING PUMP-$15.00",
//     name: "recirculating_pump_$15.00",
//     value: "",
//     status: true,
//   },
//   {
//     id: 65,
//     label: "TRAP PRIMER-$15.00",
//     name: "trap_primer_$15.00",
//     value: "",
//     status: true,
//   },
// ];
export const fcTrim = [
  {
    id: 1,
    label: "LAVATORIES",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_LAVATORIES_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_LAVATORIES_SUB_PAY",
    status: true,
  },
  {
    id: 2,
    label: "PEDESTAL LAVATORIES",
    unitPrice: 30,
    deductedFieldName: "FC_TRIM_PEDESTAL_LAVATORIES_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_PEDESTAL_LAVATORIES_SUB_PAY",
    status: true,
  },
  {
    id: 3,
    label: "WATER CLOSETS",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_WATER_CLOSETS_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WATER_CLOSETS_SUB_PAY",
    status: true,
  },
  {
    id: 4,
    label: "SHOWERS",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_SHOWERS_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SHOWERS_SUB_PAY",
    status: true,
  },
  {
    id: 5,
    label: "TUB/SHOWERS",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_TUB_SHOWERS_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_TUB_SHOWERS_SUB_PAY",
    status: true,
  },
  {
    id: 6,
    label: "WATER MAIN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_WATER_MAIN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WATER_MAIN_SUB_PAY",
    status: true,
  },
  {
    id: 7,
    label: "WASHER BOX, EMERGENCY PAN INCL",
    unitPrice: 10,
    deductedFieldName: "FC_TRIM_WASHER_BOX_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WASHER_BOX_SUB_PAY",
    status: true,
  },
  {
    id: 8,
    label: "ROMAN TUB",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_ROMAN_TUB_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_ROMAN_TUB_SUB_PAY",
    status: true,
  },
  {
    id: 9,
    label: "KITCHEN",
    unitPrice: 25,
    deductedFieldName: "FC_TRIM_KITCHEN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_KITCHEN_SUB_PAY",
    status: true,
  },
  {
    id: 10,
    label: "SUMP PUMP",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_SUMP_PUMP_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SUMP_PUMP_SUB_PAY",
    status: true,
  },
  {
    id: 11,
    label: "OUTDOOR SHOWER",
    unitPrice: 30,
    deductedFieldName: "FC_TRIM_OUTDOOR_SHOWER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_OUTDOOR_SHOWER_SUB_PAY",
    status: true,
  },
  {
    id: 12,
    label: "WET BAR",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_WET_BAR_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WET_BAR_SUB_PAY",
    status: true,
  },
  {
    id: 13,
    label: "SEWER EJECTOR PIT",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_SEWER_EJECTOR_PIT_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SEWER_EJECTOR_PIT_SUB_PAY",
    status: true,
  },
  {
    id: 14,
    label: "SEWER EJECTOR PUMP",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_SEWER_EJECTOR_PUMP_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SEWER_EJECTOR_PUMP_SUB_PAY",
    status: true,
  },
  {
    id: 15,
    label: "AREAWAY DRAIN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_AREAWAY_DRAIN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_AREAWAY_DRAIN_SUB_PAY",
    status: true,
  },
  {
    id: 16,
    label: "LAUNDRY TUB",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_LAUNDRY_TUB_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_LAUNDRY_TUB_SUB_PAY",
    status: true,
  },
  {
    id: 17,
    label: "WINDOW WELL",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_WINDOW_WELL_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WINDOW_WELL_SUB_PAY",
    status: true,
  },
  {
    id: 18,
    label: "ELECTRIC WATER HEATER",
    unitPrice: 30,
    deductedFieldName: "FC_TRIM_ELECTRIC_WATER_HEATER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_ELECTRIC_WATER_HEATER_SUB_PAY",
    status: true,
  },
  {
    id: 19,
    label: "GAS WATER HEATER",
    unitPrice: 35,
    deductedFieldName: "FC_TRIM_GAS_WATER_HEATER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_GAS_WATER_HEATER_SUB_PAY",
    status: true,
  },
  {
    id: 20,
    label: "K-LESS W/H-HANG VENT /H20 TIE IN",
    unitPrice: 40,
    deductedFieldName: "FC_TRIM_K_LESS_W_H_HANG_VENT_H20_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_K_LESS_W_H_HANG_VENT_H20_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 21,
    label: "LAWN IRRIGATION",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_LAWN_IRRIGATION_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_LAWN_IRRIGATION_SUB_PAY",
    status: true,
  },
  {
    id: 22,
    label: "RADON",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_RADON_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_RADON_SUB_PAY",
    status: true,
  },
  {
    id: 23,
    label: "CRAWL SPRINKLER",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_CRAWL_SPRINKLER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_CRAWL_SPRINKLER_SUB_PAY",
    status: true,
  },
  {
    id: 24,
    label: "HOSE BIB",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_HOSE_BIB_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_HOSE_BIB_SUB_PAY",
    status: true,
  },
  {
    id: 25,
    label: "APOLLO THERMOSTATIC MIXING VALVE",
    unitPrice: 15,
    deductedFieldName:
      "FC_TRIM_APOLLO_THERMOSTATIC_MIXING_VALVE_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_APOLLO_THERMOSTATIC_MIXING_VALVE_SUB_PAY",
    status: true,
  },
  {
    id: 26,
    label: "LAMP HOLE COVER",
    unitPrice: 10,
    deductedFieldName: "FC_TRIM_LAMP_HOLE_COVER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_LAMP_HOLE_COVER_SUB_PAY",
    status: true,
  },
  {
    id: 27,
    label: "POT FILLER",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_POT_FILLER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_POT_FILLER_SUB_PAY",
    status: true,
  },
  {
    id: 28,
    label: "HANDHELDS",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_HANDHELDS_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_HANDHELDS_SUB_PAY",
    status: true,
  },
  {
    id: 29,
    label: "RAIN HEADS",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_RAIN_HEADS_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_RAIN_HEADS_SUB_PAY",
    status: true,
  },
  {
    id: 30,
    label: "BODY SPRAYS",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_BODY_SPRAYS_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_BODY_SPRAYS_SUB_PAY",
    status: true,
  },
  {
    id: 31,
    label: "FURNACE GAS DROP TEST",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_FURNACE_GAS_DROP_TEST_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_FURNACE_GAS_DROP_TEST_SUB_PAY",
    status: true,
  },
  {
    id: 32,
    label: "FURNACE GAS DROP TIE IN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_FURNACE_GAS_DROP_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_FURNACE_GAS_DROP_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 33,
    label: "FIRE PLACE GAS DROP TEST",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_FIRE_PLACE_GAS_DROP_TEST_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_FIRE_PLACE_GAS_DROP_TEST_SUB_PAY",
    status: true,
  },
  {
    id: 34,
    label: "FIRE PLACE GAS DROP TIE IN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_FIRE_PLACE_GAS_DROP_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_FIRE_PLACE_GAS_DROP_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 35,
    label: "WATER HEATER GAS DROP TEST",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_WATER_HEATER_GAS_DROP_TEST_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WATER_HEATER_GAS_DROP_TEST_SUB_PAY",
    status: true,
  },
  {
    id: 36,
    label: "WATER HEATER GAS DROP TIE IN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_WATER_HEATER_GAS_DROP_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WATER_HEATER_GAS_DROP_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 37,
    label: "STOVE GAS DROP TEST",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_STOVE_GAS_DROP_TEST_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_STOVE_GAS_DROP_TEST_SUB_PAY",
    status: true,
  },
  {
    id: 38,
    label: "STOVE GAS DROP TIE IN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_STOVE_GAS_DROP_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_STOVE_GAS_DROP_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 39,
    label: "GRILL GAS DROP TEST",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_GRILL_GAS_DROP_TEST_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_GRILL_GAS_DROP_TEST_SUB_PAY",
    status: true,
  },
  {
    id: 40,
    label: "GRILL GAS DROP TIE IN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_GRILL_GAS_DROP_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_GRILL_GAS_DROP_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 41,
    label: "OTHER GAS DROPS TEST",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_OTHER_GAS_DROPS_TEST_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_OTHER_GAS_DROPS_TEST_SUB_PAY",
    status: true,
  },
  {
    id: 42,
    label: "OTHER GAS DROP TIE IN",
    unitPrice: 0,
    deductedFieldName: "FC_TRIM_OTHER_GAS_DROP_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_OTHER_GAS_DROP_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 43,
    label: "DISHWASHER",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_DISHWASHER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_DISHWASHER_SUB_PAY",
    status: true,
  },
  {
    id: 44,
    label: "GARBAGE DISPOSAL",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_GARBAGE_DISPOSAL_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_GARBAGE_DISPOSAL_SUB_PAY",
    status: true,
  },
  {
    id: 45,
    label: "ICE MAKER BOX",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_ICE_MAKER_BOX_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_ICE_MAKER_BOX_SUB_PAY",
    status: true,
  },
  {
    id: 46,
    label: "OPTIONAL KITCHEN FAUCET",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_OPTIONAL_KITCHEN_FAUCET_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_OPTIONAL_KITCHEN_FAUCET_SUB_PAY",
    status: true,
  },
  {
    id: 47,
    label: "BIDET",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_BIDET_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_BIDET_SUB_PAY",
    status: true,
  },
  {
    id: 48,
    label: "EXTRA SHOWER DIVERTER",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_EXTRA_SHOWER_DIVERTER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_EXTRA_SHOWER_DIVERTER_SUB_PAY",
    status: true,
  },
  {
    id: 49,
    label: "SHOWER ROD (ONE PER DWELLING)",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_SHOWER_ROD_ONE_PER_DWELLING_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SHOWER_ROD_ONE_PER_DWELLING_SUB_PAY",
    status: true,
  },
  {
    id: 50,
    label: "TANK LESS W/H-HUNG BY OTHER",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_TANK_LESS_W_H_HUNG_BY_OTHER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_TANK_LESS_W_H_HUNG_BY_OTHER_SUB_PAY",
    status: true,
  },
  {
    id: 51,
    label: "WATER HEATER MIXING VALVE",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_WATER_HEATER_MIXING_VALVE_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WATER_HEATER_MIXING_VALVE_SUB_PAY",
    status: true,
  },
  {
    id: 52,
    label: "GAS W/H FLUE POWER VENT",
    unitPrice: 30,
    deductedFieldName: "FC_TRIM_GAS_W_H_FLUE_POWER_VENT_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_GAS_W_H_FLUE_POWER_VENT_SUB_PAY",
    status: true,
  },
  {
    id: 53,
    label: "3 FUNCTION MIXING VALVE",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_3_FUNCTION_MIXING_VALVE_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_3_FUNCTION_MIXING_VALVE_SUB_PAY",
    status: true,
  },
  {
    id: 54,
    label: "'3' VENT EXTERIOR TERMINATION",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_3_VENT_EXTERIOR_TERMINATION_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_3_VENT_EXTERIOR_TERMINATION_SUB_PAY",
    status: true,
  },
  {
    id: 55,
    label: "EXTERIOR CLEAN OUT",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_EXTERIOR_CLEAN_OUT_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_EXTERIOR_CLEAN_OUT_SUB_PAY",
    status: true,
  },
  {
    id: 56,
    label: "MANIBLOC ACCESS PANEL",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_MANIBLOC_ACCESS_PANEL_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_MANIBLOC_ACCESS_PANEL_SUB_PAY",
    status: true,
  },
  {
    id: 57,
    label: "REVERSE OSMOSIS",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_REVERSE_OSMOSIS_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_REVERSE_OSMOSIS_SUB_PAY",
    status: true,
  },
  {
    id: 58,
    label: "SEWER CONNECTION",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_SEWER_CONNECTION_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SEWER_CONNECTION_SUB_PAY",
    status: true,
  },
  {
    id: 59,
    label: "SPRINKLER MAIN TIE IN",
    unitPrice: 10,
    deductedFieldName: "FC_TRIM_SPRINKLER_MAIN_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SPRINKLER_MAIN_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 60,
    label: "SUMP PUMP BATTERY BACKUP",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_SUMP_PUMP_BATTERY_BACKUP_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SUMP_PUMP_BATTERY_BACKUP_SUB_PAY",
    status: true,
  },
  {
    id: 61,
    label: "SUMP PUMP EXTERIOR TERMINATION",
    unitPrice: 5,
    deductedFieldName: "FC_TRIM_SUMP_PUMP_EXTERIOR_TERMINATION_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_SUMP_PUMP_EXTERIOR_TERMINATION_SUB_PAY",
    status: true,
  },
  {
    id: 62,
    label: "WATER METER SPREAD",
    unitPrice: 45,
    deductedFieldName: "FC_TRIM_WATER_METER_SPREAD_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WATER_METER_SPREAD_SUB_PAY",
    status: true,
  },
  {
    id: 63,
    label: "WATER METER PL",
    unitPrice: 20,
    deductedFieldName: "FC_TRIM_WATER_METER_PL_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_WATER_METER_PL_SUB_PAY",
    status: true,
  },
  {
    id: 64,
    label: "RECIRCULATING PUMP",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_RECIRCULATING_PUMP_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_RECIRCULATING_PUMP_SUB_PAY",
    status: true,
  },
  {
    id: 65,
    label: "TRAP PRIMER",
    unitPrice: 15,
    deductedFieldName: "FC_TRIM_TRAP_PRIMER_TOTAL_QUANTITY",
    subPayFieldName: "FC_TRIM_TRAP_PRIMER_SUB_PAY",
    status: true,
  },
];
export const fcRoughin = [
  {
    id: 1,
    label: "SINGLE LAVATORIES",
    unitPrice: 60,
    deductedFieldName: "SINGLE_LAVATORIES_TOTAL_QUANTITY",
    subPayFieldName: "SINGLE_LAVATORIES_SUB_PAY",
    status: true,
  },
  {
    id: 2,
    label: "SLAB ON GRADE/BASEMENT LAVATORIES",
    unitPrice: 45,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_LAVATORIES_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_LAVATORIES_SUB_PAY",
    status: true,
  },
  {
    id: 3,
    label: "SIDE BY SIDE LAVATORIES",
    unitPrice: 90,
    deductedFieldName: "SIDE_BY_SIDE_LAVATORIES_TOTAL_QUANTITY",
    subPayFieldName: "SIDE_BY_SIDE_LAVATORIES_SUB_PAY",
    status: true,
  },
  {
    id: 4,
    label: "SLAB ON GRADE SIDE BY SIDE LAVATORIES",
    unitPrice: 60,
    deductedFieldName: "SLAB_ON_GRADE_SIDE_BY_SIDE_LAVATORIES_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_SIDE_BY_SIDE_LAVATORIES_SUB_PAY",
    status: true,
  },
  {
    id: 5,
    label: "WATER CLOSET",
    unitPrice: 60,
    deductedFieldName: "WATER_CLOSET_TOTAL_QUANTITY",
    subPayFieldName: "WATER_CLOSET_SUB_PAY",
    status: true,
  },
  {
    id: 6,
    label: "SLAB ON GRADE/BASEMENT WATER CLOSET",
    unitPrice: 45,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_WATER_CLOSET_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_WATER_CLOSET_SUB_PAY",
    status: true,
  },
  {
    id: 7,
    label: "SHOWER",
    unitPrice: 60,
    deductedFieldName: "SHOWER_TOTAL_QUANTITY",
    subPayFieldName: "SHOWER_SUB_PAY",
    status: true,
  },
  {
    id: 8,
    label: "SLAB ON GRADE/BASEMENT SHOWER",
    unitPrice: 30,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_SHOWER_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_SHOWER_SUB_PAY",
    status: true,
  },
  {
    id: 9,
    label: "TUB/SHOWER",
    unitPrice: 60,
    deductedFieldName: "TUB_SHOWER_TOTAL_QUANTITY",
    subPayFieldName: "TUB_SHOWER_SUB_PAY",
    status: true,
  },
  {
    id: 10,
    label: "SLAB ON GRADE/BASEMENT TUB/SHOWER",
    unitPrice: 30,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_TUB_SHOWER_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_TUB_SHOWER_SUB_PAY",
    status: true,
  },
  {
    id: 11,
    label: "KITCHEN(ICE MAKER BOX INCLUDED)",
    unitPrice: 60,
    deductedFieldName: "KITCHEN_ICE_MAKER_BOX_INCLUDED_TOTAL_QUANTITY",
    subPayFieldName: "KITCHEN_ICE_MAKER_BOX_INCLUDED_SUB_PAY",
    status: true,
  },
  {
    id: 12,
    label: "SLAB ON GRADE KITCHEN",
    unitPrice: 45,
    deductedFieldName: "SLAB_ON_GRADE_KITCHEN_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_KITCHEN_SUB_PAY",
    status: true,
  },
  {
    id: 13,
    label: "WATER MAIN",
    unitPrice: 30,
    deductedFieldName: "WATER_MAIN_TOTAL_QUANTITY",
    subPayFieldName: "WATER_MAIN_SUB_PAY",
    status: true,
  },
  {
    id: 14,
    label: "WASHER BOX",
    unitPrice: 60,
    deductedFieldName: "WASHER_BOX_TOTAL_QUANTITY",
    subPayFieldName: "WASHER_BOX_SUB_PAY",
    status: true,
  },
  {
    id: 15,
    label: "SLAB ON GRADE/BASEMENT WASHER BOX",
    unitPrice: 45,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_WASHER_BOX_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_WASHER_BOX_SUB_PAY",
    status: true,
  },
  {
    id: 16,
    label: "WASHER PAN",
    unitPrice: 15,
    deductedFieldName: "WASHER_PAN_TOTAL_QUANTITY",
    subPayFieldName: "WASHER_PAN_SUB_PAY",
    status: true,
  },
  {
    id: 17,
    label: "ROMAN TUB",
    unitPrice: 75,
    deductedFieldName: "ROMAN_TUB_TOTAL_QUANTITY",
    subPayFieldName: "ROMAN_TUB_SUB_PAY",
    status: true,
  },
  {
    id: 18,
    label: "SLAB ON GRADE/BASEMENT ROMAN TUB",
    unitPrice: 65,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_ROMAN_TUB_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_ROMAN_TUB_SUB_PAY",
    status: true,
  },
  {
    id: 19,
    label: "OUTDOOR SHOWER",
    unitPrice: 30,
    deductedFieldName: "OUTDOOR_SHOWER_TOTAL_QUANTITY",
    subPayFieldName: "OUTDOOR_SHOWER_SUB_PAY",
    status: true,
  },
  {
    id: 20,
    label: "SUMP PUMP",
    unitPrice: 30,
    deductedFieldName: "SUMP_PUMP_TOTAL_QUANTITY",
    subPayFieldName: "SUMP_PUMP_SUB_PAY",
    status: true,
  },
  {
    id: 21,
    label: "WET BAR",
    unitPrice: 60,
    deductedFieldName: "WET_BAR_TOTAL_QUANTITY",
    subPayFieldName: "WET_BAR_SUB_PAY",
    status: true,
  },
  {
    id: 22,
    label: "SLAB ON GRADE/BASEMENT WET BAR",
    unitPrice: 45,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_WET_BAR_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_WET_BAR_SUB_PAY",
    status: true,
  },
  {
    id: 23,
    label: "SEWER EJECTOR PIT",
    unitPrice: 0,
    deductedFieldName: "SEWER_EJECTOR_PIT_TOTAL_QUANTITY",
    subPayFieldName: "SEWER_EJECTOR_PIT_SUB_PAY",
    status: true,
  },
  {
    id: 24,
    label: "SEWER EJECTOR PUMP",
    unitPrice: 30,
    deductedFieldName: "SEWER_EJECTOR_PUMP_TOTAL_QUANTITY",
    subPayFieldName: "SEWER_EJECTOR_PUMP_SUB_PAY",
    status: true,
  },
  {
    id: 25,
    label: "AREAWAY DRAIN",
    unitPrice: 0,
    deductedFieldName: "AREAWAY_DRAIN_TOTAL_QUANTITY",
    subPayFieldName: "AREAWAY_DRAIN_SUB_PAY",
    status: true,
  },
  {
    id: 26,
    label: "LAUNDRY TUB",
    unitPrice: 60,
    deductedFieldName: "LAUNDRY_TUB_TOTAL_QUANTITY",
    subPayFieldName: "LAUNDRY_TUB_SUB_PAY",
    status: true,
  },
  {
    id: 27,
    label: "SLAB ON GRADE/BASEMENT LAUNDRY TUB",
    unitPrice: 45,
    deductedFieldName: "SLAB_ON_GRADE_BASEMENT_LAUNDRY_TUB_TOTAL_QUANTITY",
    subPayFieldName: "SLAB_ON_GRADE_BASEMENT_LAUNDRY_TUB_SUB_PAY",
    status: true,
  },
  {
    id: 28,
    label: "WINDOW WELL",
    unitPrice: 0,
    deductedFieldName: "WINDOW_WELL_TOTAL_QUANTITY",
    subPayFieldName: "WINDOW_WELL_SUB_PAY",
    status: true,
  },
  {
    id: 29,
    label: "ELECTRIC WATER HEATER",
    unitPrice: 60,
    deductedFieldName: "ELECTRIC_WATER_HEATER_TOTAL_QUANTITY",
    subPayFieldName: "ELECTRIC_WATER_HEATER_SUB_PAY",
    status: true,
  },
  {
    id: 30,
    label: "GAS WATER HEATER",
    unitPrice: 60,
    deductedFieldName: "GAS_WATER_HEATER_TOTAL_QUANTITY",
    subPayFieldName: "GAS_WATER_HEATER_SUB_PAY",
    status: true,
  },
  {
    id: 31,
    label: "VENT FOR GAS WATER HEATER",
    unitPrice: 15,
    deductedFieldName: "VENT_FOR_GAS_WATER_HEATER_TOTAL_QUANTITY",
    subPayFieldName: "VENT_FOR_GAS_WATER_HEATER_SUB_PAY",
    status: true,
  },
  {
    id: 32,
    label: "TANK LESS WATER HEATER",
    unitPrice: 60,
    deductedFieldName: "TANK_LESS_WATER_HEATER_TOTAL_QUANTITY",
    subPayFieldName: "TANK_LESS_WATER_HEATER_SUB_PAY",
    status: true,
  },
  {
    id: 33,
    label: "HOSE BIB",
    unitPrice: 30,
    deductedFieldName: "HOSE_BIB_TOTAL_QUANTITY",
    subPayFieldName: "HOSE_BIB_SUB_PAY",
    status: true,
  },
  {
    id: 34,
    label: "LAWN IRRIGATION",
    unitPrice: 0,
    deductedFieldName: "LAWN_IRRIGATION_TOTAL_QUANTITY",
    subPayFieldName: "LAWN_IRRIGATION_SUB_PAY",
    status: true,
  },
  {
    id: 35,
    label: "RADON",
    unitPrice: 30,
    deductedFieldName: "RADON_TOTAL_QUANTITY",
    subPayFieldName: "RADON_SUB_PAY",
    status: true,
  },
  {
    id: 36,
    label: "CRAWL SPRINKLER",
    unitPrice: 15,
    deductedFieldName: "CRAWL_SPRINKLER_TOTAL_QUANTITY",
    subPayFieldName: "CRAWL_SPRINKLER_SUB_PAY",
    status: true,
  },
  {
    id: 37,
    label: "FURNACE GAS DROP",
    unitPrice: 0,
    deductedFieldName: "FURNACE_GAS_DROP_TOTAL_QUANTITY",
    subPayFieldName: "FURNACE_GAS_DROP_SUB_PAY",
    status: true,
  },
  {
    id: 38,
    label: "FIRE PLACE GAS DROP",
    unitPrice: 0,
    deductedFieldName: "FIRE_PLACE_GAS_DROP_TOTAL_QUANTITY",
    subPayFieldName: "FIRE_PLACE_GAS_DROP_SUB_PAY",
    status: true,
  },
  {
    id: 39,
    label: "WATER HEATER GAS DROP",
    unitPrice: 0,
    deductedFieldName: "WATER_HEATER_GAS_DROP_TOTAL_QUANTITY",
    subPayFieldName: "WATER_HEATER_GAS_DROP_SUB_PAY",
    status: true,
  },
  {
    id: 40,
    label: "STOVE GAS DROP",
    unitPrice: 0,
    deductedFieldName: "STOVE_GAS_DROP_TOTAL_QUANTITY",
    subPayFieldName: "STOVE_GAS_DROP_SUB_PAY",
    status: true,
  },
  {
    id: 41,
    label: "OUTSIDE GRILL GAS DROP",
    unitPrice: 0,
    deductedFieldName: "OUTSIDE_GRILL_GAS_DROP_TOTAL_QUANTITY",
    subPayFieldName: "OUTSIDE_GRILL_GAS_DROP_SUB_PAY",
    status: true,
  },
  {
    id: 42,
    label: "OTHER GAS DROPS",
    unitPrice: 0,
    deductedFieldName: "OTHER_GAS_DROPS_TOTAL_QUANTITY",
    subPayFieldName: "OTHER_GAS_DROPS_SUB_PAY",
    status: true,
  },
  {
    id: 43,
    label: "WATER LINE FED FROM THE FLOOR",
    unitPrice: 0,
    deductedFieldName: "WATER_LINE_FED_FROM_THE_FLOOR_TOTAL_QUANTITY",
    subPayFieldName: "WATER_LINE_FED_FROM_THE_FLOOR_SUB_PAY",
    status: true,
  },
  {
    id: 44,
    label: "WATER LINE FED FROM WALL",
    unitPrice: 0,
    deductedFieldName: "WATER_LINE_FED_FROM_WALL_TOTAL_QUANTITY",
    subPayFieldName: "WATER_LINE_FED_FROM_WALL_SUB_PAY",
    status: true,
  },
  {
    id: 45,
    label: "MANI BLOC",
    unitPrice: 60,
    deductedFieldName: "MANI_BLOC_TOTAL_QUANTITY",
    subPayFieldName: "MANI_BLOC_SUB_PAY",
    status: true,
  },
  {
    id: 46,
    label: "INSULATED PIPE HOT SIDE",
    unitPrice: 15,
    deductedFieldName: "INSULATED_PIPE_HOT_SIDE_TOTAL_QUANTITY",
    subPayFieldName: "INSULATED_PIPE_HOT_SIDE_SUB_PAY",
    status: true,
  },
  {
    id: 47,
    label: "EXTRA PEX HOT SIDE",
    unitPrice: 60,
    deductedFieldName: "EXTRA_PEX_HOT_SIDE_TOTAL_QUANTITY",
    subPayFieldName: "EXTRA_PEX_HOT_SIDE_SUB_PAY",
    status: true,
  },
  {
    id: 48,
    label: "PEX SLEEVE FOR METER WIRE",
    unitPrice: 15,
    deductedFieldName: "PEX_SLEEVE_FOR_METER_WIRE_TOTAL_QUANTITY",
    subPayFieldName: "PEX_SLEEVE_FOR_METER_WIRE_SUB_PAY",
    status: true,
  },
  {
    id: 49,
    label: "SEWER PENETRATION SEAL",
    unitPrice: 30,
    deductedFieldName: "SEWER_PENETRATION_SEAL_TOTAL_QUANTITY",
    subPayFieldName: "SEWER_PENETRATION_SEAL_SUB_PAY",
    status: true,
  },
  {
    id: 50,
    label: "TUB/SHOWER WITH WALL SET",
    unitPrice: 15,
    deductedFieldName: "TUB_SHOWER_WITH_WALL_SET_TOTAL_QUANTITY",
    subPayFieldName: "TUB_SHOWER_WITH_WALL_SET_SUB_PAY",
    status: true,
  },
  {
    id: 51,
    label: "EXTRA DIVERTER FOR HANDHELD",
    unitPrice: 30,
    deductedFieldName: "EXTRA_DIVERTER_FOR_HANDHELD_TOTAL_QUANTITY",
    subPayFieldName: "EXTRA_DIVERTER_FOR_HANDHELD_SUB_PAY",
    status: true,
  },
  {
    id: 52,
    label: "BODY SPRAY BOX",
    unitPrice: 30,
    deductedFieldName: "BODY_SPRAY_BOX_TOTAL_QUANTITY",
    subPayFieldName: "BODY_SPRAY_BOX_SUB_PAY",
    status: true,
  },
  {
    id: 53,
    label: "VOLUME CONTROL",
    unitPrice: 30,
    deductedFieldName: "VOLUME_CONTROL_TOTAL_QUANTITY",
    subPayFieldName: "VOLUME_CONTROL_SUB_PAY",
    status: true,
  },
  {
    id: 54,
    label: "3' CONDENSATE",
    unitPrice: 30,
    deductedFieldName: "_3_CONDENSATE_TOTAL_QUANTITY",
    subPayFieldName: "_3_CONDENSATE_SUB_PAY",
    status: true,
  },
  {
    id: 55,
    label: "SEWER TIE IN",
    unitPrice: 15,
    deductedFieldName: "SEWER_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "SEWER_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 56,
    label: "GAS DROP TIE IN",
    unitPrice: 15,
    deductedFieldName: "GAS_DROP_TIE_IN_TOTAL_QUANTITY",
    subPayFieldName: "GAS_DROP_TIE_IN_SUB_PAY",
    status: true,
  },
  {
    id: 57,
    label: "HUMIDIFIER",
    unitPrice: 15,
    deductedFieldName: "HUMIDIFIER_TOTAL_QUANTITY",
    subPayFieldName: "HUMIDIFIER_SUB_PAY",
    status: true,
  },
];
export const qcTrim = [
  {
    id: 1,
    label:
      "LAVATORY DRAINS DOPED AND WELL TIGHTENED WHERE RUB…EETS PORCELAIN / POP UPS ADJUSTED & HOLDING WATER",
    name: "LAVATORY_DRAINS_DOPED_AND_WELL_TIGHTENED_WHERE_RUB…EETS_PORCELAIN_POP_UPS_ADJUSTED_HOLDING_WATER",
    value: "",
    status: true,
  },
  {
    id: 2,
    label:
      "OVERFLOWS ON TUBS WITH TEST EQUIPMENT CHECKED TO M…REMOVED/RE-TIGHTENED CHECK TUB SHOE FOR TIGHTNESS",
    name: "OVERFLOWS_ON_TUBS_WITH_TEST_EQUIPMENT_CHECKED_TO_M…REMOVED_RE_TIGHTENED_CHECK_TUB_SHOE_FOR_TIGHTNESS",
    value: "",
    status: true,
  },
  {
    id: 3,
    label:
      "BRAIDED SUPPLY TUBES ON ALL LAVATORIES, WATER CLOSETS, DISHWASHERS, & REFRIGERATORS",
    name: "BRAIDED_SUPPLY_TUBES_ON_ALL_LAVATORIES_WATER_CLOSETS_DISHWASHERS_REFRIGERATORS",
    value: "",
    status: true,
  },
  {
    id: 4,
    label: "ALL SHOWER HEADS TIGHT/NO LEAKS",
    name: "ALL_SHOWER_HEADS_TIGHT_NO_LEAKS",
    value: "",
    status: true,
  },
  {
    id: 5,
    label: "ALL SHOWER HANDLES SECURED",
    name: "ALL_SHOWER_HANDLES_SECURED",
    value: "",
    status: true,
  },
  {
    id: 6,
    label: "CHECK FIXTURES TO ENSURE THEY ARE INSTALLED STRAIGHT/LEVEL",
    name: "CHECK_FIXTURES_TO_ENSURE_THEY_ARE_INSTALLED_STRAIGHT_LEVEL",
    value: "",
    status: true,
  },
  {
    id: 7,
    label: "VALVE STOPS AT FIXTURES FELT FOR LEAKS",
    name: "VALVE_STOPS_AT_FIXTURES_FELT_FOR_LEAKS",
    value: "",
    status: true,
  },
  {
    id: 8,
    label: "HOSE BIBS INSTALLED WHERE SPECIFIED AND SECURED",
    name: "HOSE_BIBS_INSTALLED_WHERE_SPECIFIED_AND_SECURED",
    value: "",
    status: true,
  },
  {
    id: 9,
    label: "HOT ON LEFT / COLD ON RIGHT",
    name: "HOT_ON_LEFT_COLD_ON_RIGHT",
    value: "",
    status: true,
  },
  {
    id: 10,
    label: "AZAK BLOCKING 5/4 BOARD",
    name: "AZAK_BLOCKING_5_4_BOARD",
    value: "",
    status: true,
  },
  {
    id: 11,
    label: "WATER CLOSET TANKS LEVEL & SQUARE WITH WALL",
    name: "WATER_CLOSET_TANKS_LEVEL_SQUARE_WITH_WALL",
    value: "",
    status: true,
  },
  {
    id: 12,
    label:
      "WATER CLOSETS FLUSHED MORE THAN THREE TIMES & PHYS…AKE SURE THEY ARE NOT ROCKING - SHIM IF NECESSARY",
    name: "WATER_CLOSETS_FLUSHED_MORE_THAN_THREE_TIMES_PHYS…AKE_SURE_THEY_ARE_NOT_ROCKING_SHIM_IF_NECESSARY",
    value: "",
    status: true,
  },
  {
    id: 13,
    label: "GARBAGE DISPOSAL KNOCKOUT REMOVED & TESTED",
    name: "GARBAGE_DISPOSAL_KNOCKOUT_REMOVED_TESTED",
    value: "",
    status: true,
  },
  {
    id: 14,
    label: "DISHWASHER DISCHARGE RUN HIGH AS POSSIBLE IN BASE CABINET",
    name: "DISHWASHER_DISCHARGE_RUN_HIGH_AS_POSSIBLE_IN_BASE_CABINET",
    value: "",
    status: true,
  },
  {
    id: 15,
    label: "ALL DISHWASHER CONNECTIONS CHECKED FOR LEAKS",
    name: "ALL_DISHWASHER_CONNECTIONS_CHECKED_FOR_LEAKS",
    value: "",
    status: true,
  },
  {
    id: 16,
    label: "COVERS ON WASHER BOX AND ICE BOX",
    name: "COVERS_ON_WASHER_BOX_AND_ICE_BOX",
    value: "",
    status: true,
  },
  {
    id: 17,
    label: "ALL PLUMBING AREAS LEFT IN CLEAN CONDITION",
    name: "ALL_PLUMBING_AREAS_LEFT_IN_CLEAN_CONDITION",
    value: "",
    status: true,
  },
  {
    id: 18,
    label: "ALL PLUMBING MATERIAL REMOVED FROM SITE",
    name: "ALL_PLUMBING_MATERIAL_REMOVED_FROM_SITE",
    value: "",
    status: true,
  },
  {
    id: 19,
    label: "WATER HEATER TEMP ADJUSTED TO MIDDLE SETTING/RINNAI TO 120 DEGREES",
    name: "WATER_HEATER_TEMP_ADJUSTED_TO_MIDDLE_SETTING_RINNAI_TO_120_DEGREES",
    value: "",
    status: true,
  },
  {
    id: 20,
    label: "WASHER BOX & FRIDGE BOX ESCUTCHEONS INSTALLED",
    name: "WASHER_BOX_&_FRIDGE_BOX_ESCUTCHEONS_INSTALLED",
    value: "",
    status: true,
  },
  {
    id: 21,
    label: "WASHER BOX EMERGENCY PAN DRAIN INSTALLED",
    name: "WASHER_BOX_EMERGENCY_PAN_DRAIN_INSTALLED",
    value: "",
    status: true,
  },
  {
    id: 22,
    label: "WATER HEATER HAS EMERGENCY SERVICE STICKER WITH CURRENT INFO",
    name: "WATER_HEATER_HAS_EMERGENCY_SERVICE_STICKER_WITH_CURRENT_INFO",
    value: "",
    status: true,
  },
  {
    id: 23,
    label: "ALL FINAL GAS CONNECTIONS HAVE BEEN MADE",
    name: "ALL_FINAL_GAS_CONNECTIONS_HAVE_BEEN_MADE",
    value: "",
    status: true,
  },
  {
    id: 24,
    label:
      "JACUZZI TUBS HAVE BEEN FILLED WITH WATER & ALL JET…MIXING VALVES & DIVERTERS ADJUSTED TO 110 DEGREES",
    name: "JACUZZI_TUBS_HAVE_BEEN_FILLED_WITH_WATER_ALL_JET…MIXING_VALVES_DIVERTERS_ADJUSTED_TO_110_DEGREES",
    value: "",
    status: true,
  },
  {
    id: 25,
    label: "ALL SINKS AND TUBS HOLDING WATER",
    name: "ALL_SINKS_AND_TUBS_HOLDING_WATER",
    value: "",
    status: true,
  },
  {
    id: 26,
    label:
      "SUMP PUMP CLEAR OF DEBRIS & WORKING / DRAIN PIPING…MP PUMP PIPE RISERS AND FUNCTIONING WITH NO ISSUE",
    name: "SUMP_PUMP_CLEAR_OF_DEBRIS_WORKING_DRAIN_PIPING…MP_PUMP_PIPE_RISERS_AND_FUNCTIONING_WITH_NO_ISSUE",
    value: "",
    status: true,
  },
  {
    id: 27,
    label: "SEWER EJECTOR PUMP INSTALLED & WORKING PROPERLY IF APPLICABLE",
    name: "SEWER_EJECTOR_PUMP_INSTALLED_&_WORKING_PROPERLY_IF_APPLICABLE",
    value: "",
    status: true,
  },
  {
    id: 28,
    label: "PACK JOINT INSPECTED; LOCK-NUT IS BRASS TO BRASS",
    name: "PACK_JOINT_INSPECTED;_LOCK_NUT_IS_BRASS_TO_BRASS",
    value: "",
    status: true,
  },
  {
    id: 29,
    label:
      "CORRECT FINISH OF WASTE & OVERFLOW MATCH PO & REST OF TRIM IN BATHROOM",
    name: "CORRECT_FINISH_OF_WASTE_OVERFLOW_MATCH_PO_REST_OF_TRIM_IN_BATHROOM",
    value: "",
    status: true,
  },
  {
    id: 30,
    label:
      "CRAWL AREA CHECKED FOR PLUMBING DEBRIS & LEAKS FROM ALL FIXTURES/DRAIN PIPING GRADE CORRECT",
    name: "CRAWL_AREA_CHECKED_FOR_PLUMBING_DEBRIS_LEAKS_FROM_ALL_FIXTURES_DRAIN_PIPING_GRADE_CORRECT",
    value: "",
    status: true,
  },
  {
    id: 31,
    label: "NOTIFY JOB FOREMAN OF ANY ISSUES THAT NEED TO BE ADDRESSED",
    name: "NOTIFY_JOB_FOREMAN_OF_ANY_ISSUES_THAT_NEED_TO_BE_ADDRESSED",
    value: "",
    status: true,
  },
  {
    id: 32,
    label: "PLACE A STICKER ON THE WATER HEATER AND ELECTRICAL PANEL",
    name: "PLACE_A_STICKER_ON_THE_WATER_HEATER_AND_ELECTRICAL_PANEL",
    value: "",
    status: true,
  },
  {
    id: 33,
    label: "FOR LENNAR - CAMERA SEWER LINE",
    name: "FOR_LENNAR_CAMERA_SEWER_LINE",
    value: "",
    status: true,
  },
  {
    id: 34,
    label: "SPRINKLER HEADS INSTALLED AND IN CORRECT LOCATION",
    name: "SPRINKLER_HEADS_INSTALLED_AND_IN_CORRECT_LOCATION",
    value: "",
    status: true,
  },
  {
    id: 35,
    label: "POTFILLER INSTALLED AND WORKING CORRECTLY",
    name: "POTFILLER_INSTALLED_AND_WORKING_CORRECTLY",
    value: "",
    status: true,
  },
  {
    id: 36,
    label: "HANDHELD INSTALLED AND WORKING CORRECTLY",
    name: "HANDHELD_INSTALLED_AND_WORKING_CORRECTLY",
    value: "",
    status: true,
  },
  {
    id: 37,
    label: "RAINHEADS INSTALLED AND WORKING CORRECTLY",
    name: "RAIN_HEADS_INSTALLED_AND_WORKING_CORRECTLY",
    value: "",
    status: true,
  },
  {
    id: 38,
    label: "BODY SPRAYS INSTALLED AND WORKING CORRECTLY",
    name: "BODY_SPRAYS_INSTALLED_AND_WORKING_CORRECTLY",
    value: "",
    status: true,
  },
  {
    id: 39,
    label: "ARE AERATORS CLEAR OF DEBRIS?",
    name: "ARE_AERATORS_CLEAR_OF_DEBRIS?",
    value: "",
    status: true,
  },
  {
    id: 40,
    label: "VALVE AND SECURITY TAGS",
    name: "VALVE_AND_SECURITY_TAGS",
    value: "",
    status: true,
  },
  {
    id: 41,
    label: "ALL FIXTURES TIGHT",
    name: "ALL_FIXTURES_TIGHT",
    value: "",
    status: true,
  },
  {
    id: 42,
    label: "INSTALL BIRD SCREENS IN 3 INCH FLUE VENTS (RYAN SPECIFIC)",
    name: "INSTALL_BIRD_SCREENS_IN_3_INCH_FLUE_VENTS_RYAN_SPECIFIC",
    value: "",
    status: true,
  },
  {
    id: 43,
    label:
      "INSTALL 2 INCH BIRD SCREEN IN OVERFLOW FOR AREA WAY DRAIN (RYAN SPECIFIC)",
    name: "INSTALL_2_INCH_BIRD_SCREEN_IN_OVERFLOW_FOR_AREA_WAY_DRAIN_RYAN_SPECIFIC",
    value: "",
    status: true,
  },
];
export const fcUnderSlab = [
  {
    id: 1,
    label: "DRAIN TEST",
    name: "DRAIN_TEST",
    value: "DRAIN_TEST",
    status: true,
    type: "radio",
    is_30$_: true,
    is_450$_: false,
    is_60$_: false,
    is_75$_: false,
  },
];
export const qcFormUnderSlab = [
  {
    id: 1,
    label: "DRAIN TEST",
    name: "DRAIN_TEST",
    value: "DRAIN_TEST",
    status: true,
    type: "radio",
  },
  {
    id: 3,
    label: "ALL WATER LINES SLEEVED ",
    name: "ALL_TUBS_SHOWERS_TRAPPED",
    value: "ALL_TUBS_SHOWERS_TRAPPED",
    status: true,
    type: "radio",
  },
  {
    id: 4,
    label: "ALL SHOWER HEADS TIGHT",
    name: "ALL_SHOWER_HEADS_TIGHT",
    value: "ALL_SHOWER_HEADS_TIGHT",
    status: true,
    type: "radio",
  },
  {
    id: 5,
    label: "HOT ON LEFT / COLD ON RIGHT",
    name: "HOT_ON_LEFT_COLD_ON_RIGHT",
    value: "HOT_ON_LEFT_COLD_ON_RIGHT",
    status: true,
    type: "radio",
  },
  {
    id: 6,
    label: "VALVES ON ALL TUBS & SHOWERS - IF APPLICABLE",
    name: "VALVES_ON_ALL_TUBS_SHOWERS_IF_APPLICABLE",
    value: "VALVES_ON_ALL_TUBS_SHOWERS_IF_APPLICABLE",
    status: true,
    type: "radio",
  },
  {
    id: 7,
    label: "SHOWER DIVERTER MOUNTED RIGHT SIDE UP",
    name: "SHOWER_DIVERTER_MOUNTED_RIGHT_SIDE_UP",
    value: "SHOWER_DIVERTER_MOUNTED_RIGHT_SIDE_UP",
    status: true,
    type: "radio",
  },
];
export const fixturesCount = [
  {
    label: "LAVATORIES",
    unitPrice: 20,
    deductedFieldName: "FC_UNDER_SLAB_LAVATORIES_TOTAL_QUANTITY",
    subPayFieldName: "FC_UNDER_SLAB_LAVATORIES_SUB_PAY",
  },
  {
    label: "WATER CLOSETS",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_WATER_CLOSETS_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_WATER_CLOSETS_SUB_PAY",
  },
  {
    label: "SHOWERS",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_SHOWERS_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_SHOWERS_SUB_PAY",
  },
  {
    label: "TUB/SHOWER",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_TUB_SHOWER_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_TUB_SHOWER_SUB_PAY",
  },
  {
    label: "KITCHEN",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_KITCHEN_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_KITCHEN_SUB_PAY",
  },
  {
    label: "WATER MAIN",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_WATER_MAIN_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_WATER_MAIN_SUB_PAY",
  },
  {
    label: "WASHER BOX",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_WASHER_BOX_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_WASHER_BOX_SUB_PAY",
  },
  {
    label: "ROMAN TUB",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_ROMAN_TUB_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_ROMAN_TUB_SUB_PAY",
  },
  {
    label: "DRAIN STACKS",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_DRAIN_STACK_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_DRAIN_STACK_SUB_PAY",
  },
  {
    label: "SUMP PUMP",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_SUMP_PUMP_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_SUMP_PUMP_SUB_PAY",
  },
  {
    label: "WET BAR",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_WEB_BAR_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_WEB_BAR_SUB_PAY",
  },
  {
    label: "SEWER EJECTOR PIT",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_SEWER_EJECTOR_PIT_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_SEWER_EJECTOR_PIT_SUB_PAY",
  },
  {
    label: "SEWER EJECTOR PUMP",
    unitPrice: null,
    deductedFieldName:
      "FC_UNDER_SLAB_FILLED_OUT_BY_SEWER_EJECTOR_PUMP_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_SEWER_EJECTOR_PUMP_SUB_PAY",
  },
  {
    label: "AREAWAY DRAIN",
    unitPrice: 50,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_AREAWAY_DRAIN_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_AREAWAY_DRAIN_SUB_PAY",
  },
  {
    label: "LAUNDRY TUB",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_LAUNDRY_TUB_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_LAUNDRY_TUB_SUB_PAY",
  },
  {
    label: "WINDOW WELL",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_WINDOW_WELL_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_WINDOW_WELL_SUB_PAY",
  },
  {
    label: "PREP SINK",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_PREP_SINK_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_PREP_SINK_SUB_PAY",
  },
  {
    label: "RADON",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_RADON_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_RADON_SUB_PAY",
  },
  {
    label: "BACKWATER VALVE",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_BACKWATER_VALVE_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_BACKWATER_VALVE_SUB_PAY",
  },
  {
    label: "CONDENSATE DRAIN",
    unitPrice: 30,
    deductedFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_CONDENSATE_DRAIN_DEDUCTED",
    subPayFieldName: "FC_UNDER_SLAB_FILLED_OUT_BY_CONDENSATE_DRAIN_SUB_PAY",
  },
];
export const qcRoughInCode002 = [
  {
    id: 2,
    label: "DUCT SYSTEM SUPPORTED CORRECTLY",
    name: "DUCT_SYSTEM_SUPPORTED_CORRECTLY",
    value: "DUCT_SYSTEM_SUPPORTED_CORRECTLY",
    status: true,
    type: "radio",
  },
  {
    id: 3,
    label: "DUCT SYSTEM MASTIC/SEALED CORRECTLY",
    name: "DUCT_SYSTEM_MASTIC_SEALED_CORRECTLY",
    value: "DUCT_SYSTEM_MASTIC_SEALED_CORRECTLY",
    status: true,
    type: "radio",
  },
  {
    id: 4,
    label: "DUCT SYSTEM INSULATED (IF REQUIRED) WITH PROPER R-VALUE",
    name: "DUCT_SYSTEM_INSULATED_IF_REQUIRED_WITH_PROPER_R_VALUE",
    value: "DUCT_SYSTEM_INSULATED_IF_REQUIRED_WITH_PROPER_R_VALUE",
    status: true,
    type: "radio",
  },
  {
    id: 5,
    label: "COLLARS - TAKE OFFS MASTIC/SEALED CORRECTLY",
    name: "COLLARS_TAKE_OFFS_MASTIC_SEALED_CORRECTLY",
    value: "COLLARS_TAKE_OFFS_MASTIC_SEALED_CORRECTLY",
    status: true,
    type: "radio",
  },
  {
    id: 6,
    label: "FLEX DUCT PROPERLY CONNECTED TRUNK/BOOTS ",
    name: "FLEX_DUCT_PROPERLY_CONNECTED_TRUNK_BOOTS_",
    value: "FLEX_DUCT_PROPERLY_CONNECTED_TRUNK_BOOTS_",
    status: true,
    type: "radio",
  },
  {
    id: 7,
    label: "FLEX DUCT PROPERLY HUNG/STRAPPED ",
    name: "FLEX_DUCT_PROPERLY_HUNG_STRAPPED_",
    value: "FLEX_DUCT_PROPERLY_HUNG_STRAPPED_",
    status: true,
    type: "radio",
  },
  {
    id: 8,
    label: "BOOTS INSULATED (IF REQUIRED)",
    name: "BOOTS_INSULATED_IF_REQUIRED",
    value: "BOOTS_INSULATED_IF_REQUIRED",
    status: true,
    type: "radio",
  },
  {
    id: 9,
    label: "BOOTS CUT OUT(S) PROPERLY CUT AND CAULKED",
    name: "BOOTS_CUT_OUT(S)_PROPERLY_CUT_AND_CAULKED",
    value: "BOOTS_CUT_OUT(S)_PROPERLY_CUT_AND_CAULKED",
    status: true,
    type: "radio",
  },
  {
    id: 10,
    label: "PENETRATIONS (FLOOR) PROPERLY CUT AND SEALED",
    name: "PENETRATIONS_(FLOOR)_PROPERLY_CUT_AND_SEALED",
    value: "PENETRATIONS_(FLOOR)_PROPERLY_CUT_AND_SEALED",
    status: true,
    type: "radio",
  },
  {
    id: 11,
    label: "T-STAT WIRE INSTALLED - PROPER LOCATION?",
    name: "T_STAT_WIRE_INSTALLED_PROPER_LOCATION?",
    value: "T_STAT_WIRE_INSTALLED_PROPER_LOCATION?",
    status: true,
    type: "radio",
  },
  {
    id: 12,
    label: "DRYER VENTING",
    name: "DRYER_VENTING",
    value: "DRYER_VENTING",
    status: true,
    type: "radio",
  },
  {
    id: 13,
    label: '4" PIPING INSTALLED TO PROPER TERM. POINT',
    name: '4"_PIPING_INSTALLED_TO_PROPER_TERM._POINT',
    value: '4"_PIPING_INSTALLED_TO_PROPER_TERM._POINT',
    status: true,
    type: "radio",
  },
  {
    id: 14,
    label: "JOINTS ARE TAPED ONLY (NO SCREWS)",
    name: "JOINTS_ARE_TAPED_ONLY_(NO_SCREWS)",
    value: "JOINTS_ARE_TAPED_ONLY_(NO_SCREWS)",
    status: true,
    type: "radio",
  },
  {
    id: 15,
    label: "WALL CAP PROPERLY CUT HOLE/SECURED/INSTALLED",
    name: "WALL_CAP_PROPERLY_CUT_HOLE_SECURED_INSTALLED",
    value: "WALL_CAP_PROPERLY_CUT_HOLE_SECURED_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 16,
    label: "MICROWAVE/EXHAUST HOOD VENTED",
    name: "MICROWAVE_EXHAUST_HOOD_VENTED",
    value: "MICROWAVE_EXHAUST_HOOD_VENTED",
    status: true,
    type: "radio",
  },
  {
    id: 17,
    label: "BATH EXHAUST",
    name: "BATH_EXHAUST",
    value: "BATH_EXHAUST",
    status: true,
    type: "radio",
  },
  {
    id: 18,
    label: "CORRECT EQUIPMENT INSTALLED",
    name: "CORRECT_EQUIPMENT_INSTALLED",
    value: "CORRECT_EQUIPMENT_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 19,
    label: '4" PIPING INSTALLED TO PROPER TERM. POINT',
    name: '4"_PIPING_INSTALLED_TO_PROPER_TERM._POINT',
    value: '4"_PIPING_INSTALLED_TO_PROPER_TERM._POINT',
    status: true,
    type: "radio",
  },
  {
    id: 20,
    label: "PIPE PROPERLY SUPPORTED/SECURED",
    name: "PIPE_PROPERLY_SUPPORTED_SECURED",
    value: "PIPE_PROPERLY_SUPPORTED_SECURED",
    status: true,
    type: "radio",
  },
  {
    id: 21,
    label: "FAN CONNECTED TO EXHAUST PROPERLY",
    name: "FAN_CONNECTED_TO_EXHAUST_PROPERLY",
    value: "FAN_CONNECTED_TO_EXHAUST_PROPERLY",
    status: true,
    type: "radio",
  },
  {
    id: 22,
    label: "WALL CAP PROPERLY CUT HOLE/SECURED/INSTALLED",
    name: "WALL_CAP_PROPERLY_CUT_HOLE_SECURED_INSTALLED",
    value: "WALL_CAP_PROPERLY_CUT_HOLE_SECURED_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 23,
    label: "LINESET (S)",
    name: "LINESET_(S)",
    value: "LINESET_(S)",
    status: true,
    type: "radio",
  },
  {
    id: 24,
    label: "PROPER SIZE INSTALLED WITH ARMAFLEX/T-STAT WIRE",
    name: "PROPER_SIZE_INSTALLED_WITH_ARMAFLEX_T_STAT_WIRE",
    value: "PROPER_SIZE_INSTALLED_WITH_ARMAFLEX_T_STAT_WIRE",
    status: true,
    type: "radio",
  },
  {
    id: 25,
    label: "NAIL PLATES-PROPER LOCATION/SIZE",
    name: "NAIL_PLATES_PROPER_LOCATION_SIZE",
    value: "NAIL_PLATES_PROPER_LOCATION_SIZE",
    status: true,
    type: "radio",
  },
  {
    id: 26,
    label: "SEALED AT BOTH ENDS",
    name: "SEALED_AT_BOTH_ENDS",
    value: "SEALED_AT_BOTH_ENDS",
    status: true,
    type: "radio",
  },
  {
    id: 27,
    label: "PRESSURE TESTED IF ANY JOINTS",
    name: "PRESSURE_TESTED_IF_ANY_JOINTS",
    value: "PRESSURE_TESTED_IF_ANY_JOINTS",
    status: true,
    type: "radio",
  },
  {
    id: 28,
    label: "CONDENSATE LINE INSTALLED WITH PROPER FALL",
    name: "CONDENSATE_LINE_INSTALLED_WITH_PROPER_FALL",
    value: "CONDENSATE_LINE_INSTALLED_WITH_PROPER_FALL",
    status: true,
    type: "radio",
  },
  {
    id: 29,
    label: "CONDENSATE LINE MALE ADAPTER PIPE DOPED/JOINTS GLUED",
    name: "CONDENSATE_LINE_MALE_ADAPTER_PIPE_DOPED_JOINTS_GLUED",
    value: "CONDENSATE_LINE_MALE_ADAPTER_PIPE_DOPED_JOINTS_GLUED",
    status: true,
    type: "radio",
  },
  {
    id: 30,
    label: "CONDENSATE LINE INSTALLED TO PROPER TERMINATION POINT",
    name: "CONDENSATE_LINE_INSTALLED_TO_PROPER_TERMINATION_POINT",
    value: "CONDENSATE_LINE_INSTALLED_TO_PROPER_TERMINATION_POINT",
    status: true,
    type: "radio",
  },
  {
    id: 31,
    label: "CONDENSATE LINE INSULATED (IF LOCATED ON EXTERIOR WALL OR ATTIC)",
    name: "CONDENSATE_LINE_INSULATED_(IF_LOCATED_ON_EXTERIOR_WALL_OR_ATTIC)",
    value: "CONDENSATE_LINE_INSULATED_(IF_LOCATED_ON_EXTERIOR_WALL_OR_ATTIC)",
    status: true,
    type: "radio",
  },
  {
    id: 32,
    label: "CONDENSATE TRAP PROPERLY INSTALLED",
    name: "CONDENSATE_TRAP_PROPERLY_INSTALLED",
    value: "CONDENSATE_TRAP_PROPERLY_INSTALLED",
    status: true,
    type: "radio",
  },
  {
    id: 33,
    label: "GAS FURNACE INSTALLED WITH PROPER PITCH",
    name: "GAS_FURNACE_INSTALLED_WITH_PROPER_PITCH",
    value: "GAS_FURNACE_INSTALLED_WITH_PROPER_PITCH",
    status: true,
    type: "radio",
  },
  {
    id: 34,
    label: "FLUE PIPES RAN TO EXTERIOR WITH PITCH BACK TO UNIT",
    name: "FLUE_PIPES_RAN_TO_EXTERIOR_WITH_PITCH_BACK_TO_UNIT",
    value: "FLUE_PIPES_RAN_TO_EXTERIOR_WITH_PITCH_BACK_TO_UNIT",
    status: true,
    type: "radio",
  },
  {
    id: 35,
    label: "FLUE PIPE ROOF FLASHING",
    name: "FLUE_PIPE_ROOF_FLASHING",
    value: "FLUE_PIPE_ROOF_FLASHING",
    status: true,
    type: "radio",
  },
  {
    id: 36,
    label: "AIR HANDLER INSTALLED LEVEL",
    name: "AIR_HANDLER_INSTALLED_LEVEL",
    value: "AIR_HANDLER_INSTALLED_LEVEL",
    status: true,
    type: "radio",
  },
  {
    id: 37,
    label: "DRAIN PAN INSTALLED PROPERLY UNDER EQUIPMENT",
    name: "DRAIN_PAN_INSTALLED_PROPERLY_UNDER_EQUIPMENT",
    value: "DRAIN_PAN_INSTALLED_PROPERLY_UNDER_EQUIPMENT",
    status: true,
    type: "radio",
  },
  {
    id: 38,
    label: "EQUIPMENT PROPERLY SUPPORTED",
    name: "EQUIPMENT_PROPERLY_SUPPORTED",
    value: "EQUIPMENT_PROPERLY_SUPPORTED",
    status: true,
    type: "radio",
  },
  {
    id: 39,
    label: "EQUIPMENT PROPERLY RAISED TO ACCOMMODATE DRAIN",
    name: "EQUIPMENT_PROPERLY_RAISED_TO_ACCOMMODATE_DRAIN",
    value: "EQUIPMENT_PROPERLY_RAISED_TO_ACCOMMODATE_DRAIN",
    status: true,
    type: "radio",
  },
  {
    id: 40,
    label: "DUCTWORK CONNECTED TO UNITS CORRECTLY",
    name: "DUCTWORK_CONNECTED_TO_UNITS_CORRECTLY",
    value: "DUCTWORK_CONNECTED_TO_UNITS_CORRECTLY",
    status: true,
    type: "radio",
  },
  {
    id: 41,
    label: "FILTER REMOVED IF USING FILTER GRILLES",
    name: "FILTER_REMOVED_IF_USING_FILTER_GRILLES",
    value: "FILTER_REMOVED_IF_USING_FILTER_GRILLES",
    status: true,
    type: "radio",
  },
  {
    id: 42,
    label: "ORDER INSPECTION",
    name: "ORDER_INSPECTION",
    value: "ORDER_INSPECTION",
    status: true,
    type: "radio",
  },
  {
    id: 43,
    label: "ORDER DUCT BLAST",
    name: "ORDER_DUCT_BLAST",
    value: "ORDER_DUCT_BLAST",
    status: true,
    type: "radio",
  },
  {
    id: 44,
    label: "IS ROUGH READY TO BILL",
    name: "IS_ROUGH_READY_TO_BILL",
    value: "IS_ROUGH_READY_TO_BILL",
    status: true,
    type: "radio",
  },
];
