/** @format */

import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectLoggedInUser } from "./authSlice";
function Protected({ children }) {
  const user = useSelector((state) => state.user.loggedInUser);
  if (user.length == 0) {
    return <Navigate to="/" replace={true}></Navigate>;
  }
  return children;
}
export default Protected;
