/** @format */
import React, { useEffect, useState } from "react";
import {
  BASE_URL_PORT_LOCAL_5000,
  FORM_ASSIGN_CANCEL_BTN,
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  INPUT_FIELD_AUTH,
} from "../generals/common";
import { costFields, validationOfCostField } from "./formConfig";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import { costcode } from "./data";
import Axios from "axios";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
const CostCode = ({ onNext, onBack, onStepOne, isEditMode }) => {
  const [fieldValues, setFieldValues] = useState(costFields);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [initialValue, setInitialValue] = useState({ ...fieldValues });
  // const isEditMode = localStorage.getItem("costCode") ? true : false;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("costCode")) || {}
  );
  useEffect(() => {
    setErrors(validationOfCostField(fieldValues));
  }, [fieldValues, data]);
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("costCode"));
    if (storedData) {
      setData(storedData);
    }
  }, [fieldValues]);
  useEffect(() => {
    if (isEditMode) {
      setFieldValues({
        ...fieldValues,
        ...data?.cost_code,
      });
    }
  }, []);
  const [selectedCostCode, setSelectedCostCode] = useState(
    isEditMode ? data?.cost_code : 0
  );
  // ... (other useEffect and functions)
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    if (name === "cost_code") {
      // Update the local state for cost_code
      setSelectedCostCode(value);
    } else {
      // Update other fields in fieldValues
      setFieldValues({
        ...fieldValues,
        [name]: value,
      });
    }
  };
  const addJob = async (uid) => {
    setLoading(true);
    let job = {
      jobField: JSON.parse(localStorage.getItem("jobField")),
      addressField: JSON.parse(localStorage.getItem("addressField")),
      costCode: fieldValues,
    };
    const authAxios = Axios.create({
      // baseURL: "https://server-beige-delta.vercel.app/api/job/add-job",
      baseURL: `https://server-beige-delta.vercel.app/api/job/add-job`,
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post(`https://server-beige-delta.vercel.app/api/job/add-job`, { job })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40002) {
          Swal.fire({
            title: "Congratulations!",
            text: `You have created the job ID is ${response?.data?.data?._id}`,
            icon: "success",
            confirmButtonColor: "#d33",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/job/view-job");
              localStorage.clear();
            }
          });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else if (response?.data?.status == 40033) {
          Swal.fire({
            title: "Duplicate Job!",
            text: `${response?.data?.message}`,
            icon: "error",
            confirmButtonColor: "#d33",
          }).then((result) => {
            if (result.isConfirmed) {
              handleStepOne();
              localStorage.setItem("enableJob", true);
            }
          });
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const updateJob = async (uid) => {
    setLoading(true);
    let job = {
      jobField: JSON.parse(localStorage.getItem("jobField")),
      addressField: JSON.parse(localStorage.getItem("addressField")),
      costCode: fieldValues,
      _id: JSON.parse(localStorage.getItem("_id")),
    };
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/job/update-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .put("https://server-beige-delta.vercel.app/api/job/update-job", { job })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40003) {
          Swal.fire({
            title: "Updated!",
            text: `You have Updated the JOB ID is ${response?.data?.data?._id}`,
            icon: "success",
            confirmButtonColor: "#d33",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/job/view-job");
              localStorage.clear();
            }
          });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const handleFieldChanges = (e) => {
    setFieldValues({
      ...fieldValues,
      cost_code: e,
    });
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      if (isEditMode) {
        updateJob();
      } else {
        addJob();
      }
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const handleStepOne = () => {
    onStepOne();
  };
  return (
    <div>
      {loading ? <Spinner /> : null}
      <div className=" ">
        <div className="pt-8 px-8 xs:py-2  xs:pt-4 xs:px-4 ">
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Cost Code<span className="text-red-800">*</span>
            </label>
            <div>
              {/* <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="cost_code"
                value={selectedCostCode}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {costcode.map((data) => (
                  <option key={data.id} value={data.code}>
                    {data.code}
                  </option>
                ))}
              </select> */}
              <Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                isMulti
                name="colors"
                options={costcode}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleFieldChanges}
              />
            </div>
          </div>
          {showError && errors.cost_code && (
            <small className="text-red-700	">{errors.cost_code}</small>
          )}
        </div>
        <div className="px-8 xs:px-5 flex justify-center gap-8 xs:justify-between ">
          <div className="flex justify-end mt-6">
            <button type="button" className={FORM_ASSIGN_CANCEL_BTN}>
              Cancel
            </button>
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleSubmit}
            >
              {isEditMode ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CostCode;
