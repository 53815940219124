/** @format */
import React, { useEffect, useState } from "react";
import "../../component/stepper/stepper.css";
import { TiTick } from "react-icons/ti";
import Job from "./Job";
import Address from "./Address";
import CostCode from "./CostCode";
import ContractItem from "./ContractItem";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Axios from "axios";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
const Stepper = () => {
  const steps = ["Job", "Address", "Cost Code"];
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const jobStateSelector = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const { jobId } = useParams();
  const isEditMode = jobId ? true : false;
  const getJobById = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/job/job-by-id",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/job/job-by-id", {
        _id: jobId,
      })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 20026) {
          const jobField = response?.data?.foundJob?.job[0].jobField;
          const addressField = response?.data?.foundJob?.job[0].addressField;
          const costCode = response?.data?.foundJob?.job[0].costCode;
          const _id = response?.data?.foundJob?._id;
          localStorage.setItem("jobField", JSON.stringify(jobField));
          localStorage.setItem("addressField", JSON.stringify(addressField));
          localStorage.setItem("costCode", JSON.stringify(costCode));
          localStorage.setItem("_id", JSON.stringify(_id));
          // localStorage.setItem();
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    if (isEditMode) {
      getJobById();
    }

  }, []);
  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };
  const handleStepOne = () => {
    setCurrentStep(1);
  };
  return (
    <>
      <div className="max-w-4xl mx-auto shadow-lg mt-8 py-10 rounded-lg xs:py-6">
        <div className="flex justify-center align-middle ">
          {steps?.map((step, i) => (
            <div
              key={i}
              className={`step-item ${currentStep === i + 1 && "active"} ${
                (i + 1 < currentStep || complete) && "complete"
              } `}
            >
              <div className="step xs:w-9 xs:h-9">
                {i + 1 < currentStep || complete ? <TiTick size={24} /> : i + 1}
              </div>
              <p className="text-slate-700 font-semibold xs:text-xs whitespace-nowrap">
                {step}
              </p>
            </div>
          ))}
        </div>
        <div style={{ overflow: "auto" }}>
          {currentStep == 1 ? (
            <Job onNext={handleNext} isEditMode={isEditMode} />
          ) : currentStep == 2 ? (
            <Address onNext={handleNext} onBack={handleBack} />
          ) : currentStep == 3 ? (
            <CostCode
              onNext={handleNext}
              onBack={handleBack}
              onStepOne={handleStepOne}
              isEditMode={isEditMode}
            />
          ) : (
            <ContractItem onNext={handleNext} onBack={handleBack} />
          )}
        </div>
        {/* <div className=" mt-6 px-8 flex justify-center   ">
          <tr>
            {currentStep > 1 ? (
              <td>
                <button
                  className="xs:h-12 px-6 bg-[#494b74] leading-10 hover:bg-opacity-90 h-14 text-white rounded-lg"
                  onClick={() => {
                    currentStep === steps.length
                      ? setComplete(true)
                      : setCurrentStep((prev) => prev - 1);
                  }}
                >
                  <ArrowBack width={24} height={20} />
                </button>
              </td>
            ) : null}
            <td>
              {!complete && (
                <button
                  className="xs:h-12 px-6 bg-[#494b74] ml-2 hover:bg-opacity-90 h-14 text-white rounded-lg font-bold"
                  onClick={() => {
                    currentStep === steps.length
                      ? setComplete(true)
                      : setCurrentStep((prev) => prev + 1);
                  }}
                >
                  {currentStep === steps.length ? "Finish" : "Next"}
                </button>
              )}
            </td>
          </tr>
        </div> */}
      </div>
    </>
  );
};
export default Stepper;
