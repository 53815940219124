/** @format */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createJob,
  createAddress,
  createCostCode,
  createContractIem,
} from "./jobAPI";
const initialState = {
  loggedInUser: null,
  status: "idle",
  error: null,
};
export const createJobAsync = createAsyncThunk(
  "jobMaster/createJob",
  async (jobData) => {
    const response = await createJob(jobData);
    return response.data;
  }
);
export const createAddressAsync = createAsyncThunk(
  "jobMaster/createAddress",
  async (userData) => {
    const response = await createAddress(userData);
    return response.data;
  }
);
export const createCostCodeAsync = createAsyncThunk(
  "jobMaster/createCostCode",
  async (userData) => {
    const response = await createCostCode(userData);
    return response.data;
  }
);
export const createContractIemAsync = createAsyncThunk(
  "jobMaster/createContractIem",
  async (userData) => {
    const response = await createContractIem(userData);
    return response.data;
  }
);
const jobMasterReducer = createSlice({
  name: "jobMaster",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createJobAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createJobAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.jobMaster = action.payload;
      })
      .addCase(createJobAsync.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(createAddressAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAddressAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.jobMaster = action.payload;
      })
      .addCase(createAddressAsync.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(createCostCodeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCostCodeAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.jobMaster = action.payload;
      })
      .addCase(createCostCodeAsync.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      })
      .addCase(createContractIemAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createContractIemAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.jobMaster = action.payload;
      })
      .addCase(createContractIemAsync.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error;
      });
  },
});
export const selectJobMaster = (state) => state.jobMaster;
export const selectError = (state) => state.error;
export const selectLoading = (state) => state.loading;
export default jobMasterReducer.reducer;
