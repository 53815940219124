/** @format */
// import Image from "next/image";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import uploadImage from "../../../../src/assets/images/fetchJobsSVG.svg";
import searchIcon from "../../../../src/assets/images/searchSVG.svg";
import * as xlsx from "xlsx";
import {
  faCheckSquare,
  faTrash,
  faEdit,
  faEye,
  faExternalLink,
} from "@fortawesome/free-solid-svg-icons";
import {
  INPUT_SEARCH,
  SEARCH_SUBMIT,
  UPLOAD_BTN,
  BASE_URL_PORT_LOCAL_5000,
  customStylesForDatatable,
} from "../../../../src/components/generals/common";
import Spinner from "../../../../src/components/Spinner";
import { importExcelData } from "./formState";
import axios from "axios";
import { MenuItem, Select } from "@mui/material";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransitionsModal from "../../../../src/components/modal/TransitionsModal";
import url from "../../core";
import moment from "moment";
const ImportExcel = () => {
  const [excelData, setExcelData] = useState([]);
  const [column, setColumn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const [query, setQuery] = useState("");
  const [fieldValues, setFieldValues] = useState(importExcelData);
  const baseURL = BASE_URL_PORT_LOCAL_5000;
  useEffect(() => {
    if (excelData && excelData.length > 0) {
      setLoading(true);
      const columnNames = Object.keys(excelData[0]);
      const newColumns = columnNames.map((columnName) => {
        return {
          name: columnName,
          selector: (row) => row[columnName],
        };
      });
      newColumns.unshift({
        name: "Action",
        selector: (row) => (
          // Define the action you want for each row
          // This could be a button, link, or any other action you want to perform
          <tr>
            <>
              <td>
                <TransitionsModal data={row} title={"Detail For "} />
              </td>
            </>
          </tr>
        ),
      });
      setColumn(newColumns);
    } else {
      console.error("excelData is undefined or empty.");
    }
    setLoading(false);
  }, [excelData]);
  const handleOptionChange = (event) => {
    setSelectOption(event.target.value);
  };
  function extractFilenameAndExtension(filePath) {
    // Get the base filename from the file path
    const baseFilename = filePath?.name.split("/").pop().split("\\").pop();
    const filenameWithoutSpaces = baseFilename.replace(/\s/g, "");
    const dotIndex = filenameWithoutSpaces.lastIndexOf(".");
    const filename = filenameWithoutSpaces.substring(0, dotIndex);
    const extension = filenameWithoutSpaces.substring(dotIndex);
    setFieldValues({
      ...fieldValues,
      file_name: filename,
      file_extension: extension,
    });
  }
  const readExcel = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    extractFilenameAndExtension(file);
    const data = await file.arrayBuffer(file);
    const excelfile = xlsx.read(data);
    const excelsheet = excelfile.Sheets[excelfile.SheetNames[0]];
    const exceljson = xlsx.utils.sheet_to_json(excelsheet);
    setExcelData(exceljson);
    setFieldValues({ ...fieldValues, file: exceljson });
    setLoading(false);
  };
  const addRoleApi = async () => {
    setLoading(true);
    const apiUrl = `http://localhost:5000/api/file/file-add-job`;
    var formData = new FormData();
    file.forEach((obj, index) => {
      Object.keys(obj).forEach((key) => {
        if (obj[key] instanceof File || obj[key] instanceof Blob) {
          formData.append(`${key}_${index}`, obj[key], obj[key].name);
        } else {
          formData.append(`${key}_${index}`, JSON.stringify(obj[key]));
        }
      });
    });
    axios
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response?.data?.status == 20000) {
          Swal.fire({
            title: "Success ",
            text: `${response?.data?.message}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#fe1626",
            // cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // router.push("/dashboard");
            }
          });
        } else {
          Swal.fire(
            "Something went wrong",
            `${response?.data?.message}`,
            "error"
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setLoading(false);
  };
  const getFile = async () => {
    setLoading(true);
    const apiUrl = `${url}/api/file/file-get`;
    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setExcelData(response.data?.File[0].file);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setLoading(false);
  };
  const getFileById = async () => {
    setLoading(true);
    const apiUrl = `${url}/api/file/file-get-by-id`;
    axios
      .post(
        apiUrl,
        { _id: "6568c5f2f2a21c9043fa0efb" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setExcelData(response?.data?.file?.file);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setLoading(false);
  };
  const getFileName = async () => {
    setLoading(true);
    const apiUrl = `${url}/api/file/file-names`;
    axios
      .get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSelectOption(response.data?.extractedData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setLoading(false);
  };
  const handleChange = ({ selectedRows }) => {
    setFile(selectedRows);
    setFieldValues({
      ...fieldValues,
      file: selectedRows,
    });
  };
  const handleSubmit = () => {
    addRoleApi();
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const searchParam = (data) => {
    return data?.filter((item) => {
      // Check if any field in the item contains the query
      return Object.values(item).some((field) => {
        if (field !== undefined && field !== null) {
          if (typeof field === "string" || typeof field === "number") {
            return field
              .toString()
              .toLowerCase()
              .includes(query?.toLowerCase());
          } else if (field instanceof Date) {
            return new moment(field).format("DD-MM-YYYY").includes(query);
          }
        }
        return false;
      });
    });
  };
  return (
    <div className="max-w-6xl p-8 mx-auto shadow-lg mt-8 rounded-lg ">
      {loading ? <Spinner /> : null}
      <div className="flex gap-6 items-center mb-8 flex-wrap xs:gap-2 xs:justify-between ">
        <div className="text-secondary text-xl lg:text-2xl font-bold xs:text-lg">
          Choose Excel File :
        </div>
        <div className="upload-btn-wrapper">
          <button className={UPLOAD_BTN}>
            Upload file{" "}
            <img
              src={uploadImage}
              width={24}
              height={"auto"}
              className="icon-white"
            />
          </button>
          <input
            type="file"
            className="form-control cursor-pointer"
            name="image"
            onChange={(e) => readExcel(e)}
          />
        </div>
        <div className="ml-auto xs:w-full xs:max-w-full xs:m-0 w-80 md:w-65 lg:max-w-65 ">
          <form>
            <label
              for="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 ps-10 text-sm text-secondary  border-primary rounded-lg focus:outline-primary bg-white   focus:border-lightRed border-2 dark:placeholder-gray-40 "
                placeholder="Search..."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center items-center gap-4 mb-8">
        <div>
          <select
            className="form-control p-3 list1"
            name="file_id"
            value={fieldValues.file_id}
            onChange={handleFieldChange}
          >
            <option value="0" selected>
              Please Select
            </option>
            {selectOption?.map((data, idx) => {
              return (
                <option className="options" key={idx} value={data._id}>
                  {data.file_name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="shadow-lg dataTable overflow-scroll ">
        <DataTable
          columns={column}
          // data={excelData}
          data={searchParam(excelData)}
          customStyles={customStylesForDatatable}
          // data={searchParam(excelData)}
          selectableRows
          pagination
          onSelectedRowsChange={handleChange}
        />
      </div>
      <label
        for="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      {excelData?.length ? (
        <div className="relative mt-14 ">
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-lime-900 hover:bg-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 0"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      ) : null}
    </div>
  );
};
export default ImportExcel;
