/** @format */
import React, { useEffect, useState } from "react";
import {
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  FORM_ASSIGN_CANCEL_BTN,
  FORM_MAIN_WRAPPER,
  FORM_INNER_PADDING,
  STEPPER_LABLE_WRAPPER,
  STEPPER_FORM_INPUT_FIELD,
  PLUS_BUTTON_CSS,
  BASE_URL_PORT_LOCAL_5000,
  FORM_ASSIGN_SKIP_BTN,
  // PLUS_BUTTON,
} from "../generals/common";
import { fcRoughin, fcTrim } from "./data";
import { FC_TRIM, validationOfFCTrim } from "./formState";
import Spinner from "../../component/Spinner";
import { useNavigate } from "react-router-dom";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import Axios from "axios";
import Swal from "sweetalert2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tooltip } from "@mui/material";
import { SWAL_CANCEL_DESIGN, SWAL_CONFIRM_DESIGN } from "../../generals/common";
const FixtureCountTrim = ({ onBack }) => {
  const [fieldValues, setFieldValues] = useState(FC_TRIM);
  const [users, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const formCondition = JSON.parse(localStorage.getItem("formAlign"));
  const [inputs, setInputs] = useState(["", ""]);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const [editMode, setEditMode] = useState(false);
  const [underQCSlabData, setQCUnderSlabData] = useState([]);
  const [underFCSlabData, setFCUnderSlabData] = useState([]);
  const [qcRoughIn, setQCRoughInData] = useState([]);
  const [fcRoughIn, setFCRoughInData] = useState([]);
  const [qcTrim, setQCTrimData] = useState([]);
  const [formState, setFormState] = useState(false);
  const navigate = useNavigate();
  const fetchUserList = async () => {
    setLoading(true);
    fetch(`https://server-beige-delta.vercel.app/api/user/get-user-list`)
      .then((response) => response.json())
      .then((json) => setUser(json?.user));
    getJobByJobNo(formCondition.Job_id);
  };
  const getJobByJobNo = async (jobNo) => {
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/form-by-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/form-by-job", {
        jobNo,
      })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        let msg = response?.data?.message;
        if (response?.data?.status === 20022) {
          const data = response?.data?.foundForm?.form[0]?.FC_TRIM;
          console.log("🚀 ~ .then ~ data:", data);
          const _id = response?.data?.foundForm?._id;
          const formState = response?.data?.foundForm != null;
          setFormState(formState);
          localStorage.setItem("formMDBId", _id);
          setQCUnderSlabData(response?.data?.foundForm?.form[0]?.QC_UNDER_SLAB);
          setFCUnderSlabData(response?.data?.foundForm?.form[0]?.FC_UNDER_SLAB);
          setQCRoughInData(response?.data?.foundForm?.form[0]?.QC_ROUGH_IN);
          setFCRoughInData(response?.data?.foundForm?.form[0]?.FC_ROUGH_IN);
          setQCTrimData(response?.data?.foundForm?.form[0]?.QC_TRIM);
          setFieldValues({
            ...data,
          });
          console.log("editmode", editMode);
          if (data && Object.keys(data && data).length > 0) {
            console.log("hello");
            setEditMode(true);
          }
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const updateForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      QC_ROUGH_IN: qcRoughIn,
      QC_UNDER_SLAB: underQCSlabData,
      FC_UNDER_SLAB: underFCSlabData,
      FC_ROUGH_IN: fcRoughIn,
      QC_TRIM: qcTrim,
      FC_TRIM: fieldValues,
      _id: localStorage.getItem("formMDBId"),
    };
    console.log("🚀 ~ updateForm ~ form:", form);
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/update-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .put("https://server-beige-delta.vercel.app/api/form/update-job", {
        form,
      })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40001) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          Swal.fire({
            title: "Successful",
            text: "Thank You For Submit!",
            icon: "success",
            confirmButtonColor: "#d33",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/form");
              localStorage.clear();
            }
          });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const addForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      FC_TRIM: fieldValues,
    };
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/add-form",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/add-form", { form })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40002) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Successful",
              text: "Form has been Updated!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Want to Stay on Page?",
              cancelButtonText: "No!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setFieldValues({
                  ...initialState,
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate("/form");
              }
            });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    fetchUserList();
  }, []);
  useEffect(() => {
    setErrors(validationOfFCTrim(fieldValues));
  }, [fieldValues]);
  const submitForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      QC_under_slab: JSON.parse(localStorage.getItem("QC_under_slab")),
      FC_under_slab: JSON.parse(localStorage.getItem("FC_under_slab")),
      QC_rough_in: JSON.parse(localStorage.getItem("QC_rough_in")),
      FC_rough_in: JSON.parse(localStorage.getItem("FC_rough_in")),
      QC_trim: JSON.parse(localStorage.getItem("QC_trim")),
      FC_trim: fieldValues,
    };
    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/form/add-form`,
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post(`https://server-beige-delta.vercel.app/api/form/add-form`, { form })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40002) {
          Swal.fire({
            title: "Successful",
            text: `Job has been Completed!`,
            icon: "success",
            confirmButtonColor: "#d33",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/form");
              localStorage.clear();
            }
          });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      if (formState) {
        updateForm();
      } else {
        addForm();
      }
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const handleFieldChangeNotANumber = (event) => {
    const { name, value } = event.target;
    if (!isNaN(value) || value === "") {
      setFieldValues({
        ...fieldValues,
        [name]: value,
      });
    } else {
      setFieldValues({
        ...fieldValues,
        [name]: 0,
      });
    }
  };
  const handleKeyDown = (e, inputName) => {
    if (e.key === "Backspace" && fieldValues[inputName].length > 0) {
      setFieldValues((prevValues) => ({
        ...prevValues,
        [inputName]: prevValues[inputName].slice(0, -1),
      }));
    }
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleBack = () => {
    onBack();
  };
  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };
  const handleButtonClick = () => {
    setInputs([...inputs, "", ""]);
  };
  const actualTotalDeducted = fcTrim.reduce((total, item) => {
    const value =
      (parseFloat(fieldValues[item.deductedFieldName]) || 0) * item.unitPrice;
    return total + value;
  }, 0);
  const actualTotalSubPay = fcTrim.reduce((total, item) => {
    const value =
      (parseFloat(fieldValues[item.subPayFieldName]) || 0) * item.unitPrice;
    return total + value;
  }, 0);
  const handleNext = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      if (fieldValues) {
        localStorage.setItem("FC_under_slab", JSON.stringify(fieldValues));
      }
      //  onNext();
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  return (
    <div className={FORM_MAIN_WRAPPER}>
      {loading ? <Spinner /> : null}
      <div className="px-8 pb-4 text-center border-b border-#ebedf3">
        <h1 className="font-bold text-base mb-1">Fixture Count Trim</h1>
        <p className="underline text-sm justify-center flex gap-1">
          <u>Note:-</u>
          <span className="flex gap-1">
            <b className="text-green-800">QC=Quality Control</b>,
            <b className="text-green-800">FC=Fixture Count</b>
            <b className="text-yellow-500">
              Please Hover To BUTTONS To Check Their Behavior
            </b>
          </span>
        </p>
      </div>
      <div className={FORM_INNER_PADDING}>
        <div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Date<span className="text-red-800">*</span>
            </label>
            <div>
              <input
                className={FORM_ASSIGN_INPUT_FIELD}
                type="date"
                name="FC_TRIM_DATE"
                value={fieldValues.FC_TRIM_DATE}
                onChange={handleFieldChange}
              />
            </div>
            {showError && errors.FC_TRIM_DATE && (
              <small className="text-red-700	">{errors.FC_TRIM_DATE}</small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Form Filled Out By<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="FC_TRIM_FILLED_OUT_BY"
                value={fieldValues.FC_TRIM_FILLED_OUT_BY}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {users.map((data) => {
                  return <option value={data._id}>{data.reg_name}</option>;
                })}{" "}
              </select>
            </div>
            {showError && errors.FC_TRIM_FILLED_OUT_BY && (
              <small className="text-red-700	">
                {errors.FC_TRIM_FILLED_OUT_BY}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Whose work being inspected<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="FC_QC_TRIM_BEING_INSPECTED"
                value={fieldValues.FC_QC_TRIM_BEING_INSPECTED}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {users.map((data, idx) => {
                  if (data._id != fieldValues.FC_TRIM_FILLED_OUT_BY)
                    return (
                      <option key={idx} value={data._id}>
                        {data.reg_name}
                      </option>
                    );
                })}{" "}
              </select>
            </div>
            {showError && errors.FC_TRIM_BEING_INSPECTED && (
              <small className="text-red-700	">
                {errors.FC_TRIM_BEING_INSPECTED}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">Sub Contractor</label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="FC_TRIM_SUB_CONTRACTOR"
                value={fieldValues.FC_TRIM_SUB_CONTRACTOR}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {users?.map((data) => {
                  return <option value={data._id}>{data.reg_name}</option>;
                })}{" "}
              </select>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="font-bold mb-8 text-center text-base ">
              Confirm the following{" "}
            </h2>
            <tr className="bg-primary p-4 w-full flex text-sm gap-0 justify-between text-white rounded-lg mb-8">
              <td className=" font-extrabold w-2/4">Fixtures(qty)</td>
              <td className=" font-extrabold">Total Quantity</td>
              <td className=" font-extrabold">Deducted From Sub Pay</td>
            </tr>
          </div>
          {/* Fixture thing */}
          {/* dynamic form start */}
          <div>
            <div className="h-[500px] overflow-y-auto stepperFormTable ">
              {fcTrim.map((item, index) => (
                <div key={index} className="flex gap-4 items-center">
                  <table className="w-full">
                    <tbody>
                      <div className="flex gap-4 mb-4 w-full">
                        <div className={STEPPER_LABLE_WRAPPER}>
                          <label className="text-ellipsis text-sm block mb-2">
                            {item.label}{" "}
                            <span className="text-green-800">
                              {item.unitPrice !== null
                                ? `$${item.unitPrice} EA`
                                : ""}
                            </span>
                          </label>
                        </div>
                        <div>
                          <input
                            className={`${STEPPER_FORM_INPUT_FIELD} w-28 `}
                            type="text"
                            name={`${item.subPayFieldName}`}
                            value={fieldValues[`${item.subPayFieldName}`] || ""}
                            onChange={handleFieldChangeNotANumber}
                            maxLength="2"
                            onKeyDown={(e) =>
                              handleKeyDown(e, `${item.subPayFieldName}`)
                            }
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            className={`${STEPPER_FORM_INPUT_FIELD} w-28`}
                            value={fieldValues[item.deductedFieldName] || ""}
                            name={item.deductedFieldName}
                            onChange={handleFieldChangeNotANumber}
                            maxLength="2"
                            onKeyDown={(e) =>
                              handleKeyDown(e, item.deductedFieldName)
                            }
                          />
                        </div>
                      </div>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
            <div className="flex gap-4 py-3  items-center border-t border-b ">
              <tr className="flex gap-4 items-center  w-full">
                <td className={STEPPER_LABLE_WRAPPER}>
                  <div>
                    <label className="text-ellipsis text-primary  text-base font-black  block mb-2	">
                      TOTAL
                    </label>
                  </div>
                </td>
                <td className="w-40 font-bold text-base flex items-center px-4 border-2 border-secondary rounded-lg h-12">
                  {isNaN(actualTotalSubPay)
                    ? "Invalid Input"
                    : `$${actualTotalSubPay}`}
                </td>
                <td className="w-40 font-bold text-base flex items-center px-4 border-2 border-secondary rounded-lg h-12">
                  {isNaN(actualTotalDeducted)
                    ? "Invalid Input"
                    : `$${actualTotalDeducted}`}
                </td>
              </tr>
            </div>
          </div>
          {/* end of Fixture */}
          {/* dynamic from end  */}
          {/* additional installation  */}
          <div className="mt-8">
            <table className="w-full flex justify-around text-white rounded-lg mb-8">
              <tbody className="w-full">
                <tr className="bg-primary w-full flex justify-around text-white rounded-lg mb-8">
                  <td className="p-4 font-extrabold">
                    List Any Extra Installation(s) By Sub
                  </td>
                  <td className="p-4 font-extrabold">Extra Amount To Pay</td>
                </tr>
                <div className="grid grid-cols-2  px-4 mb-4">
                  {inputs.map((input, index) => (
                    <tr key={index} className="px-4 xs:p-0">
                      <td className="p-4  block">
                        <input
                          type="text"
                          value={input}
                          className={`${FORM_ASSIGN_INPUT_FIELD} text-neutral-950`}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </div>
                <td className="text-right flex   w-full px-4">
                  <td className="w-full"></td>
                  <td className="w-full flex justify-center ">
                    <button
                      onClick={handleButtonClick}
                      className={PLUS_BUTTON_CSS}
                    >
                      <span className="-mt-1.5">+</span>
                    </button>
                  </td>
                </td>
              </tbody>
            </table>
          </div>
          {/* {Array(4)
            .fill(1)
            .map((data, idx) => {
              return (
                <div>
                  <tr className="flex justify-around px-4 mb-4">
                    <td className="w-2/5">
                      <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
                    </td>
                    <td className="w-2/5">
                      <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
                    </td>
                  </tr>
                </div>
              );
            })}
          <hr className="my-4" />
          <tr className="flex justify-around flex-wrap px-4 mb-8">
            <td className="w-2/5 xs:w-full">
              <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
            </td>
            <td className="w-2/5 xs:w-full">
              <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
            </td>
          </tr> */}
          {/* end of additional installation */}
          <tr className="bg-primary w-full flex justify-between text-white rounded-lg mb-8 ">
            <td className="p-4">
              <h2 className="font-bold  text-center text-base ">
                ADDITIONAL NOTES
              </h2>
            </td>
            <td className="p-4">
              {" "}
              <h2 className="font-bold text-center text-base ">AMEND FORM </h2>
            </td>
          </tr>
          <tr className="flex items-center flex-wrap ">
            <td className="w-2/4">
              {" "}
              <div className="mt-8">
                <textarea
                  id=""
                  cols="30"
                  rows="10"
                  name="FC_TRIM_ADDITIONAL_NOTE"
                  value={fieldValues.FC_TRIM_ADDITIONAL_NOTE}
                  onChange={handleFieldChange}
                  className={`${FORM_ASSIGN_INPUT_FIELD} h-auto w-full`}
                ></textarea>
              </div>
            </td>
            <td className="w-2/5">
              <input
                type="checkbox"
                className="ml-32 scale-150 shadow-sm"
                name="FC_TRIM_FORM_AMENDED"
                value={true || false}
                onChange={handleFieldChange}
                checked={fieldValues.FC_TRIM_FORM_AMENDED}
              />
            </td>
          </tr>
        </div>
      </div>
      {/* <div className="px-8 flex justify-center gap-8">
        <div className="flex justify-end mt-6">
          <button
            type="button"
            className={FORM_ASSIGN_CANCEL_BTN}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </div> */}
      <div className="px-8 xs:px-5 flex justify-center mt-6 gap-6 xs:justify-between ">
        <div>
          <Tooltip title="This will take back">
            <button
              type="button"
              className={`bg-opacity-75 flex items-center justify-center gap-2 ${FORM_ASSIGN_CANCEL_BTN}`}
              onClick={handleBack}
            >
              <ArrowBackIcon style={{ marginTop: 2 }} /> Back
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="This will add/update record">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleSubmit}
            >
              {editMode ? "Update" : "Submit"}
              {console.log("🚀 ~ FixtureCountTrim ~ editMode:", editMode)}
            </button>
          </Tooltip>
        </div>
        {/* <div>
          <Tooltip title="This will store data and jump to next form">
            <button onClick={handleNext} className={FORM_ASSIGN_CANCEL_BTN}>
              Next
            </button>
          </Tooltip>
        </div> */}
      </div>
    </div>
  );
};
export default FixtureCountTrim;
