/** @format */
import React, { useEffect, useState } from "react";
import {
  createCompanyConfig,
  errorValidationForCreateCompanyConfig,
} from "./formState";
import { errorFormFieldsNotComplete } from "../../generals/swalMessages";
import Spinner from "../Spinner";
import { FormControlLabel, Switch } from "@mui/material";
import Axios from "axios";
import Swal from "sweetalert2";
// import { PORT_PRODUCTION } from "../../generals/common";
import {
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_SUBMIT_BTN,
} from "../../generals/common";
import { useParams } from "react-router-dom";
const CreateCompany = () => {
  const [fieldValues, setFieldValues] = useState(createCompanyConfig);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const [showErrors, setShowErrors] = useState(false);
  const { companyId } = useParams();
  const [isEditMode, setIsEditMode] = useState(
    companyId != undefined ? true : false
  );
  // const baseURL = PORT_PRODUCTION;
  const regext = "^(?![0-9._])(?!.*[0-9._]$)(?!.*d_)(?!.*_d)[a-zA-Z0-9_]+$";
  useEffect(() => {
    setErrors(errorValidationForCreateCompanyConfig(fieldValues));
  }, [fieldValues]);
  useEffect(() => {
    if (isEditMode) {
      getCompany();
    }
  }, []);
  const getCompany = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/company/company-by-id`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .post(`https://server-beige-delta.vercel.app/api/company/company-by-id`, {
        companyId,
      })
      .then((response) => {
        if (response?.data?.status == 20022) {
          const data = response?.data?.companyFound;
          setFieldValues({ ...fieldValues, ...data });
          setToggleState(data?.status);
        } else {
          // Swal.fire(
          //   "Something went wrong",
          //   ${response?.data?.message},
          //   "error"
          // );
        }
      })
      .catch((err) => {});
    setLoading(false);
  };
  const addCompanyApi = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/company/add-company`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .post(`https://server-beige-delta.vercel.app/api/company/add-company`, {
        fieldValues,
      })
      .then((response) => {
        if (response?.data?.status == 20000) {
          Swal.fire({
            title: "Successfully Added",
            text: `${response?.data?.message}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#fe1626",
            // cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              setFieldValues({ ...initialState });
              setShowErrors(false);
              setErrors({});
            }
          });
        } else {
          Swal.fire(
            "Something went wrong",
            `${response?.data?.message}`,
            "error"
          );
        }
      })
      .catch((err) => {});
    setLoading(false);
  };
  const updateCompany = async () => {
    setLoading(true);

    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/company/update-company`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .put(`https://server-beige-delta.vercel.app/api/company/update-company`, {
        fieldValues,
      })
      .then((response) => {
        if (response?.data?.status == 40001) {
          Swal.fire({
            title: "Updated Successfully",
            text: `${response?.data?.message}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#fe1626",
            // cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // router.push("/dashboard");
              setFieldValues({ ...initialState });
              setShowErrors(false);
              setErrors({});
            }
          });
        } else {
          Swal.fire(
            "Something went wrong",
            `${response?.data?.message}`,
            "error"
          );
        }
      })
      .catch((err) => {});
    setLoading(false);
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      if (isEditMode) {
        updateCompany();
      } else {
        addCompanyApi();
      }
    } else {
      errorFormFieldsNotComplete(
        "Invalid Field",
        "Please enter required field"
      );
    }
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: value,
    });
  };
  const [toggleState, setToggleState] = useState(false);
  const handleToggleChange = () => {
    setToggleState(!toggleState);
    setFieldValues({
      ...fieldValues,
      status: !toggleState,
    });
  };
  return (
    <>
      <div className="max-w-3xl mx-auto shadow-lg mt-8 rounded-lg">
        {loading ? <Spinner /> : null}
        <div className={FORM_ASSIGN_HEADING_WRAPPER}>
          <h3 className="font-bold text-base">Create Company</h3>
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:px-5">
          <label htmlFor="name" className="font-bold text-base block mb-2">
            Company Name
          </label>
          <input
            type="text"
            value={fieldValues.company_name}
            onChange={handleFieldChange}
            placeholder="Company"
            name="company_name"
            className="py-2 px-4 w-full border border-#e4e6ef rounded-lg h-12 focus:outline-primary"
          />
          {showError && errors.company_name && (
            <small className="text-red-700	">{errors.company_name}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:px-5">
          <label htmlFor="name" className="font-bold text-base block mb-2">
            Company Code
          </label>
          <input
            type="text"
            value={fieldValues.company_code}
            onChange={handleFieldChange}
            placeholder="Company"
            name="company_code"
            className="py-2 px-4 w-full border border-#e4e6ef rounded-lg h-12 focus:outline-primary"
          />
          {showError && errors.company_code && (
            <small className="text-red-700	">{errors.company_code}</small>
          )}
        </div>
        <div className="py-8 px-8 border-b border-#ebedf3">
          <label htmlFor="name" className="font-bold text-base block mb-">
            Status
          </label>
          <Switch
            checked={toggleState}
            onChange={handleToggleChange}
            name="toggleSwitch"
            size="medium "
            className="scale-150 xs:scale-125"
          />
        </div>
        <div className="flex justify-end py-6 px-8 xs:p-4">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={handleSubmit}
          >
            {isEditMode ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </>
  );
};
export default CreateCompany;
