/** @format */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUser } from "./authAPI";
const initialState = {
  registerUser: [],
  loadingRegister: false,
  errorRegister: null,
};
export const addUserAsync = createAsyncThunk(
  "user/AddUser",
  async (userDetail) => {
    const response = await addUser(userDetail);
    return response.data;
  }
);
const addUserReducer = createSlice({
  name: "user/Add",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addUserAsync.pending, (state) => {
        state.loadingRegister = true;
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.loadingRegister = false;
        state.registerUser = action.payload;
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.loadingRegister = false;
        state.error = action.error;
      });
  },
});
export const selectLoggedInUser = (state) => state.auth.loggedInUser;
export const selectError = (state) => state.auth.error;
export default addUserReducer.reducer;
// export  {};
