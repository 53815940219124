/** @format */

import React, { useEffect, useState } from "react";
import {
  createRoleState,
  errorValidationForRoleCreateRoleState,
} from "./formState";

import Spinner from "../Spinner";
import { FormControlLabel, Switch } from "@mui/material";
import Axios from "axios";
import Swal from "sweetalert2";
import {
  BASE_URL_PORT_PRODUCTION,
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_SUBMIT_BTN,
} from "../generals/common";
import { errorFormFieldsNotComplete } from "../generals/swalMessages";
import { useParams } from "react-router-dom";
const CreateUserRole = ({ _id }) => {
  const [fieldValues, setFieldValues] = useState(createRoleState);
  const [errors, setErrors] = useState({});
  const [showError, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const { role_id } = useParams();
  
  const isEditMode = role_id != undefined ? true : false;
  
  const baseURL = BASE_URL_PORT_PRODUCTION;
  const regext = "^(?![0-9._])(?!.*[0-9._]$)(?!.*d_)(?!.*_d)[a-zA-Z0-9_]+$";
  useEffect(() => {
    setErrors(errorValidationForRoleCreateRoleState(fieldValues));
  }, [fieldValues]);
  useEffect(() => {
    if (isEditMode) {
      getRoleById();
    }
  }, []);
  const getRoleById = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/role/role-by-id`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .post(`https://server-beige-delta.vercel.app/api/role/role-by-id`, {
        _id: role_id,
      })
      .then((response) => {
        if (response?.data?.status == 20022) {
          const data = response?.data?.role;
          setFieldValues({ ...fieldValues, ...data });
          setToggleState(data?.status);
        } else {
          // Swal.fire(
          //   "Something went wrong",
          //   ${response?.data?.message},
          //   "error"
          // );
        }
      })
      .catch((err) => {});
    setLoading(false);
  };
  const addRoleApi = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/role/add-role`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .post(`https://server-beige-delta.vercel.app/api/role/add-role`, {
        fieldValues,
      })
      .then((response) => {
        if (response?.data?.status == 20000) {
          // Swal.fire(
          //   "Successfully Added",
          //   `${response?.data?.message}`,
          //   "success"
          // );
          // // router.back();
          Swal.fire({
            title: "Successfully Added",
            text: `${response?.data?.message}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#fe1626",
            // cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // router.push("/dashboard");
            }
          });
          setFieldValues({ ...initialState });
          setShowErrors(false);
        } else {
          // Swal.fire(
          //   "Something went wrong",
          //   ${response?.data?.message},
          //   "error"
          // );
        }
      })
      .catch((err) => {});
    setLoading(false);
  };
  const updateRoleApi = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/role/update-role`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .put(`https://server-beige-delta.vercel.app/api/role/update-role`, {
        fieldValues,
      })
      .then((response) => {
        if (response?.data?.status == 40001) {
          Swal.fire({
            title: "Updated Successfully",
            text: `${response?.data?.message}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#fe1626",
            // cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // router.push("/dashboard");
            }
          });
          setFieldValues({ ...initialState });
          setShowErrors(false);
          setErrors({});
        } else {
          Swal.fire(
            "Something went wrong",
            `${response?.data?.message}`,
            "error"
          );
        }
      })
      .catch((err) => {});
    setLoading(false);
  };
  const handleSubmit = () => {
    setShowErrors(true);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      if (isEditMode) {
        updateRoleApi();
      } else {
        addRoleApi();
      }
    } else {
      errorFormFieldsNotComplete(
        "Invalid Field",
        "Please enter required field"
      );
    }
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    //
    // var pattern = new RegExp(
    //   "^(?![0-9._])(?!.*[0-9._]$)(?!.*d_)(?!.*_d)[a-zA-Z0-9_]+$"
    // );
    //
    // // let text = "The best things in life are free";
    // // let pattern = /e/;
    // // let result = pattern.test(text);
    // if (pattern?.test(value)) {
    //   setFieldValues({
    //     ...fieldValues,
    //     [name]: value,
    //   });
    // }
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: value,
    });
  };
  const [toggleState, setToggleState] = useState(false);
  const handleToggleChange = () => {
    setToggleState(!toggleState);
    setFieldValues({
      ...fieldValues,
      status: !toggleState,
    });
  };
  return (
    <>
      <div className="max-w-3xl mx-auto shadow-lg mt-8 rounded-lg">
        {loading ? <Spinner /> : null}
        <div className={FORM_ASSIGN_HEADING_WRAPPER}>
          <h3 className="font-semibold text-base">Create Role</h3>
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:px-5">
          <label htmlFor="name" className="font-semibold text-base block mb-2">
            Role Name
          </label>
          <input
            type="text"
            value={fieldValues.role_name}
            onChange={handleFieldChange}
            placeholder="Role name"
            name="role_name"
            className="py-2 px-4 w-full border border-#e4e6ef rounded-lg h-12 focus:outline-primary"
          />
          {showError && errors.role_name && (
            <small className="text-red-700	">{errors.role_name}</small>
          )}
        </div>
        <div className="py-8 px-8 border-b border-#ebedf3">
          <label htmlFor="name" className="font-semibold text-base block mb-">
            Status
          </label>
          <Switch
            checked={toggleState}
            onChange={handleToggleChange}
            name="toggleSwitch"
            size="medium "
            className="scale-150 xs:scale-125"
          />
        </div>
        <div className="flex justify-end py-6 px-8 xs:p-4">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={handleSubmit}
          >
            {isEditMode ? "Update" : "Add"}
          </button>
        </div>
      </div>
    </>
  );
};
export default CreateUserRole;
