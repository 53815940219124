/** @format */

import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
// import { Plus_Jakarta_Sans } from "next/font/google";
export const BASE_URL_PORT_PRODUCTION = "https://eclipse-be.vercel.app/api/";
export const BASE_URL_PORT_LOCAL_5000 = "https://server-beige-delta.vercel.app/api/";
export const BASE_URL_PORT_LOCAL_5001 = "http://localhost:5001/api/";
export const BASE_URL_PORT_SERVER = "http://localhost:5000";

export const extractFullDate = (date) => {
  const dateConstructor = new Date(date);
  const dateExtracted =
    dateConstructor.getDate() +
    "-" +
    dateConstructor.getMonth() +
    "-" +
    dateConstructor.getFullYear();
  return dateExtracted;
};
export function convertToTitleCase(inputString) {
  return inputString
    .replace(/_/g, " ")
    .replace(/\b\w/g, (match) => match.toUpperCase());
}
// function textFileForSelectedData(arr) {
//   const jsonString = JSON.stringify(arr);
//   const blob = new Blob([jsonString], { type: "application/json" });
//   const downloadLink = document.createElement("a");
//   downloadLink.href = URL.createObjectURL(blob);
//   downloadLink.download = "data.txt";
// }
// function fileConverter() {
//   var formData = new FormData();
//   const jsonString = JSON.stringify(file);
//   const blob = new Blob([jsonString], { type: "application/json" });
//   // formData.append("file", blob, "data.xlsx");
//   let payload = {
//     file: JSON.stringify(formData.get("file")),
//   };
// }
// export const plus = Plus_Jakarta_Sans({
//   weight: ["300", "400", "500", "600", "700"],
//   subsets: ["latin"],
//   display: "swap",
//   fallback: ["Helvetica", "Arial", "sans-serif"],
// });
export const SWAL_CONFIRM_DESIGN = "focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800";
export const SWAL_CANCEL_DESIGN =
  "focus:outline-none text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900";
export const INPUT_FIELD_AUTH =
  "w-full py-2 px-4 border border-#e4e6ef rounded-lg h-14 focus:outline-red-500";
export const INPUT_WRAPPER =
  "w-11/12 xs:w-11/12 flex relative items-center justify-center  flex-col";
export const INPUT_FIELD_WRAPPER =
  "flex relative items-center justify-center w-full mb-4";
export const TABLE_DATA = "font-semibold bg-gray-5 border-b border-r p-2";
export const INPUT_SEARCH =
  "block w-full p-4 ps-10 text-sm text-secondary  outline-none border-gray-300 rounded-lg bg-white focus:ring-red-500 focus:border-red-500  border-2";
export const SEARCH_SUBMIT =
  "text-white absolute  end-2.5 bottom-2.5 bg-primary hover:bg-opacity-90 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 0";
export const UPLOAD_BTN =
  "btn bg-primary hover:bg-opacity-90 border-2 cursor-pointer border-primary flex items-center gap-4";
export const FORM_ASSIGN_INPUT_FIELD =
  "w-full py-2 px-4 border border-#e4e6ef rounded-lg h-12 focus:outline-primary";
export const FORM_ASSIGN_SUBMIT_BTN =
  "w-40 bg-primary hover:bg-opacity-90 h-14 text-white rounded-lg text-base font-semibold ";
export const FORM_ASSIGN_HEADING_WRAPPER = "py-6 px-8 border-b border-#ebedf3";
export const INPUT_TYPE_CHECKBOX =
  "w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
export const BUTTON_CARD =
  "block max-w-sm p-6 bg-slate-600 text-white border center border-gray-200 rounded-lg shadow hover:bg-gray-950 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700";
export const BUTTON_CARD_LG = "w-full bg-black";
export const customStylesForDatatable = {
  headCells: {
    style: {
      backgroundColor: "#EE1E25",
      fontSize: "16px",
      color: "#fff",
    },
  },
  cells: {
    style: {
      fontSize: "14px",
    },
  },
  rows: {
    style: {},
  },
};
export const BaseLightTheme = createTheme({
  direction: "ltr",
  palette: {
    primary: {
      main: "#EE1E25",
      // main: "#5D87FF",
      light: "#ECF2FF",
      dark: "#4570EA",
    },
    secondary: {
      main: "#fc7b7b",
      light: "#E8F7FF",
      dark: "#23afdb",
    },
    success: {
      main: "#13DEB9",
      light: "#E6FFFA",
      dark: "#02b3a9",
      contrastText: "#ffffff",
    },
    info: {
      main: "#539BFF",
      light: "#EBF3FE",
      dark: "#1682d4",
      contrastText: "#ffffff",
    },
    error: {
      main: "#FA896B",
      light: "#FDEDE8",
      dark: "#f3704d",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#FFAE1F",
      light: "#FEF5E5",
      dark: "#ae8e59",
      contrastText: "#ffffff",
    },
    grey: {
      100: "#F2F6FA",
      200: "#EAEFF4",
      300: "#DFE5EF",
      400: "#7C8FAC",
      500: "#5A6A85",
      600: "#2A3547",
    },
    text: {
      primary: "#2A3547",
      secondary: "#5A6A85",
    },
    action: {
      disabledBackground: "rgba(73,82,88,0.12)",
      hoverOpacity: 0.02,
      hover: "#f6f9fc",
    },
    divider: "#e5eaef",
  },
  typography: {
    // fontFamily: plus.style.fontFamily,
    h1: {
      fontWeight: 600,
      fontSize: "2.25rem",
      lineHeight: "2.75rem",
      // fontFamily: plus.style.fontFamily,
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.875rem",
      lineHeight: "2.25rem",
      // // fontFamily: plus.style.fontFamily,
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
      // // fontFamily: plus.style.fontFamily,
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.3125rem",
      lineHeight: "1.6rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.125rem",
      lineHeight: "1.6rem",
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "1.2rem",
    },
    button: {
      textTransform: "capitalize",
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.334rem",
    },
    body2: {
      fontSize: "0.75rem",
      letterSpacing: "0rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ".MuiPaper-elevation9, .MuiPopover-root .MuiPaper-elevation": {
          boxShadow:
            "rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
        },
      },
    },
  },
});
// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
});
export default theme;
