/** @format */
import React, { useEffect, useState } from "react";
import {
  FORM_ASSIGN_SUBMIT_BTN,
  UPLOAD_BTN,
  customStylesForDatatable,
  formatTimestampToDate,
} from "../generals/common";
import moment from "moment";
import Spinner from "../../component/Spinner";
import DataTable from "react-data-table-component";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import TransitionsModal from "../modal/TransitionsModal";
import { useNavigate } from "react-router-dom";
const JobList = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const getJob = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/job/get-job-list",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .get("https://server-beige-delta.vercel.app/api/job/get-job-list")
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 20025) {
          setJobList(response?.data?.job);
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    getJob();
  }, []);

  const handleView = (row) => {
    setshowModal(true);
    setRowData(row);
  };
  const searchParam = (data) => {
    return data?.filter((item) => {
      // Check if any field in the item contains the query
      return Object.values(item).some((field) => {
        if (field !== undefined && field !== null) {
          if (typeof field === "string" || typeof field === "number") {
            return field
              .toString()
              .toLowerCase()
              .includes(query?.toLowerCase());
          } else if (field instanceof Date) {
            return new moment(field).format("DD-MM-YYYY").includes(query);
          }
        }
        return false;
      });
    });
  };
  const handleRoute = (id) => {
    navigate(`/job/${id}`);
  };

  const column = [
    {
      name: "ID",
      selector: (row) => row._id,
    },
    {
      name: "Job Number",
      selector: (row) => row.job_no,
    },
    {
      name: "Created At",
      selector: (row) => formatTimestampToDate(row.updatedAt),
    },
    {
      name: "Status",
      selector: (row) => String(row.status),
    },
    {
      name: "Actions",
      selector: (row) => (
        <tr>
          <td>
            <FontAwesomeIcon
              className="fas fa-edit ms-2 mouseOverLink  fafaIconSize"
              title="Edit"
              onClick={() => handleRoute(row._id)}
              icon={faEdit}
            />
          </td>
          <td>
            <FontAwesomeIcon
              className="fas fa-trash ms-2 mouseOverLink  fafaIconSize"
              title="Delete"
              // onClick={() => handleDeleteReport(row)}
              icon={faTrash}
            />
          </td>
          <td>
            <FontAwesomeIcon
              className="fas fa-trash ms-2 mouseOverLink  fafaIconSize"
              title="View"
              onClick={() => handleView(row._id)}
              icon={faEye}
            />
          </td>{" "}
          {/* <td>
            <TransitionsModal data={row} title="" />
          </td> */}
        </tr>
      ),
      width: "10%",
    },
  ];

  return (
    <div className="max-w-6xl p-8 mx-auto shadow-lg mt-8 rounded-lg ">
      {loading ? <Spinner /> : null}
      {showModal ? (
        <TransitionsModal
          data={rowData}
          showModal={showModal}
          setshowModal={setshowModal}
          title=""
        />
      ) : null}
      <div className="flex gap-6 items-center mb-8 flex-wrap xs:gap-2 xs:justify-between ">
        <div className="text-secondary text-xl lg:text-2xl font-bold xs:text-lg">
          Job
        </div>
        <div className="ml-auto xs:w-full xs:max-w-full xs:m-0 w-80 md:w-65 lg:max-w-65 ">
          <div>
            <button
              onClick={() => navigate("/job/create-job")}
              className={FORM_ASSIGN_SUBMIT_BTN}
            >
              Create Job
            </button>
          </div>
          <form>
            <label
              for="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div>
              <div className="relative mt-3">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 ps-10 text-sm text-secondary  border-primary rounded-lg focus:outline-primary bg-white   focus:border-lightRed border-2 dark:placeholder-gray-40 "
                  placeholder="Search..."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="shadow-lg dataTable overflow-scroll ">
        <DataTable
          columns={column}
          data={jobList}
          //   data={"searchParam(excelData)"}
          customStyles={customStylesForDatatable}
          // data={searchParam(excelData)}
          selectableRows
          pagination
        />
      </div>
      <label
        for="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
    </div>
  );
};
export default JobList;
