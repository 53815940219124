/** @format */
import React, { useEffect, useState } from "react";
import {
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  FORM_ASSIGN_CANCEL_BTN,
  UL_FOR_RADIO,
  RADIO_DESIGN,
  FORM_MAIN_WRAPPER,
  FORM_INNER_PADDING,
  STEPPER_LABLE_WRAPPER,
  FORM_ASSIGN_SKIP_BTN,
} from "../generals/common";
import { qcFormRoughIn } from "./data";
import { QC_ROUGH_IN, validationOfQCroughIn } from "./formState";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import Spinner from "../Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { SWAL_CANCEL_DESIGN, SWAL_CONFIRM_DESIGN } from "../../generals/common";
const QCRoughIn = ({ onNext, onBack }) => {
  const [fieldValues, setFieldValues] = useState(QC_ROUGH_IN);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [formState, setFormState] = useState(false);
  const [underQCSlabData, setQCUnderSlabData] = useState([]);
  const [underFCSlabData, setFCUnderSlabData] = useState([]);
  const [FC_ROUGH_IN, setFC_ROUGH_IN] = useState([]);
  const [QC_TRIM, setQC_TRIM] = useState([]);
  const [FC_TRIM, setFC_TRIM] = useState([]);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const formCondition = JSON.parse(localStorage.getItem("formAlign"));
  const navigate = useNavigate();
  const fetchUserList = async () => {
    setLoading(true);
    fetch("https://server-beige-delta.vercel.app/api/user/get-user-list")
      .then((response) => response.json())
      .then((json) => setUser(json?.user));
    getJobByJobNo(formCondition.Job_id);
  };
  const getJobByJobNo = async (jobNo) => {
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/form-by-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/form-by-job", {
        jobNo,
      })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        let msg = response?.data?.message;
        if (response?.data?.status === 20022) {
          const data = response?.data?.foundForm?.form[0]?.QC_ROUGH_IN;
          const _id = response?.data?.foundForm?._id;
          const formState = response?.data?.foundForm != null;
          setFormState(formState);
          localStorage.setItem("formMDBId", _id);
          setQCUnderSlabData(response?.data?.foundForm?.form[0]?.QC_UNDER_SLAB);
          setFCUnderSlabData(response?.data?.foundForm?.form[0]?.FC_UNDER_SLAB);
          setFC_ROUGH_IN(response?.data?.foundForm?.form[0]?.FC_ROUGH_IN);
          setQC_TRIM(response?.data?.foundForm?.form[0]?.QC_TRIM);
          setFC_TRIM(response?.data?.foundForm?.form[0]?.FC_TRIM);
          setFieldValues({
            ...data,
          });
          if (data && Object.keys(data && data).length > 0) {
            setEditMode(true);
          }
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const addForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      QC_UNDER_SLAB: fieldValues,
    };
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/add-form",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/add-form", { form })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40002) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Successful",
              text: "Form has been Updated!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Want to Stay on Page?",
              cancelButtonText: "No!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setFieldValues({
                  ...initialState,
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate("/form");
              }
            });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    setErrors(validationOfQCroughIn(fieldValues));
  }, [fieldValues]);
  useEffect(() => {
    fetchUserList();
    // const data = JSON.parse(localStorage.getItem("QC_rough_in"));
    // if (isEditMode) {
    //   setFieldValues({
    //     ...fieldValues,
    //     ...data?.QC_rough_in,
    //   });
    // }
  }, []);
  const updateForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      QC_ROUGH_IN: fieldValues,
      QC_UNDER_SLAB: underQCSlabData,
      FC_UNDER_SLAB: underFCSlabData,
      FC_ROUGH_IN: FC_ROUGH_IN,
      QC_TRIM: QC_TRIM,
      FC_TRIM: FC_TRIM,
      _id: localStorage.getItem("formMDBId"),
    };
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/update-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .put("https://server-beige-delta.vercel.app/api/form/update-job", {
        form,
      })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40001) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Successful",
              text: "Form has been Updated!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Want to Stay on Page?",
              cancelButtonText: "No!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setFieldValues({
                  ...initialState,
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate("/form");
              }
            });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      if (formState) {
        updateForm();
      } else {
        addForm();
      }
      // setLoading(true);
      // setTimeout(() => {
      //   localStorage.setItem(
      //     "QC_rough_in",
      //     JSON.stringify({ QC_rough_in: fieldValues })
      //   );
      //   onNext();
      //   setLoading(false);
      // }, 500);
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const handleNext = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      if (fieldValues) {
        localStorage.setItem("FC_under_slab", JSON.stringify(fieldValues));
      }
      onNext();
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const handleBack = () => {
    onBack();
  };
  return (
    <div className={FORM_MAIN_WRAPPER}>
      {loading ? <Spinner /> : null}
      <div className="px-8 pb-4 text-center border-b border-#ebedf3">
        <h1 className="font-bold text-base mb-1">
          Quality Control Checklist - Rough In
        </h1>
        <p className="underline text-sm justify-center text-center items-center flex gap-1">
          <u>Note:-</u>
          <span className="flex gap-1">
            <b className="text-green-800">QC=Quality Control</b>,
            <b className="text-green-800">FC=Fixture Count</b>
          </span>
        </p>
      </div>
      <div className={FORM_INNER_PADDING}>
        <div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Date<span className="text-red-800">*</span>
            </label>
            <div>
              <input
                className={FORM_ASSIGN_INPUT_FIELD}
                type="date"
                name="QC_ROUGH_IN_DATE"
                value={fieldValues.QC_ROUGH_IN_DATE}
                onChange={handleFieldChange}
              />
            </div>
            {showError && errors.QC_ROUGH_IN_DATE && (
              <small className="text-red-700	">{errors.QC_ROUGH_IN_DATE}</small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              QC inspector<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="QC_ROUGH_IN_INSPECTOR"
                value={fieldValues.QC_ROUGH_IN_INSPECTOR}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {user.map((data, idx) => {
                  return (
                    <option key={idx} value={data._id}>
                      {data.reg_name}
                    </option>
                  );
                })}{" "}
              </select>
            </div>
            {showError && errors.QC_ROUGH_IN_INSPECTOR && (
              <small className="text-red-700	">
                {errors.QC_ROUGH_IN_INSPECTOR}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Whose being inspected<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="QC_ROUGH_IN_BEING_INSPECTED"
                value={fieldValues.QC_ROUGH_IN_BEING_INSPECTED}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {user.map((data, idx) => {
                  if (data._id != fieldValues.QC_ROUGH_IN_INSPECTOR)
                    return (
                      <option key={idx} value={data._id}>
                        {data.reg_name}
                      </option>
                    );
                })}{" "}
              </select>
            </div>
            {showError && errors.QC_ROUGH_IN_BEING_INSPECTED && (
              <small className="text-red-700	">
                {errors.QC_ROUGH_IN_BEING_INSPECTED}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">Sub Contractor</label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="QC_ROUGH_IN_SUB_CONTRACTOR"
                value={fieldValues.QC_ROUGH_IN_SUB_CONTRACTOR}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {user.map((data, idx) => {
                  return (
                    <option key={idx} value={data._id}>
                      {data.reg_name}
                    </option>
                  );
                })}{" "}
              </select>
            </div>
            {showError && errors.QC_ROUGH_IN_SUB_CONTRACTOR && (
              <small className="text-red-700	">
                {errors.QC_ROUGH_IN_SUB_CONTRACTOR}
              </small>
            )}
          </div>{" "}
          <div className="mt-8">
            <h2 className="font-bold mb-8 text-center text-base ">
              Confirm the following
            </h2>
            <div className="h-[500px] overflow-y-auto">
              {qcFormRoughIn?.map((data, idx) => {
                const { label, name } = data;
                return (
                  <div
                    className="flex gap-4 items-center justify-between"
                    key={idx}
                  >
                    <div className={STEPPER_LABLE_WRAPPER}>
                      <label className="text-ellipsis" title={label}>
                        {label}
                      </label>
                    </div>
                    <div className="">
                      <ul className={UL_FOR_RADIO}>
                        {[0, 1, 2].map((option) => (
                          <li className="w-full" key={option}>
                            <div className="flex items-center ps-3 xs:p-0">
                              <input
                                id={`${name}_${option}`}
                                className={RADIO_DESIGN}
                                type="radio"
                                name={`${name}`}
                                checked={
                                  parseInt(fieldValues[name], 10) === option
                                }
                                value={option}
                                onChange={handleFieldChange}
                              />
                              <label
                                htmlFor={`${name}_${option}`}
                                className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {option === 0
                                  ? "PASS"
                                  : option === 1
                                  ? "FAIL"
                                  : "N/A"}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-8">
            <h2 className="font-bold mb-8 text-center text-base ">
              ADDITIONAL NOTES
            </h2>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className={`${FORM_ASSIGN_INPUT_FIELD} h-auto`}
              onChange={handleFieldChange}
              name="QC_ROUGH_IN_ADDITIONAL_NOTE"
              value={fieldValues.QC_ROUGH_IN_ADDITIONAL_NOTE}
            ></textarea>
          </div>
        </div>
      </div>
      {/* <div className="px-8 flex justify-center gap-8">
        <div className="flex justify-end mt-6">
          <button
            type="button"
            className={FORM_ASSIGN_CANCEL_BTN}
            onClick={handleBack}
          >
            Back
          </button>
        </div>
        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={handleSubmit}
          >
            {isEditMode ? "Update" : "Submit"}
          </button>
        </div>
      </div> */}
      <div className="px-8 xs:px-5 flex justify-center mt-6 gap-6 xs:justify-between ">
        <div>
          <Tooltip title="This will take back">
            <button
              type="button"
              className={`bg-opacity-75 flex items-center justify-center gap-2 ${FORM_ASSIGN_CANCEL_BTN}`}
              onClick={handleBack}
            >
              <ArrowBackIcon style={{ marginTop: 2 }} /> Back
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="This will add/update record">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleSubmit}
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </Tooltip>
        </div>
        {/* <div>
          <Tooltip title="This will store data and jump to next form">
            <button onClick={handleNext} className={FORM_ASSIGN_CANCEL_BTN}>
              Next
            </button>
          </Tooltip>
        </div> */}
        <div>
          <Tooltip title="This Will Skip to next step with out storing current form">
            <button
              className={`bg-opacity-90 ${FORM_ASSIGN_SKIP_BTN}`}
              onClick={onNext}
            >
              Skip <ArrowForwardIcon />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default QCRoughIn;
