/** @format */
import React, { useState } from "react";
import { sampleForm } from "./formState";
const DynamicForm = () => {
  const [fieldValues, setFieldValues] = useState(sampleForm);
  const [formData, setFormData] = useState([]);
  const addRow = () => {
    const newField = {
      fieldName: `field${formData.length + 1}`,
      input1: "",
      input2: "",
    };
    setFormData([...formData, newField]);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  return (
    <div>
      <button onClick={addRow}>Add Row</button>
      <form>
        {formData.map((field, index) => (
          <div key={index}>
            <label>{field.fieldName}</label>
            <input
              type="text"
              placeholder="Input 1"
              //   value={field.input1}
              name={field + "extra"}
              onChange={handleInputChange()}
            />
            <input
              type="text"
              placeholder="Input 2"
              //   value={field.input2}
              onChange={handleInputChange()}
            />
          </div>
        ))}
      </form>
    </div>
  );
};
export default DynamicForm;
