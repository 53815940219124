/** @format */
import React, { useEffect, useState } from "react";
import { errorFormFieldsNotComplete } from "../../../src/components/generals/swalMessages";
import {
  FORM_ASSIGN_CANCEL_BTN,
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  INPUT_FIELD_AUTH,
} from "../generals/common";
import { addressFields, validationOfAddressFields } from "./formConfig";
import {
  cityListForDelaware,
  cityListForMaryland,
  cityListForPennsylvania,
  companyList,
  countryList,
  stateList,
  zipCodeListForDelaware,
} from "./data";
import Spinner from "../Spinner";
const Address = ({ onNext, onBack }) => {
  const [fieldValues, setFieldValues] = useState(addressFields);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [initialValue, setInitialValue] = useState({ ...fieldValues });
  const [loading, setLoading] = useState(false);
  const isEditMode = localStorage.getItem("addressField") ? true : false;
  useEffect(() => {
    setErrors(validationOfAddressFields(fieldValues));
  }, [fieldValues]);
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("addressField"));
    const jobField = JSON.parse(localStorage.getItem("jobField"));
    const companyName = jobField?.jobField?.company;
    setFieldValues({
      ...fieldValues,
      company: companyName,
    });
    if (isEditMode) {
      setFieldValues({
        ...fieldValues,
        ...data?.addressField,
      });
    }
  }, []);
  const formatPhoneNumber = (input) => {
    const numericValue = input.replace(/\D/g, "");
    const formattedValue = numericValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    return formattedValue;
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatPhoneNumber(inputValue);
    setFieldValues({
      ...fieldValues,
      contact_number: formattedValue,
    });
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      setTimeout(() => {
        localStorage.setItem(
          "addressField",
          JSON.stringify({ addressField: fieldValues })
        );
        onNext();
        setLoading(false);
      }, 500);
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const handleBack = () => {
    onBack();
  };
  return (
    <div className=" ">
      {loading ? <Spinner /> : null}
      <div className="mt-8 rounded-lg">
        <div className={FORM_ASSIGN_HEADING_WRAPPER}>
          <h2 className="font-extrabold  text-base ">Address</h2>
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:pt-4 xs:px-4 ">
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Address Code<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="address_code"
                value={fieldValues.address_code}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {companyList.map((data) => {
                  return <option value={data.code}>{data.code}</option>;
                })}
              </select>
            </div>
          </div>
          {showError && errors.address_code && (
            <small className="text-red-700	">{errors.address_code}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:px-5 ">
          <div>
            <label htmlFor="name" className=" text-base block mb-2">
              Address Line
            </label>
            <input
              type="text"
              className={INPUT_FIELD_AUTH}
              placeholder="Address Line"
              name="address_line"
              value={fieldValues.address_line}
              onChange={handleFieldChange}
            />
          </div>
          {showError && errors.address_line && (
            <small className="text-red-700	">{errors.address_line}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:px-5 ">
          <div>
            <label htmlFor="name" className=" text-base block mb-2">
              Contact Number<span className="text-red-800">*</span>
            </label>
            <input
              type="text"
              className={INPUT_FIELD_AUTH}
              placeholder="Contact Number"
              name="contact_number"
              value={fieldValues.contact_number}
              // placeholder="(555) 555-1234"
              maxLength={13}
              onChange={handleInputChange}
            />
          </div>
          {showError && errors.contact_number && (
            <small className="text-red-700	">{errors.contact_number}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2  xs:pt-4 xs:px-4 ">
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              State<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="state"
                value={fieldValues.state}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {stateList.map((data) => {
                  return <option value={data.code}>{data.label}</option>;
                })}
              </select>
            </div>
          </div>
          {showError && errors.state && (
            <small className="text-red-700	">{errors.state}</small>
          )}
        </div>
        {fieldValues.state != "" && fieldValues.state != 0 ? (
          <div className="pt-8 px-8 xs:py-2 xs:px-5 ">
            <div>
              <label htmlFor="name" className=" text-base block mb-2">
                City<span className="text-red-800">*</span>
              </label>
              <div>
                <select
                  className={FORM_ASSIGN_INPUT_FIELD}
                  name="city"
                  value={fieldValues.city}
                  onChange={handleFieldChange}
                >
                  <option value={0}>Please select</option>
                  {fieldValues.state == "0001"
                    ? cityListForDelaware.map((data) => {
                        return <option value={data.id}>{data.label}</option>;
                      })
                    : fieldValues.state == "0002"
                    ? cityListForMaryland.map((data) => {
                        return <option value={data.id}>{data.label}</option>;
                      })
                    : cityListForPennsylvania.map((data) => {
                        return <option value={data.id}>{data.label}</option>;
                      })}
                </select>
              </div>
            </div>
            {showError && errors.city && (
              <small className="text-red-700	">{errors.city}</small>
            )}
          </div>
        ) : null}
        {fieldValues.state != "" &&
        fieldValues.state != 0 &&
        fieldValues.city != "" &&
        fieldValues.city != 0 ? (
          <div className="pt-8 px-8 xs:py-2 xs:px-5 ">
            <div>
              <label htmlFor="name" className=" text-base block mb-2">
                Zip code<span className="text-red-800">*</span>
              </label>
              <div>
                <select
                  className={FORM_ASSIGN_INPUT_FIELD}
                  name="zip_code"
                  value={fieldValues.zip_code}
                  onChange={handleFieldChange}
                >
                  <option value={0}>Please select</option>
                  {fieldValues.state == "0001"
                    ? zipCodeListForDelaware.map((data) => {
                        return <option value={data.id}>{data.label}</option>;
                      })
                    : fieldValues.state == "0002"
                    ? zipCodeListForDelaware.map((data) => {
                        return <option value={data.id}>{data.label}</option>;
                      })
                    : zipCodeListForDelaware.map((data) => {
                        return <option value={data.id}>{data.label}</option>;
                      })}
                </select>
              </div>
            </div>
            {showError && errors.zip_code && (
              <small className="text-red-700	">{errors.zip_code}</small>
            )}
          </div>
        ) : null}
        <div className="px-8 xs:px-5 flex justify-center gap-8 xs:justify-between ">
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className={FORM_ASSIGN_CANCEL_BTN}
              onClick={handleBack}
            >
              Back
            </button>
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleSubmit}
            >
              {isEditMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Address;
