/** @format */
import React, { useEffect, useState } from "react";
import "./stepper.css";
import { TiTick } from "react-icons/ti";
import Job from "../Job/Job";
import Address from "../Job/Address";
import CostCode from "../Job/CostCode";
import ContractItem from "../Job/ContractItem";
import { ArrowBack } from "@mui/icons-material";
import { useSelector } from "react-redux";
import QCUnderSlab from "./QCUnderSlab";
import FCTrim from "./FCTrim";
import QcTrim from "./QCTrim";
import FCRough from "./FCRough";
import QCRoughIn from "./QCRoughIn";
import FCUnderslab from "./FCUnderslab";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import Axios from "axios";
import { formAlignState } from "./formState";
import QCRoughIn002 from "./QCRoughIn002";
import FCRough002 from "./FCRough002";
import QCTrim002 from "./QCTrim002";
import FCTrim002 from "./FCTrim002";
import QCTrim003 from "./QCTrim003";
import FCTrim003 from "./FCTrim003";
import QCRoughIn003 from "./QCRoughIn003";
const Stepper = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  // const formCondition? = JSON.parse(localStorage.getItem("formAlign"));
  const [formCondition?, setYourState] = useState(
    JSON.parse(localStorage.getItem("formAlign"))
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { formId } = useParams();
  const isEditMode = formId ? true : false;
  const getJobById = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/form-by-id",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/form-by-id", {
        _id: formId,
      })
      .then((response) => {
        console.log("response",response)
        let msg = response?.data?.message;
        if (response?.data?.status === 20022) {
          const FC_rough_in = response?.data?.foundForm?.form[0].FC_rough_in;
          const FC_trim = response?.data?.foundForm?.form[0].FC_trim;
          const FC_under_slab =
            response?.data?.foundForm?.form[0].FC_under_slab;
          const QC_rough_in = response?.data?.foundForm?.form[0].QC_rough_in;
          const QC_trim = response?.data?.foundForm?.form[0].QC_trim;
          const QC_under_slab =
            response?.data?.foundForm?.form[0].QC_under_slab;
          const _id = response?.data?.foundForm?._id;
          localStorage.setItem("FC_rough_in", JSON.stringify(FC_rough_in));
          localStorage.setItem("FC_trim", JSON.stringify(FC_trim));
          localStorage.setItem("FC_under_slab", JSON.stringify(FC_under_slab));
          localStorage.setItem("QC_rough_in", JSON.stringify(QC_rough_in));
          localStorage.setItem("QC_trim", JSON.stringify(QC_trim));
          localStorage.setItem("QC_under_slab", JSON.stringify(QC_under_slab));
          localStorage.setItem("_id", JSON.stringify(_id));
          // localStorage.setItem();
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    if (isEditMode) {
      getJobById();
    }
  }, []);
  const steps = [
    `QC Under Slab`,
    "FC Under Slab",
    "QC Rough",
    "FC Rough",
    "QC Trim",
    "FC Trim",
  ];
  const stepsFor002 = ["QC Rough-in", "FC Rough-in", "QC Trim"];
  const stepsFor003 = ["QC Rough-in", "QC Trim"];
  const jobStateSelector = useSelector((state) => state);
  const handleNext = () => {
    setCurrentStep((prev) => prev + 1);
  };
  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };
  if (formCondition == null) {
    return (
      <center>
        <h3>
          Please Come From FORM Align Page
          <span
            className="cursor-pointer text-red-700 underline"
            onClick={() => navigate("/form")}
          >
            Take me{" "}
          </span>
        </h3>
      </center>
    );
  }
  const renderStepComponentForCompany001 = () => {
    if (currentStep === 1) {
      if (!formCondition?.QC_under_slab) {
        return <QCUnderSlab onNext={handleNext} />;
      } else {
        steps[0] = "QC Under Slab(Not Required)";
        handleNext();
      }
    } else if (currentStep === 2) {
      if (!formCondition?.FC_under_slab) {
        return <FCUnderslab onNext={handleNext} onBack={handleBack} />;
      } else {
        handleNext();
      }
    } else if (currentStep === 3) {
      if (!formCondition?.QC_rough_in) {
        return <QCRoughIn onNext={handleNext} onBack={handleBack} />;
      } else {
        handleNext();
      }
    } else if (currentStep === 4) {
      if (!formCondition?.FC_rough_in) {
        return <FCRough onNext={handleNext} onBack={handleBack} />;
      } else {
        handleNext();
      }
    } else if (currentStep === 5) {
      if (!formCondition?.QC_trim) {
        return <QcTrim onNext={handleNext} onBack={handleBack} />;
      } else {
        handleNext();
      }
    } else {
      return <FCTrim onNext={handleNext} onBack={handleBack} />;
    }
  };
  const renderStepComponentForCompany002 = () => {
    if (currentStep === 1) {
      if (!formCondition?.QC_rough_002) {
        return <QCRoughIn002 onNext={handleNext} />;
      } else {
        steps[0] = "QC Under Slab(Not Required)";
        handleNext();
      }
    } else if (currentStep === 2) {
      if (!formCondition?.FC_rough_in_002) {
        return <FCRough002 onNext={handleNext} onBack={handleBack} />;
      } else {
        handleNext();
      }
    } else if (currentStep === 3) {
      if (!formCondition?.QC_check_trim_002) {
        return <QCTrim002 onNext={handleNext} onBack={handleBack} />;
      } else {
        handleNext();
      }
    }
  };
  const renderStepComponentForCompany003 = () => {
    if (currentStep === 1) {
      if (!formCondition?.QC_rough_in_003) {
        return <QCRoughIn003 onNext={handleNext} />;
      } else {
        handleNext();
      }
    } else if (currentStep === 2) {
      if (!formCondition?.QC_trim_003) {
        return <QCTrim003 onNext={handleNext} onBack={handleBack} />;
      } else {
        handleNext();
      }
    }
  };
  return (
    <>
      <div className="max-w-4xl mx-auto shadow-lg mt-8 py-10 rounded-lg overflow-x-auto">
        <div className="max-w-4xl mx-auto shadow-lg mt-8 py-10 rounded-lg overflow-x-auto">
          {formCondition?.company === "001" && (
            <>
              <div className="flex flex-nowrap whitespace-nowrap overflow-x-auto xs:gap-6 xs:px-4 md:justify-center align-middle">
                {steps?.map((step, i) => (
                  <div
                    key={i}
                    className={`step-item ${
                      currentStep === i + 1 && "active"
                    } ${(i + 1 < currentStep || complete) && "complete"} `}
                  >
                    <div className="step">
                      {i + 1 < currentStep || complete ? (
                        <TiTick size={24} />
                      ) : (
                        i + 1
                      )}
                    </div>
                    <p className="text-slate-700 font-semibold">{step}</p>
                  </div>
                ))}
              </div>
              {renderStepComponentForCompany001()}
            </>
          )}
          {formCondition?.company === "002" && (
            <>
              <div className="flex flex-nowrap whitespace-nowrap overflow-x-auto xs:gap-6 xs:px-4 md:justify-center align-middle">
                {stepsFor002?.map((step, i) => (
                  <div
                    key={i}
                    className={`step-item ${
                      currentStep === i + 1 && "active"
                    } ${(i + 1 < currentStep || complete) && "complete"} `}
                  >
                    <div className="step">
                      {i + 1 < currentStep || complete ? (
                        <TiTick size={24} />
                      ) : (
                        i + 1
                      )}
                    </div>
                    <p className="text-slate-700 font-semibold">{step}</p>
                  </div>
                ))}
              </div>
              {renderStepComponentForCompany002()}
            </>
          )}
          {formCondition?.company === "003" && (
            <>
              <div className="flex flex-nowrap whitespace-nowrap overflow-x-auto xs:gap-6 xs:px-4 md:justify-center align-middle">
                {stepsFor003?.map((step, i) => (
                  <div
                    key={i}
                    className={`step-item ${
                      currentStep === i + 1 && "active"
                    } ${(i + 1 < currentStep || complete) && "complete"} `}
                  >
                    <div className="step">
                      {i + 1 < currentStep || complete ? (
                        <TiTick size={24} />
                      ) : (
                        i + 1
                      )}
                    </div>
                    <p className="text-slate-700 font-semibold">{step}</p>
                  </div>
                ))}
              </div>
              {renderStepComponentForCompany003()}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Stepper;
