/** @format */
import React, { useState } from "react";
import bellIcon from "../../../src/assets/images/bellSVG.svg";
import personIcon from "../../../src/assets/images/person.svg";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const Header = () => {
  const navigate = useNavigate();
  const [settingOption, setSettingOption] = useState("1");
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setSettingOption(event.target.value);
  };
  const handleClearSession = () => {
    // dispatch(clearSession());
    navigate("/")
  };
  return (
    <header className="bg-white h-16 flex items-center justify-end px-6 above900:shadow-lg below900:shadow-none z-50 sticky top-0">
      <div className="flex gap-4 items-center">
        <div className="flex items-center justify-center gap-1 bg-primary relative bg-opacity-80 h-8 w-8 rounded-full">
          <span className="w-2 h-2 rounded-full bg-red-500 absolute -top-1 -right-1"></span>
          <img
            src={bellIcon}
            alt="Bell Icon"
            width={22}
            height={"auto"}
            className="icon-white"
          />
        </div>
        <div className="flex items-center gap-1 bg-primary rounded-md px-2 p-0.5 selectDropdown">
          <div className="flex items-center justify-center gap-1 bg-white bg-opacity-40 h-7 w-7 rounded-full absolute">
            <img
              src={personIcon}
              alt="Person Icon"
              width={16}
              height={"auto"}
              className="icon-white"
            />
          </div>
          <Select
            className="w-32 pl-6 dropdown-setting border-none outline-none font-bold text-base"
            value={settingOption}
            size="small"
            onChange={handleChange}
            style={{
              color: "#fff",
            }}
          >
            <MenuItem value="1">Admin</MenuItem>
            <MenuItem value="2">Profile</MenuItem>
            <MenuItem value="3">Setting</MenuItem>
            <MenuItem onClick={handleClearSession} value="4">
              Logout
            </MenuItem>
          </Select>
        </div>
      </div>
    </header>
  );
};
export default Header;
