/** @format */
import React, { useEffect } from "react";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import Auth from "./components/auth/Auth";
import Layout from "./layout/Layout";
import Dashboard from "./components/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import Protected from "./components/auth/Protected";
import { selectLoggedInUser } from "./components/auth/authSlice";
import Stepper from "./component/stepper/Stepper";
import CreateAdminRole from "./components/forms/CreateAdminRole";
import AssignRolesToAdmin from "./components/forms/AssignRolesToAdmin";
import ImportExcel from "./components/dashboard/components/ImportExcel";
import Form from "./components/FormCode/Form";
import QCRoughIn from "./components/FormCode/QCRoughIn";
import QCUnderSlab from "./components/FormCode/QCUnderSlab";
import FormStepper from "./components/FormCode/FormStepper";
import CreateFormField from "./components/FormCode/CreateFormField";
import Role from "./container/Role/Role";
import CreateRole from "./components/role/CreateRole";
import CreateDashboardUser from "./components/users/create-dashboard-user/CreateDashboardUser";
import AssignRolesToUsers from "./components/forms/AssignRolesToUsers";
import FetchJobs from "./components/fetch-jobs/FetchJobs";
import CombinedMaster from "./components/views/combined-master/CombinedMaster";
import FormAlign from "./components/FormCode/FormAlign";
import JobStepper from "./components/Job/JobStepper";
import JobList from "./components/Job/JobList";
import DynamicForm from "./components/FormCode/DynamicForm";
import CompanyList from "./components/forms/ComapnyList";
import CreateCompany from "./components/forms/CreateCompany";
import QCRoughIn002 from "./components/FormCode/QCRoughIn002";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Auth />,
    },
    {
      path: "/dashboard",
      element: <Layout Component={<Dashboard />} />,
    },
    {
      path: "/role/create-admin-role",
      element: <Layout Component={<CreateAdminRole />} />,
    },
    {
      path: "/role/role-list",
      element: <Layout Component={<Role />} />,
    },
    {
      path: "/role/create-user-role",
      element: <Layout Component={<CreateRole />} />,
    },
    {
      path: "/role/create-user-role/:role_id",
      element: <Layout Component={<CreateRole />} />,
    },
    {
      path: "/role/assign-role-to-admins",
      element: <Layout Component={<AssignRolesToAdmin />} />,
    },
    {
      path: "/role/assign-role-to-users",
      element: <Layout Component={<AssignRolesToUsers />} />,
    },
    {
      path: "/house-material/import-house-materials",
      element: <Layout Component={<ImportExcel />} />,
    },
    {
      path: "/job/view-job",
      element: <Layout Component={<JobList />} />,
    },
    {
      path: "/job/create-job",
      element: <Layout Component={<JobStepper />} />,
    },
    {
      path: "/job/:jobId",
      element: <Layout Component={<JobStepper />} />,
    },
    {
      path: "/form",
      element: <Layout Component={<Form />} />,
    },
    {
      path: "/form/create-form",
      element: <Layout Component={<CreateFormField />} />,
    },
    {
      path: "/form/form-align",
      element: <Layout Component={<FormAlign />} />,
    },
    {
      path: "/form/form-align/:formId",
      element: <Layout Component={<FormAlign />} />,
    },
    {
      path: "/form/QC-FC/:formId",
      element: <Layout Component={<FormStepper />} />,
    },
    {
      path: "/form/QC-FC",
      element: <Layout Component={<FormStepper />} />,
    },
    {
      path: "/ddd",
      element: <Layout Component={<DynamicForm />} />,
    },
    {
      path: "/form/QC-FC",
      element: <Layout Component={<FormStepper />} />,
    },
    {
      path: "/form/qc-under-slab-form",
      element: <Layout Component={<QCUnderSlab />} />,
    },
    {
      path: "/users/create-dashboard-user",
      element: <Layout Component={<CreateDashboardUser />} />,
    },
    {
      path: "/fetch-jobs",
      element: <Layout Component={<FetchJobs />} />,
    },
    {
      path: "/view/combined-master",
      element: <Layout Component={<CombinedMaster />} />,
    },
    {
      path: "/view/company-list",
      element: <Layout Component={<CompanyList />} />,
    },
    {
      path: "/view/create-company",
      element: <Layout Component={<CreateCompany />} />,
    },
    {
      path: "/view/create-company/:companyId",
      element: <Layout Component={<CreateCompany />} />,
    },
    {
      path: "/view/qcRoughInCode002",
      element: <Layout Component={<QCRoughIn002 />} />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
export default App;
