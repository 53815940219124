/** @format */
import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/header/Header.jsx";
import Sidebar from "../components/sidebar/Sidebar";
import Dashboard from "../components/dashboard/Dashboard.jsx";
import { ThemeProvider } from "@emotion/react";
import { BaseLightTheme } from "../components/generals/common.js";
import { CssBaseline } from "@mui/material";
import CreateAdminRole from "../components/forms/CreateAdminRole.jsx";
import RoleList from "../components/forms/RoleList.jsx";
import AssignRolesToUsers from "../components/forms/AssignRolesToAdmin.jsx";
import AssignRolesToAdmin from "../components/forms/AssignRolesToAdmin.jsx";
import ImportExcel from "../components/dashboard/components/ImportExcel.jsx";
import JobMaster from "../components/Job/JobMaster.js";
import Stepper from "../component/stepper/Stepper.jsx";
const Layout = ({ Component }) => {
  return (
    <div>
      <ThemeProvider theme={BaseLightTheme}>
        <CssBaseline />
        <section className="flex">
          <div className="hidden above900:block">
            <Sidebar />
          </div>
          <div className="w-full">
            <div className="below900:flex below900:justify-between below900:items-center below900:pl-8  z-1200a bg-white">
              <div className="hidden below900:block"><Sidebar /></div>
              <Header />
            </div>
            <div className="max-w-7xl p-6 xs:p-4 mx-auto">{Component}</div>
          </div>
        </section>
      </ThemeProvider>
    </div>
  );
};
export default Layout;
