import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import { useState } from "react";
import Aside from "./Aside";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
const Sidebar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="below900:block hidden">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerOpen}
          className="bg-primary text-white"
        >
          <MenuIcon className="text-white" />
        </IconButton>

        <Drawer
          className="below900:block hidden"
          anchor="left"
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <Aside />
        </Drawer>
      </div>
      <div className={` ${isOpen ? "w-24 aside" : "w-65"}`}>
        <div
          className={`above900:block hidden z-1000 transition-all  fixed top-0 ${
            isOpen ? "w-24 aside" : "w-65"
          }`}
        >
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="sidebarToggle absolute -right-12 top-3 bg-[#fc7b7b] z-50 w-10 h-10 rounded-full transition-all"
          >
            <KeyboardDoubleArrowLeftIcon
              style={{ color: "#fff" }}
              className={` ${isOpen ? "rotate-180 " : ""}`}
            />
          </button>
          <Aside isOpen={isOpen} />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
