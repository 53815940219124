/** @format */
export const formAlignState = {
  QC_under_slab: "",
  FC_under_slab: "",
  QC_rough_in: "",
  FC_rough_in: "",
  QC_trim: "",
  FC_trim: "",
  Job_id: "",
  company: "0",
  // all_select: "",
};
export const sampleForm = {};
export const QC_UNDER_SLAB_STATE = {
  QC_UNDER_SLAB_DATE: "",
  QC_UNDER_SLAB_QC_INSPECTOR: "",
  QC_UNDER_SLAB_BEING_INSPECTED: "",
  QC_UNDER_SLAB_SUBCONTRACTOR: "",
  QC_UNDER_SLAB_BEING_DRAIN_TEST: "",
  QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC: "",
  QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK: "",
  QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT: "",
  QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK: "",
  QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK: "",
  QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION: "",
};
export const FC_UNDER_SLAB_STATE = {
  FC_UNDER_SLAB_DATE: "",
  FC_UNDER_SLAB_QC_INSPECTOR: "",
  FC_UNDER_SLAB_BEING_INSPECTED: "",
};
export const QC_ROUGH_IN = {
  QC_ROUGH_IN_DATE: "",
  QC_ROUGH_IN_INSPECTOR: "",
  QC_ROUGH_IN_BEING_INSPECTED: "",
};
export const FC_ROUGH_IN = {
  FC_ROUGH_IN_DATE: "",
  FC_ROUGH_IN_INSPECTOR: "",
  FC_ROUGH_IN_BEING_INSPECTED: "",
};
export const QC_TRIM = {
  QC_TRIM_DATE: "",
  QC_TRIM_INSPECTOR: "",
  QC_TRIM_BEING_INSPECTED: "",
};
export const FC_TRIM = {
  FC_TRIM_DATE: "",
  FC_UNDER_SLAB_FILLED_OUT_BY: "",
  FC_UNDER_SLAB_BEING_INSPECTED: "",
};
export const validationOfQCUnderslab = (values) => {
  let errors = {};
  if (values.QC_UNDER_SLAB_DATE == "") {
    errors.QC_UNDER_SLAB_DATE = "This field is Required";
  }
  if (
    values.QC_UNDER_SLAB_QC_INSPECTOR == "" ||
    values.QC_UNDER_SLAB_QC_INSPECTOR == 0
  ) {
    errors.QC_UNDER_SLAB_QC_INSPECTOR = "This field is Required";
  }
  if (values.QC_UNDER_SLAB_BEING_INSPECTED == "") {
    errors.QC_UNDER_SLAB_BEING_INSPECTED = "This field is Required";
  }
  return errors;
};
export const validationOfFormAlign = (values) => {
  let errors = {};
  if (values.Job_id == "" || values.Job_id == 0) {
    errors.Job_id = "This field is Required";
  }
  if (values.company == "" || values.company == 0) {
    errors.company = "This field is Required";
  }
  return errors;
};
export const validationOfFCUnderslab = (values) => {
  let errors = {};
  if (values.FC_UNDER_SLAB_DATE == "") {
    errors.FC_UNDER_SLAB_DATE = "This field is Required";
  }
  if (
    values.FC_UNDER_SLAB_FILLED_OUT_BY == "" ||
    values.FC_UNDER_SLAB_FILLED_OUT_BY == "0"
  ) {
    errors.FC_UNDER_SLAB_FILLED_OUT_BY = "This field is Required";
  }
  if (
    values.FC_UNDER_SLAB_BEING_INSPECTED == "" ||
    values.FC_UNDER_SLAB_BEING_INSPECTED == "0"
  ) {
    errors.FC_UNDER_SLAB_BEING_INSPECTED = "This field is Required";
  }
  return errors;
};
export const validationOfQCroughIn = (values) => {
  let errors = {};
  if (values.QC_ROUGH_IN_DATE == "") {
    errors.QC_ROUGH_IN_DATE = "This field is Required";
  }
  if (
    values.QC_ROUGH_IN_INSPECTOR == "" ||
    values.QC_ROUGH_IN_INSPECTOR == "0"
  ) {
    errors.QC_ROUGH_IN_INSPECTOR = "This field is Required";
  }
  if (
    values.QC_ROUGH_IN_BEING_INSPECTED == "" ||
    values.QC_ROUGH_IN_BEING_INSPECTED == "0"
  ) {
    errors.QC_ROUGH_IN_BEING_INSPECTED = "This field is Required";
  }
  return errors;
};
export const validationOfFCroughIn = (values) => {
  let errors = {};
  if (values.FC_ROUGH_IN_DATE == "") {
    errors.FC_ROUGH_IN_DATE = "This field is Required";
  }
  if (values.FC_ROUGH_IN_BEING_INSPECTED == "") {
    errors.FC_ROUGH_IN_BEING_INSPECTED = "This field is Required";
  }
  return errors;
};
export const validationOfQCTrim = (values) => {
  let errors = {};
  if (values.QC_TRIM_DATE == "") {
    errors.QC_TRIM_DATE = "This field is Required";
  }
  if (values.QC_TRIM_INSPECTOR == "") {
    errors.QC_TRIM_INSPECTOR = "This field is Required";
  }
  if (values.QC_TRIM_BEING_INSPECTED == "") {
    errors.QC_TRIM_BEING_INSPECTED = "This field is Required";
  }
  return errors;
};
export const validationOfFCTrim = (values) => {
  let errors = {};
  if (values.FC_TRIM_DATE == "") {
    errors.FC_TRIM_DATE = "This field is Required";
  }
  if (values.FC_TRIM_FILLED_OUT_BY == "") {
    errors.FC_TRIM_FILLED_OUT_BY = "This field is Required";
  }
  if (values.FC_TRIM_BEING_INSPECTED == "") {
    errors.FC_TRIM_BEING_INSPECTED = "This field is Required";
  }
  return errors;
};
