/** @format */

import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Axios from "axios";
import Spinner from "../Spinner";
import {
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  PORT_PRODUCTION,
  customStylesForDatatable,
  extractDayAndDate,
  extractFullDate,
} from "../../generals/common";
import { Modal } from "@mui/material";
import TransitionsModal from "../modal/TransitionsModal";
// import { useRouter } from "next/navigation";
// import Link from "next/link";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
// import { getRoleList } from "@/redux/feature/role/roleSlice";
// import { useDispatch } from "react-redux";

const CompanyList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  // const router = useRouter();
  // const baseURL = PORT_PRODUCTION;
  const getCompanyList = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/company/get-company`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .get(`https://server-beige-delta.vercel.app/api/company/get-company`)
      .then((response) => {
        const data = response?.data?.companyList;
        if (response?.data?.status == 20022) {
          setData(data);
        } else {
          Swal.fire(
            "Something went wrong",
            `${response?.data?.message}`,
            "error"
          );
        }
      })
      .catch((err) => {});
    setLoading(false);
  };
  const callDeleteCompany = async (_id) => {
    const data = { _id };
    setLoading(true);

    const authAxios = Axios.create({
      baseURL: `https://server-beige-delta.vercel.app/api/company/delete-company`,
      headers: { "Content-Type": "application/json" },
    });
    await authAxios
      .delete(
        `https://server-beige-delta.vercel.app/api/company/delete-company`,
        {
          data,
        }
      )
      .then((response) => {
        const data = response?.data?.roles;
        if (response?.data?.status == 20022) {
          Swal.fire(
            "Successfully Deleted",
            `${response?.data?.message}`,
            "success"
          );
          getCompanyList();
        } else {
          Swal.fire(
            "Something went wrong",
            `${response?.data?.message}`,
            "error"
          );
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    setLoading(false);
  };
  useEffect(() => {
    getCompanyList();
    // dispatch(getRoleList());
  }, []);
  const columns = [
    {
      name: "ID",
      selector: (row) => row._id,
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
    },
    {
      name: "Company Code",
      selector: (row) => row.company_name,
    },
    {
      name: "Create At",
      selector: (row) => extractFullDate(row.updatedAt),
    },
    {
      name: "Actions",
      selector: (row) => (
        <tr>
          <td>
            {/* <Link href={`create-user-role/${row._id}`}> */}
            <FontAwesomeIcon
              className="fas fa-edit ms-2 mouseOverLink  fafaIconSize"
              title="Edit"
              onClick={() => handleRoute(row)}
              icon={faEdit}
            />
            {/* </Link> */}
          </td>
          <td>
            <FontAwesomeIcon
              className="fas fa-trash ms-2 mouseOverLink  fafaIconSize"
              title="Delete"
              onClick={() => handleDeleteCompany(row)}
              icon={faTrash}
            />
          </td>
          <td>
            <TransitionsModal data={row} title="" />
          </td>
        </tr>
      ),
      width: "10%",
    },
  ];
  const handleRoute = (row) => {
    const { _id } = row;
    navigate(`/view/create-company/${_id}`);
  };
  const handleDeleteCompany = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Delete Request For ${row.company_name}`,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#fe1626",
      customClass: {
        confirmButton: "bg-danger  me-md-3",
        cancelButton: " bg-secondary",
      },
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteCompany(row._id);
      }
    });
  };
  return (
    <div className="shadow-lg dataTable overflow-scroll mt-4 ">
      <div className="flex justify-between mb-6 items-center  pt-4 px-4">
        <h2 className="text-secondary text-2xl font-bold xs:text-lg  ">
          Company
        </h2>
        <button
          className={FORM_ASSIGN_SUBMIT_BTN}
          // onClick={() => router.push("/role/create-user-role")}
        >
          Add New
        </button>
      </div>
      {loading ? <Spinner /> : null}
      <DataTable
        className=""
        columns={columns}
        data={data}
        customStyles={customStylesForDatatable}
      />
    </div>
  );
};

export default CompanyList;
