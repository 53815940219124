/** @format */

import React from "react";
import RoleList from "../../components/role/RoleList";

const Role = () => {
  return (
    <div>
      <RoleList />
    </div>
  );
};

export default Role;
