import { DashboardSVG } from "../../SVG";

export const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
export const sideBar = [
  {
    id: 1,
    label: "Dashboard",
    icon: "",
    child: [
      {
        id: 1_1,
        label: "Reports Dashboard",
        icon: "",
        subChild: [],
      },
      {
        id: 1_2,
        label: "Reports Dashboard",
        icon: "",
        subChild: [],
      },
      {
        id: 1_3,
        label: "Dashboard",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 2,
    label: "Views",
    icon: "",
    child: [
      {
        id: 2_1,
        label: "Combined Master",
        icon: "",
        subChild: [],
      },
      {
        id: 2_2,
        label: "Invoice Summary",
        icon: "",
        subChild: [],
      },
      {
        id: 2_3,
        label: "Invoice Summary Detail",
        icon: "",
        subChild: [],
      },
      {
        id: 2_4,
        label: "Items Not On Master",
        icon: "",
        subChild: [],
      },
    ],
  },
  { id: 3, label: "Fetch Jobs", icon: "", child: [] },
  {
    id: 4,
    label: "Users",
    icon: "",
    child: [
      {
        id: 4_1,
        label: "Create Dashboard User",
        icon: "",
        subChild: [],
      },
      {
        id: 4_2,
        label: "Dashboard Users",
        icon: "",
        subChild: [],
      },
      {
        id: 4_3,
        label: "Mobile Users",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 5,
    label: "Company",
    icon: "",
    child: [
      {
        id: 5_1,
        label: "Create Company",
        icon: "",
        subChild: [],
      },
      {
        id: 5_2,
        label: "View Companies",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 6,
    label: "Jobs",
    icon: "",
    child: [
      {
        id: 6_1,
        label: "Create Job",
        icon: "",
        subChild: [],
      },
      {
        id: 6_2,
        label: "View Jobs",
        icon: "",
        subChild: [],
      },
      {
        id: 6_3,
        label: "Create Job Bid",
        icon: "",
        subChild: [],
      },
      {
        id: 6_5,
        label: "View Job Bid",
        icon: "",
        subChild: [],
      },
      {
        id: 6_6,
        label: "Bid OPtion",
        icon: "",
        subChild: [],
      },
    ],
  },
  { id: 7, label: "Custome Style", icon: "", child: [] },
  {
    id: 8,
    label: "Warranty",
    icon: "",
    child: [
      {
        id: 8_1,
        label: "Create Warranty",
        icon: "",
        subChild: [],
      },
      {
        id: 8_2,
        label: "View Warranties",
        icon: "",
        subChild: [],
      },
      {
        id: 8_3,
        label: "View Warranty Logs",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 9,
    label: "Job",
    icon: "",
    child: [
      {
        id: 9_1,
        label: "Job Entry",
        icon: "",
        subChild: [],
      },
      {
        id: 9_2,
        label: "Entry Options",
        icon: "",
        subChild: [],
      },
      {
        id: 9_3,
        label: "Import Budgets",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 10,
    label: "Failures",
    icon: "",
    child: [],
  },
  {
    id: 11,
    label: "Import Mappings",
    icon: "",
    child: [
      {
        id: 11_1,
        label: "Create Mapping",
        icon: "",
        subChild: [],
      },
      {
        id: 11_2,
        label: "View Mapping",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 12,
    label: "asad",
    icon: "",
    child: [
      {
        id: 12_1,
        label: "Create Form Type",
        icon: "",
        subChild: [],
      },
      {
        id: 12_2,
        label: "View Form Type",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 13,
    label: "Division Leader",
    icon: "",
    child: [
      {
        id: 13_1,
        label: "Create Division Leaders",
        icon: "",
        subChild: [],
      },
      {
        id: 13_2,
        label: "View Division Leaders",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 14,
    label: "Form Item",
    icon: "",
    child: [
      {
        id: 14_1,
        label: "Create Form Item",
        icon: "",
        subChild: [],
      },
      {
        id: 14_2,
        label: "View Form Item",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 15,
    label: "Fail Reason",
    icon: "",
    child: [
      {
        id: 15_1,
        label: "Create Fail Reason",
        icon: "",
        subChild: [],
      },
      {
        id: 15_2,
        label: "View Fail Reason",
        icon: "",
        subChild: [],
      },
    ],
  },
  { id: 16, lable: "Submitted Forms", icon: "", child: [] },
  { id: 17, lable: "Saved Forms", icon: "", child: [] },
  {
    id: 17,
    label: "Role",
    icon: "",
    child: [
      {
        id: 17_1,
        label: "Create Admin Role",
        icon: "",
        subChild: [],
      },
      {
        id: 17_3,
        label: "Create User Role",
        icon: "",
        subChild: [],
      },
      {
        id: 17_4,
        label: "View Role",
        icon: "",
        subChild: [],
      },
      {
        id: 17_5,
        label: "Assign Role To Admins",
        icon: "",
        subChild: [],
      },
      {
        id: 17_6,
        label: "Assign Role To Users",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 18,
    label: "House Material",
    icon: "",
    child: [
      {
        id: 18_1,
        label: "Import House Materials",
        icon: "",
        subChild: [],
      },
      {
        id: 18_2,
        label: "Create House Material",
        icon: "",
        subChild: [],
      },
      {
        id: 18_3,
        label: "View  House Materials",
        icon: "",
        subChild: [],
      },
      {
        id: 18_4,
        label: "View Pending House Materials",
        icon: "",
        subChild: [],
      },
      {
        id: 18_5,
        label: "View House Material Logs",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 19,
    label: "Suppliers",
    icon: "",
    child: [
      {
        id: 19_1,
        label: "Create Supplier",
        icon: "",
        subChild: [],
      },
      {
        id: 19_2,
        label: "View Supplier",
        icon: "",
        subChild: [],
      },
    ],
  },
  {
    id: 20,
    label: "SubContractors",
    icon: "",
    child: [
      {
        id: 20_1,
        label: "Create SubContractors",
        icon: "",
        subChild: [],
      },
      {
        id: 20_2,
        label: "View SubContractors",
        icon: "",
        subChild: [],
      },
    ],
  },
];

export const menu = [
  {
    id: 1,
    label: "Dashboard",
    href: "dashboard",
    icon: <DashboardSVG />,
    child: [
      {
        id: 1_1,
        labelChild: "Reports Dashboard",
        icon: "/assets/images/home.svg",
        subChild: [],
      },
      {
        id: 1_2,
        labelChild: "Reports Dashboard",
        icon: "/assets/images/home.svg",
        subChild: [],
      },
      {
        id: 1_3,
        labelChild: "Dashboard",
        icon: "/assets/images/home.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 2,
    label: "Views",
    icon: "/assets/images/folderSVG.svg",
    child: [
      {
        id: 2_1,
        labelChild: "Combined Master",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 2_2,
        labelChild: "Invoice Summary",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 2_3,
        labelChild: "Invoice Summary Detail",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 2_4,
        labelChild: "Items Not On Master",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 3,
    label: "Fetch Jobs",
    icon: "/assets/images/fetchJobsSVG.svg",
    child: [],
  },
  {
    id: 4,
    label: "Users",
    icon: "/assets/images/userSVG.svg",
    child: [
      {
        id: 4_1,
        labelChild: "Create Dashboard User",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 4_2,
        labelChild: "Dashboard Users",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 4_3,
        labelChild: "Mobile Users",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 5,
    label: "Company",
    icon: "/assets/images/companySVG.svg",
    child: [
      {
        id: 5_1,
        labelChild: "Create Company",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 5_2,
        labelChild: "View Companies",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 6,
    label: "Job",
    icon: "/assets/images/jobSVG.svg",
    child: [
      {
        id: 6_1,
        labelChild: "Create Job",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 6_2,
        labelChild: "View Jobs",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 6_3,
        labelChild: "Create Job Bid",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 6_5,
        labelChild: "View Job Bid",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 6_6,
        labelChild: "Bid OPtion",
        icon: "/assets/images/bidSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 7,
    label: "Custome Style",
    icon: "/assets/images/customStyleSVG.svg",
    child: [],
  },
  {
    id: 8,
    label: "Warranty",
    icon: "/assets/images/warrantySVG.svg",
    child: [
      {
        id: 8_1,
        labelChild: "Create Warranty",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 8_2,
        labelChild: "View Warranties",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 8_3,
        labelChild: "View Warranty Logs",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 9,
    label: "Job Entry",
    icon: "/assets/images/warrantySVG.svg",
    child: [
      {
        id: 9_1,
        labelChild: "Job Entry",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 9_2,
        labelChild: "Entry Options",
        icon: "/assets/images/bidSVG.svg",
        subChild: [],
      },
      {
        id: 9_3,
        labelChild: "Import Budgets",
        icon: "/assets/images/importSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 10,
    label: "Failures",
    icon: "/assets/images/settingSVG.svg",
    child: [],
  },
  {
    id: 11,
    label: "Import Mappings",
    icon: "/assets/images/mappingSVG.svg",
    child: [
      {
        id: 11_1,
        labelChild: "Create Mapping",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 11_2,
        labelChild: "View Mapping",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 12,
    label: "Form Type",
    icon: "/assets/images/warrantySVG.svg",
    child: [
      {
        id: 12_1,
        labelChild: "Create Form Type",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 12_2,
        labelChild: "View Form Type",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 13,
    label: "Division Leader",
    icon: "/assets/images/userSVG.svg",
    child: [
      {
        id: 13_1,
        labelChild: "Create Division Leaders",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 13_2,
        labelChild: "View Division Leaders",
        icon: "/assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 14,
    label: "Form Item",
    icon: "/assets/images/formItemSVG.svg",
    child: [
      {
        id: 14_1,
        labelChild: "Create Form Item",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 14_2,
        labelChild: "View Form Item",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 15,
    label: "Fail Reason",
    icon: "/assets/images/crossSVG.svg",
    child: [
      {
        id: 15_1,
        labelChild: "Create Fail Reason",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 15_2,
        labelChild: "View Fail Reason",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 16,
    label: "Submitted Forms",
    icon: "/assets/images/submittedSVG.svg",
    child: [],
  },
  {
    id: 17,
    label: "Saved Forms",
    icon: "/assets/images/submittedSVG.svg",
    child: [],
  },
  {
    id: 18,
    label: "Role",
    icon: "/assets/images/lockSVG.svg",
    child: [
      {
        id: 17_1,
        labelChild: "Create Admin Role",
        icon: "/assets/images/plusSVG.svg",
        href: "/forms/assign-role",
        subChild: [],
      },
      {
        id: 17_3,
        labelChild: "Create User Role",
        href: "/forms/add-role",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 17_4,
        labelChild: "View Role",
        href: "/forms/view-roles",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 17_5,
        labelChild: "Assign Role To Admins",
        href: "/forms/admin",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 17_6,
        labelChild: "Assign Role To Users",
        href: "/forms/user",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 19,
    label: "House Material",
    icon: "/assets/images/lockSVG.svg",
    child: [
      {
        id: 19_1,
        labelChild: "Import House Materials",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 19_2,
        labelChild: "Create House Material",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 19_3,
        labelChild: "View  House Materials",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 19_4,
        labelChild: "View Pending House Materials",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
      {
        id: 19_5,
        labelChild: "View House Material Logs",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 20,
    label: "Suppliers",
    icon: "assets/images/userSVG.svg",
    child: [
      {
        id: 20_1,
        labelChild: "Create Supplier",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 20_2,
        labelChild: "View Supplier",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 21,
    label: "SubContractors",
    icon: "assets/images/userSVG.svg",
    child: [
      {
        id: 21_1,
        labelChild: "Create SubContractors",
        icon: "/assets/images/plusSVG.svg",
        subChild: [],
      },
      {
        id: 21_2,
        labelChild: "View SubContractors",
        icon: "assets/images/ViewChildSVG.svg",
        subChild: [],
      },
    ],
  },
  {
    id: 22,
    label: "Settings",
    icon: "/assets/images/settingSVG.svg",
    child: [],
  },
];
