/** @format */

"use client";
import { configureStore } from "@reduxjs/toolkit";
import roleSlice from "./feature/role/roleSlice";
import checkUserReducer from "../components/auth/authSlice";
import jobMasterReducer from "../components/Job/jobSlice";
import addUserReducer from "../components/auth/registerUserSlice";
export const store = configureStore({
  reducer: {
    role: roleSlice,
    user: checkUserReducer,
    jobMaster: jobMasterReducer,
    registerUser: addUserReducer,
  },
});
