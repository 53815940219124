/** @format */

import React from "react";
import SalesOverview from "./components/sales-overview/SalesOverview";
import YearlyBreakup from "./components/yearly-earnings/YearlyBreakup";
import MonthlyEarnings from "./components/monthly-earnings/MonthlyEarnings";
import RecentTransactions from "./components/recent-transactions/RecentTransactions";
import ProductPerformance from "./components/ProductPerformance/ProductPerformance";
import Blog from "./components/blog/Blog";
import { useSelector } from "react-redux";
const Dashboard = () => {
  const state = useSelector((state) => state.user.loggedInUser);
  return (
    <div className="flex gap-6 flex-col xs:flex-wrap wrapperDash ">
      <div className="flex gap-6 xs:flex-wrap md:flex-col xl:flex-row xs:w-full ">
        <div className="xl:w-2/3 shadow-lg xs:w-full md:w-full">
          <SalesOverview />
        </div>
        <div className="xl:w-1/3 flex xs:flex-col  xl:flex-col gap-6 xs:w-full  md:w-full md:flex-row">
          <div className="shadow-lg md:w-1/2 xl:w-full">
            <YearlyBreakup />
          </div>
          <div className="shadow-lg md:w-1/2 xl:w-full">
            <MonthlyEarnings />
          </div>
        </div>
      </div>
      <div className="flex gap-6 xs:flex-wrap xs:w-full">
        <div className="md:w-2/4 xl:w-1/3 flex flex-col gap-6 xs:w-full">
          <div className="shadow-lg">
            <RecentTransactions />
          </div>
        </div>
        <div className="md:w-2/4 xl:w-2/3 shadow-lg xs:w-full overflow-auto">
          <ProductPerformance />
        </div>
      </div>
      <div>
        <Blog />
      </div>
    </div>
  );
};

export default Dashboard;
