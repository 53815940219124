// use-client.js
let url;
if (typeof window !== "undefined") {
  // This code will only be executed on the client side
  const protocol = window.location.href.split(":")[0];
  if (protocol === "https") {
    // url = "https://databaselogin.herokuapp.com"
    url = "https://eclipse-be.vercel.app";
  } else {
    url = "http://localhost:5000";
  }
}
export default url;
