/** @format */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { BASE_URL_PORT_LOCAL_5000 } from "@/generals/common";
const initialState = { data: [], err: false, loading: false };
const roleSlice = createSlice({
  name: "roleReducer",
  initialState,
  reducers: {
    add(state, action) {
      state.push(action.payload);
    },
    getRoles(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRoleList.pending, (state, action) => {
      state.status = true;
    });
  },
});
export const { add, getRoles } = roleSlice.actions;
export default roleSlice.reducer;
export const getRoleList = createAsyncThunk("role/list", async () => {
  const data = await fetch(
    "https://server-beige-delta.vercel.app/api/role/get-role"
  );
  const result = await data.json();
  return result;
});
