/** @format */

import React from "react";
import { FORM_ASSIGN_INPUT_FIELD, FORM_ASSIGN_SUBMIT_BTN, FORM_INNER_PADDING, FORM_MAIN_WRAPPER } from "../generals/common";

const CreateFormField = () => {
  return (
    <div>
      <div className={FORM_MAIN_WRAPPER}>
        <div className="px-8 pb-4 text-center border-b border-#ebedf3">
          <h1 className="font-bold text-lg mb-1">Create Form for QC</h1>
        </div>
        <div className={FORM_INNER_PADDING}>
          <div>
            <div className="flex flex-col my-4 ">
              <label className="mb-2">Label</label>
              <div>
                <input
                  className={FORM_ASSIGN_INPUT_FIELD}
                  type="text"
                  placeholder="Name of field "
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col my-4 ">
              <label className="mb-2">Name</label>
              <div>
                <input
                  className={FORM_ASSIGN_INPUT_FIELD}
                  type="text"
                  placeholder="With this label it will save the data "
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col my-4 ">
              <label className="mb-2">Value</label>
              <div>
                <input
                  className={FORM_ASSIGN_INPUT_FIELD}
                  type="text"
                  placeholder="With this label it will save the data "
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col my-4 ">
              <label className="mb-2">Placeholder</label>
              <div>
                <input
                  className={FORM_ASSIGN_INPUT_FIELD}
                  type="text"
                  placeholder="Will Show in control"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col my-4 ">
              <label className="mb-2">Type of Control</label>
              <div>
                <select className={FORM_ASSIGN_INPUT_FIELD}>
                  <option value={0}>Please select</option>
                  <option>Text</option>
                  <option>Option Type</option>
                  <option>Radio Button</option>
                  <option>Checkbox</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col my-4 ">
              <label className="mb-2">Label</label>
              <div>
                <input
                  className={FORM_ASSIGN_INPUT_FIELD}
                  type="text"
                  placeholder="Name of field "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end py-6 px-8 xs:p-4">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            // onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFormField;
