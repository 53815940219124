/** @format */
import React, { useEffect, useState } from "react";
import { errorFormFieldsNotComplete } from "../../../src/components/generals/swalMessages";
import {
  FORM_ASSIGN_CANCEL_BTN,
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  INPUT_FIELD_AUTH,
} from "../generals/common";
import { jobFields, validationOfJobFields } from "./formConfig";
import Spinner from "../Spinner";
import { useDispatch } from "react-redux";
import { createJobAsync, selectLoading } from "./jobSlice";
import { WCB_code, companyList, jobType, zoneList } from "./data";
const Job = ({ onNext, isEditMode }) => {
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem("jobField")) || {}
  );
  const [fieldValues, setFieldValues] = useState(jobFields);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [initialValue, setInitialValue] = useState({ ...fieldValues });
  // const loading = false;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const isEditMode = localStorage.getItem("jobField") ? true : false;
  useEffect(() => {
    setErrors(validationOfJobFields(fieldValues));
  }, [fieldValues]);
  useEffect(() => {
    setFieldValues({
      ...fieldValues,
      WCB: fieldValues.company,
    });
  }, [fieldValues]);
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    if (name == "job_no") {
      if (!isNaN(value)) {
        setFieldValues({
          ...fieldValues,
          [name]: value,
        });
      }
    } else {
      setFieldValues({
        ...fieldValues,
        [name]: value,
      });
    }
  };
  const handleFieldChangeDropdown = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("jobField"));
    if (storedData) {
      setData(storedData);
    }
  }, [fieldValues]);
  useEffect(() => {
    // const data = JSON.parse(localStorage.getItem("jobField"));
    // console.log("🚀 ~ file: Job.js:38 ~ useEffect ~ data:", data);
    if (isEditMode) {
      setFieldValues({
        ...fieldValues,
        ...data?.jobField,
      });
    }
  }, [data]);
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      // if (isEditMode) {
      //   updateMenuApi();
      // } else {
      // dispatch(createJobAsync(fieldValues));
      // }
      setLoading(true);
      setTimeout(() => {
        localStorage.setItem(
          "jobField",
          JSON.stringify({ jobField: fieldValues })
        );
        onNext();
        setLoading(false);
      }, 500);
      // setLoading(false);
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  // const handleSubmit = () => {
  //   onNext();
  // };
  return (
    <div>
      {loading ? <Spinner /> : null}
      <div className=" mt-8 rounded-lg">
        <div className={FORM_ASSIGN_HEADING_WRAPPER}>
          <h2 className="font-extrabold  text-base ">Create Job</h2>
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:pt-4 xs:px-4 ">
          <div>
            <label htmlFor="name" className=" text-base block mb-2">
              Job No.<span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              className={INPUT_FIELD_AUTH}
              placeholder="Job No"
              name="job_no"
              value={fieldValues.job_no}
              // disabled={
              //   isEditMode && localStorage.getItem("enableJob") ? true : false
              // }
              maxLength={9}
              onChange={handleFieldChange}
            />
          </div>
          {showError && errors.job_no && (
            <small className="text-red-700	">{errors.job_no}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:pt-4 xs:px-4 ">
          <div>
            <label htmlFor="name" className=" text-base block mb-2">
              Job Name<span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              className={INPUT_FIELD_AUTH}
              placeholder="Job Name"
              name="job_name"
              value={fieldValues.job_name}
              onChange={handleFieldChange}
              maxLength={2}
            />
          </div>
          {showError && errors.job_name && (
            <small className="text-red-700	">{errors.job_name}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2  xs:pt-4 xs:px-4 ">
          <div className="flex flex-col">
            <label className="mb-2">
              Company<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="company"
                value={fieldValues.company}
                onChange={handleFieldChangeDropdown}
              >
                <option value={0}>Please select</option>
                {companyList.map((data, idx) => {
                  return (
                    <option value={data.code} key={idx}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {showError && errors.company && (
            <small className="text-red-700	">{errors.company}</small>
          )}
        </div>
        {/* <div className="pt-8 px-8 xs:py-2  xs:pt-4 xs:px-4 ">
          <div className="flex flex-col">
            <label className="mb-2">
              WCB<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="WCB"
                value={fieldValues.WCB}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {WCB_code.map((data) => {
                  return <option value={data.code}>{data.label}</option>;
                })}
              </select>
            </div>
          </div>
          {showError && errors.WCB && (
            <small className="text-red-700	">{errors.WCB}</small>
          )}
        </div> */}
        <div className="pt-8 px-8 xs:py-2 xs:pt-4 xs:px-4 ">
          <div className="flex flex-col">
            <label className="mb-2">
              ZONE<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="zone"
                value={fieldValues.zone}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {zoneList.map((data) => {
                  return <option value={data.id}>{data.label}</option>;
                })}
              </select>
            </div>
          </div>
          {showError && errors.zone && (
            <small className="text-red-700	">{errors.zone}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2  xs:pt-4 xs:px-4 ">
          <div>
            <label htmlFor="name" className=" text-base block mb-2">
              Start Date<span className="text-red-400">*</span>
            </label>
            <input
              type="date"
              className={INPUT_FIELD_AUTH}
              placeholder="start date"
              name="start_date"
              value={fieldValues.start_date}
              onChange={handleFieldChange}
            />
          </div>
          {showError && errors.start_date && (
            <small className="text-red-700	">{errors.start_date}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:pt-4 xs:px-4 ">
          <div>
            <label htmlFor="name" className=" text-base block mb-2">
              End Date<span className="text-red-400">*</span>
            </label>
            <input
              type="date"
              className={INPUT_FIELD_AUTH}
              placeholder="End Date"
              name="end_date"
              value={fieldValues.end_date}
              onChange={handleFieldChange}
              min={fieldValues.start_date}
            />
          </div>
          {showError && errors.end_date && (
            <small className="text-red-700	">{errors.end_date}</small>
          )}
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:pt-4 xs:px-4 ">
          <div className="flex flex-col">
            <label className="mb-2">
              Job Type<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="job_type"
                value={fieldValues.job_type}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {jobType.map((data) => {
                  return <option value={data.id}>{data.label}</option>;
                })}
              </select>
            </div>
          </div>
          {showError && errors.job_type && (
            <small className="text-red-700	">{errors.job_type}</small>
          )}
        </div>
        {/* <div className="pt-8 px-8 xs:py-2 xs:pt-4 xs:px-4 ">
          <div>
            <label htmlFor="name" className=" text-base block mb-2">
              location<span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              className={INPUT_FIELD_AUTH}
              placeholder="location"
              name="location"
              value={fieldValues.location}
              onChange={handleFieldChange}
            />
          </div>
          {showError && errors.location && (
            <small className="text-red-700	">{errors.location}</small>
          )}
        </div> */}
        <div className="px-8 xs:px-5 flex justify-center gap-8 xs:justify-between ">
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleSubmit}
            >
              {isEditMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Job;
