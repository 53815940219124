/** @format */
import userSVG from "../../src/assets/images/userSVG.svg";
import homeSVG from "../../src/assets/images/home.svg";
import folderSVG from "../../src/assets/images/folderSVG.svg";
import fetchJobsSVG from "../../src/assets/images/fetchJobsSVG.svg";
import plusSVG from "../../src/assets/images/plusSVG.svg";
import companySVG from "../../src/assets/images/companySVG.svg";
import jobSVG from "../../src/assets/images/jobSVG.svg";
import customStyleSVG from "../../src/assets/images/customStyleSVG.svg";
import warrantySVG from "../../src/assets/images/warrantySVG.svg";
import settingSVG from "../../src/assets/images/settingSVG.svg";
import mappingSVG from "../../src/assets/images/mappingSVG.svg";
import formItemSVG from "../../src/assets/images/formItemSVG.svg";
import crossSVG from "../../src/assets/images/crossSVG.svg";
import submittedSVG from "../../src/assets/images/submittedSVG.svg";
import lockSVG from "../../src/assets/images/lockSVG.svg";
import importSVG from "../../src/assets/images/importSVG.svg";
import ViewChildSVG from "../../src/assets/images/ViewChildSVG.svg";
import bidSVG from "../../src/assets/images/bidSVG.svg";
export const navMenu = [
  {
    id: 1,
    label: "Dashboard",
    icon: userSVG,
    child: [
      {
        id: 1_1,
        labelChild: "Reports Dashboard",
        icon: homeSVG,
        subChild: [],
      },
      {
        id: 1_2,
        labelChild: "Reports Dashboard",
        icon: homeSVG,
        subChild: [],
      },
      {
        id: 1_3,
        labelChild: "Dashboard",
        href: "/dashboard",
        icon: homeSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 2,
    label: "View",
    icon: folderSVG,
    child: [
      {
        id: 2_1,
        labelChild: "Combined Master",
        href: "/view/combined-master",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 2_2,
        labelChild: "Invoice Summary",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 2_3,
        labelChild: "Invoice Summary Detail",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 2_4,
        labelChild: "Items Not On Master",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 3,
    label: "Fetch Jobs",
    href: "/fetch-jobs",
    icon: fetchJobsSVG,
    child: [],
  },
  {
    id: 4,
    label: "Users",
    icon: userSVG,
    child: [
      {
        id: 4_1,
        href: "/users/create-dashboard-user",
        labelChild: "Create Dashboard User",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 4_2,
        labelChild: "Dashboard Users",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 4_3,
        labelChild: "Mobile Users",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 5,
    label: "Company",
    icon: companySVG,
    child: [
      {
        id: 5_1,
        labelChild: "Create Company",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 5_2,
        labelChild: "View Companies",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 6,
    label: "Job",
    icon: jobSVG,
    child: [
      {
        id: 6_1,
        labelChild: "Create Job",
        icon: plusSVG,
        href: "/job/create-job",
        subChild: [],
      },
      {
        id: 6_2,
        labelChild: "View Job",
        icon: ViewChildSVG,
        subChild: [],
        href: "/job/view-job",
      },
      {
        id: 6_3,
        labelChild: "Create Job Bid",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 6_5,
        labelChild: "View Job Bid",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 6_6,
        labelChild: "Bid Option",
        icon: bidSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 7,
    label: "Custom Style",
    icon: customStyleSVG,
    child: [],
  },
  {
    id: 8,
    label: "Warranty",
    icon: warrantySVG,
    child: [
      {
        id: 8_1,
        labelChild: "Create Warranty",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 8_2,
        labelChild: "View Warranties",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 8_3,
        labelChild: "View Warranty Logs",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 9,
    label: "Job Entry",
    icon: warrantySVG,
    child: [
      {
        id: 9_1,
        labelChild: "Job Entry",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 9_2,
        labelChild: "Entry Options",
        icon: bidSVG,
        subChild: [],
      },
      {
        id: 9_3,
        labelChild: "Import Budgets",
        icon: importSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 10,
    label: "Failures",
    icon: settingSVG,
    child: [],
  },
  {
    id: 11,
    label: "Import Mappings",
    icon: mappingSVG,
    child: [
      {
        id: 11_1,
        labelChild: "Create Mapping",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 11_2,
        labelChild: "View Mapping",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 12,
    label: "Form Type",
    icon: warrantySVG,
    child: [
      {
        id: 12_1,
        labelChild: "Create Form Type",
        icon: plusSVG,
        href: "/form/form-align",
        subChild: [],
      },
      {
        id: 12_2,
        labelChild: "View Form Type",
        icon: ViewChildSVG,
        href: "/form",
        subChild: [],
      },
    ],
  },
  {
    id: 13,
    label: "Division Leader",
    icon: userSVG,
    child: [
      {
        id: 13_1,
        labelChild: "Create Division Leaders",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 13_2,
        labelChild: "View Division Leaders",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  // {
  //   id: 14,
  //   label: "Form Item",
  //   icon: formItemSVG,
  //   child: [
  //     {
  //       id: 14_1,
  //       labelChild: "Create Form Item",
  //       icon: plusSVG,
  //       subChild: [],
  //     },
  //     {
  //       id: 14_2,
  //       labelChild: "View Form Item",
  //       icon: ViewChildSVG,
  //       subChild: [],
  //     },
  //   ],
  // },
  {
    id: 15,
    label: "Fail Reason",
    icon: crossSVG,
    child: [
      {
        id: 15_1,
        labelChild: "Create Fail Reason",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 15_2,
        labelChild: "View Fail Reason",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 16,
    label: "Submitted Forms",
    icon: submittedSVG,
    child: [],
  },
  {
    id: 17,
    label: "Saved Forms",
    icon: submittedSVG,
    child: [],
  },
  {
    id: 18,
    label: "Role",
    icon: lockSVG,
    child: [
      {
        id: 17_1,
        labelChild: "Create Admin Role",
        icon: plusSVG,
        href: "/role/create-admin-role",
        subChild: [],
      },
      {
        id: 17_3,
        labelChild: "Create User Role",
        href: "/role/role-list",
        icon: plusSVG,
        subChild: [],
      },
      // Uncomment the following section if needed
      // {
      //   id: 17_4,
      //   labelChild: "View Role",
      //   href: "/role/view-role",
      //   icon: ViewChildSVG,
      //   subChild: [],
      // },
      {
        id: 17_5,
        labelChild: "Assign Role To Admins",
        href: "/role/assign-role-to-admins",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 17_6,
        labelChild: "Assign Role To Users",
        href: "/role/assign-role-to-users",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 19,
    label: "House Material",
    icon: lockSVG,
    child: [
      {
        id: 19_1,
        labelChild: "Import House Materials",
        icon: ViewChildSVG,
        subChild: [],
        href: "/house-material/import-house-materials",
      },
      {
        id: 19_2,
        labelChild: "Create House Material",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 19_3,
        labelChild: "View  House Materials",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 19_4,
        labelChild: "View Pending House Materials",
        icon: ViewChildSVG,
        subChild: [],
      },
      {
        id: 19_5,
        labelChild: "View House Material Logs",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 20,
    label: "Suppliers",
    icon: userSVG,
    child: [
      {
        id: 20_1,
        labelChild: "Create Supplier",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 20_2,
        labelChild: "View Supplier",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 21,
    label: "SubContractors",
    icon: userSVG,
    child: [
      {
        id: 21_1,
        labelChild: "Create SubContractors",
        icon: plusSVG,
        subChild: [],
      },
      {
        id: 21_2,
        labelChild: "View SubContractors",
        icon: ViewChildSVG,
        subChild: [],
      },
    ],
  },
  {
    id: 22,
    label: "Settings",
    icon: settingSVG,
    child: [],
  },
];
