import Swal from "sweetalert2";
// const navigate = useNavigate;
const OK = "OK";
const Decline = "Decline";
const Application = "Application";
const Close = "Close";

export const errorFormFieldsNotComplete = (title, text) => {
  Swal.fire({
    icon: "warning",
    title: title,
    text: text,
    customClass: {
      confirmButton: "bg-success btn btn-primary me-md-3",
      cancelButton: "btn btn-secondary",
    },
  });
};
export const tokenExpireSwal = (msg) => {
  Swal.fire({
    title: "Session Expire!",
    text: msg,
    icon: "warning",
    showCancelButton: false,
    confirmButtonText: OK,
  }).then((result) => {
    if (result.isConfirmed) {
      window.localStorage.clear();
      window.location.href = "/";
      //navigate("/login");
    }
  });
};
export const deleteSwal = (msg) => {
  Swal.fire({
    title: "Delete!",
    text: msg,
    icon: "success",
    showCancelButton: false,
    confirmButtonText: OK,
    confirmButtonColor: "#4fb4ae",
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }
  });
};
export const errorSwal = (msg) => {
  Swal.fire({
    title: "Error!",
    text: msg,
    icon: "error",
    showCancelButton: false,
    confirmButtonColor: "#4fb4ae",
    confirmButtonText: OK,
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }
  });
};
export const warningSwal = (msg) => {
  Swal.fire({
    title: "Warning!",
    text: msg,
    icon: "warning",
    showCancelButton: false,
    confirmButtonColor: "#4fb4ae",
    confirmButtonText: OK,
  }).then((result) => {
    if (result.isConfirmed) {
      return true;
    }
  });
};
export const successMessageFormFormSubmit = (title, text) => {
  Swal.fire({
    icon: "success",
    title: title,
    text: text,
    confirmButtonColor: "#4fb4ae",
  });
};
export const successMessageLogout = (title, text) => {
  Swal.fire({
    icon: "success",
    title: title,
    text: text,
    customClass: {
      confirmButton: "bg-success btn btn-primary me-md-3",
      cancelButton: "btn btn-secondary",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      window.localStorage.clear();
      window.location.href = "/";
    }
  });
};
export const successMessageFormFormSubmitCB = (title, text, cb_api) => {
  Swal.fire({
    icon: "success",
    title: title,
    text: text,
    customClass: {
      confirmButton: "bg-success btn btn-primary me-md-3",
      cancelButton: "btn btn-secondary",
    },
  }).then((result) => {
    if (result.isConfirmed) {
      cb_api();
    }
  });
};
export const approval_cancel_form = (title, text, cb_api) => {
  Swal.fire({
    title: title,
    text: text,
    input: "textarea",
    inputValidator: (result) => !result && "Your comment is required",
    showCloseButton: true,
    inputAttributes: {
      autocapitalize: "off",
    },
    customClass: {
      validationMessage: "my-validation-message",
      confirmButton: "bg-success btn btn-primary me-md-3",
      denyButton: "btn btn-danger",
    },
    buttonsStyling: false,
    inputPlaceholder: "please enter your comments",
    showCancelButton: false,
    confirmButtonText: "Approve",
    showDenyButton: true,
    denyButtonText: Decline,
    showLoaderOnConfirm: true,
    showLoaderOnDeny: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: true,
    preConfirm: (value) => {
      if (!value) {
        Swal.showValidationMessage("Your comment is required");
        return false;
      } else {
        let respData = { action: 1, comment: value };
        cb_api(respData);
      }
    },
    preDeny: (value) => {
      if (!value) {
        Swal.showValidationMessage("Your comment is required");
        return false;
      } else {
        let respData = { action: 2, comment: value };
        cb_api(respData);
      }
    },
  });
};
export const mashup_application_form = (title, text, cb_api) => {
  Swal.fire({
    title: title,
    text: text,
    // input: "textarea",
    // inputValidator: ( result ) => !result && "Your comment is required",
    showCloseButton: true,
    // inputAttributes: {
    //   autocapitalize: "off",
    // },
    customClass: {
      //validationMessage: "my-validation-message",
      confirmButton: "bg-success btn btn-primary me-md-3",
      denyButton: "btn btn-secondary me-md-3",
    },
    buttonsStyling: false,
    inputPlaceholder: "please enter your comments",
    showCancelButton: false,
    confirmButtonText: "Mashup",
    showDenyButton: true,
    denyButtonText: Application,
    showLoaderOnConfirm: true,
    showLoaderOnDeny: true,
    allowOutsideClick: false,
    //returnInputValueOnDeny: true,
    preConfirm: () => {
      let respData = { action: 1 };
      cb_api(respData);
    },
    preDeny: () => {
      let respData = { action: 2 };
      cb_api(respData);
    },
  });
};
export const approval_delete_form = (title, text, row, cb_api) => {
  Swal.fire({
    title: title,
    text: text,
    input: "textarea",
    inputValidator: (result) => !result && "Your comment is required",
    showCloseButton: false,
    inputAttributes: {
      autocapitalize: "off",
    },
    customClass: {
      validationMessage: "my-validation-message",
      confirmButton: "bg-success btn btn-primary me-md-3",
      denyButton: "btn btn-secondary",
    },
    buttonsStyling: false,
    inputPlaceholder: "please enter your comments",
    showCancelButton: false,
    confirmButtonText: "Yes, Cancel it!",
    showDenyButton: true,
    denyButtonText: Close,
    showLoaderOnConfirm: true,
    showLoaderOnDeny: true,
    allowOutsideClick: false,
    returnInputValueOnDeny: false,
    preConfirm: (value) => {
      if (!value) {
        Swal.showValidationMessage("Your comment is required");
        return false;
      } else {
        let respData = { action: 1, comment: value };
        cb_api(respData);
      }
    },
    // preDeny: ( value ) =>
    // {
    //   if ( value == false )
    //   {
    //   }
    // },
  });
};
export const stateChangeMessage = () => {
  Swal.fire({
    title: "Do you want to save the changes?",
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: "Save",
    denyButtonText: "Don't save",
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.fire("Saved!", "", "success");
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  });
};
