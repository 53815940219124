/** @format */
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import {
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  FORM_ASSIGN_CANCEL_BTN,
  RADIO_DESIGN,
  FORM_MAIN_WRAPPER,
  FORM_INNER_PADDING,
  STEPPER_RADIOS_OPTIONS,
  STEPPER_LABLE_WRAPPER,
  STEPPER_RADIO_WRAPPER,
} from "../../../src/components/generals/common";
import { formAlignState, validationOfFormAlign } from "./formState";
import axios from "axios";
import Spinner from "../Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { jobCode } from "./data";
import Select from "react-select";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import { companyList } from "../Job/data";
import { BASE_URL_PORT_LOCAL_5000 } from "../../generals/common";
const FormAlign = () => {
  const [fieldValues, setFieldValues] = useState(formAlignState);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const [checkStatus, setCheckStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const { formId } = useParams();
  const isEditMode = formId ? true : false;

  console.log("formId", formId);
  const navigate = useNavigate();
  useEffect(() => {
    setErrors(validationOfFormAlign(fieldValues));
  }, [fieldValues]);
  const callJobs = async (Company) => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://server-beige-delta.vercel.app/api/file/file-get-job",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ Company }),
        }
      );
      if (!response.ok) {
        console.error("Error:", response.statusText);
        return;
      }
      const data = await response.json();
      setJobList(data.jobsWithCompany002);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };
  const getJobById = async () => {
    setLoading(true);
    const authAxios = axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/form-by-id",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/form-by-id", {
        _id: formId,
      })
      .then((response) => {
        console.log("response", response);
        let msg = response?.data?.message;
        if (response?.data?.status === 20022) {
          let data = response?.data?.foundForm;
          console.log("data", data.form[0]);

          console.log("data.company", data.jobNo);
          callJobs(data.company);
          setFieldValues({
            ...fieldValues,
            ...data.form[0],
            company:data.company,
            Job_id: String(data.jobNo),
          });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    // const sendSecondApi = async () => {
    //   const username = "blowe"; // Replace with actual username
    //   const password = "explorer"; // Replace with actual password
    //   const base64Credentials = btoa(`${username} ${password}`);
    //   const authorizationHeader = `Basic ${base64Credentials}`;
    //   try {
    //     console.log(
    //       "🚀 ~ file: FormAlign.js:28 ~ sendSecondApi ~ authorizationHeader:",
    //       authorizationHeader
    //     );
    //     const response = await axios.get(
    //       "https://eclipse.cpnhinc.com/api/app/cm/JC/m_jc_job_master?sortby=JOB%20ASC&rawColumns=1&pretty=0&fields=JOB&COMPANY=001",
    //       // { type: "cm/JC/m_jc_job_master" },
    //       {
    //         headers: {
    //           Authorization: authorizationHeader,
    //         },
    //       }
    //     );
    //     // Handle the response data
    //     console.log("Response Data:", response);
    //   } catch (error) {
    //     // Handle errors
    //     console.error("Error:", error);
    //   }
    // };
    // `https://server-beige-delta.vercel.app/api/job/job-no`
    // fetchData();
    localStorage.clear();
    if (isEditMode) {
      getJobById();
    }
  }, []);
console.log("fieldValues",fieldValues)
  const handleFieldChange = (event) => {
    console.log("handleFieldChange");
    const { name, value } = event.target;
    setCheckStatus(!checkStatus);
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
    if (value != 0) {
      callJobs(value);
    }
  };
  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    if (fieldValues.all_select) {
      const updatedState = {};
      for (const key in fieldValues) {
        if (fieldValues.hasOwnProperty(key)) {
          updatedState[key] = true;
        }
        setFieldValues(updatedState);
      }
    } else {
      setFieldValues({
        ...fieldValues,
        [name]: checked,
      });
    }
  };
  const handleReset = () => {
    setFieldValues({ ...initialState });
  };
  const handleSelectAll = () => {
    if (fieldValues.all_select) {
      const updatedState = {};
      for (const key in fieldValues) {
        if (fieldValues.hasOwnProperty(key)) {
          updatedState[key] = true;
        }
        setFieldValues(updatedState);
      }
    }
  };
  const handleFormStatus = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      localStorage.setItem("formAlign", JSON.stringify(fieldValues));
      navigate("/form/QC-FC");
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const handleFieldChanges = (e) => {
    setFieldValues({
      ...fieldValues,
      Job_id: e.value,
    });
  };
  return (
    <div className="max-w-3xl mx-auto shadow-lg mt-8 rounded-lg py-10">
      {loading ? <Spinner /> : null}
      <div className="px-8 pb-4 text-center border-b border-#ebedf3">
        <h1 className="font-bold text-base mb-1">Form Assessment</h1>
        <p className="underline text-sm justify-center flex gap-1">
          <u>Note:-</u>
          <span className="flex gap-1">
            <b className="text-red-800">
              Please check if you don't want to go through the process
            </b>
          </span>
        </p>
      </div>
      <div className={FORM_INNER_PADDING}>
        <div>
          <div className="flex flex-col my-4 ">
            <div className="flex flex-col">
              <label className="mb-2">
                Company<span className="text-red-800">*</span>
              </label>
              <div>
                <select
                  className={FORM_ASSIGN_INPUT_FIELD}
                  name="company"
                  value={fieldValues.company}
                  onChange={handleFieldChange}
                >
                  <option value={0}>Please select</option>
                  {companyList.map((data, idx) => {
                    return (
                      <option value={data.code} key={idx}>
                        {data.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {showError && errors.company && (
              <small className="text-red-700	">{errors.company}</small>
            )}
          </div>
          {
            <div className="flex flex-col my-4 ">
              <label className="mb-2">
                Job Number<span className="text-red-800">*</span>{" "}
              </label>
              <div>
                <Select options={jobList}  onChange={handleFieldChanges} />
              </div>
              {showError && errors.Job_id && (
                <small className="text-red-700	">{errors.Job_id}</small>
              )}
            </div>
          }
          {fieldValues.company == "003" ? (
            <>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.QC_rough_in_003
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Quality Check Rough-in
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="QC_rough_in_003"
                      value={fieldValues.QC_rough_in_003}
                      checked={fieldValues.QC_rough_in_003}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.QC_trim_003
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Quality Check Trim
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="QC_trim_003"
                      value={fieldValues.QC_trim_003}
                      checked={fieldValues.QC_trim_003}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : fieldValues.company == "002" ? (
            <>
              {" "}
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.QC_rough_002
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Quality Check Rough-in
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="QC_rough_002"
                      value={fieldValues.QC_rough_002}
                      checked={fieldValues.QC_rough_002}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.FC_rough_in_002
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Fixture Count Rough-in
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="FC_rough_in_002"
                      value={fieldValues.FC_rough_in_002}
                      checked={fieldValues.FC_rough_in_002}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.QC_check_trim_002
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Quality Check Trim
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="QC_check_trim_002"
                      value={fieldValues.QC_check_trim_002}
                      checked={fieldValues.QC_check_trim_002}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : fieldValues.company == "001" ? (
            <div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.QC_under_slab
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Quality Check Under Slab
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="QC_under_slab"
                      value={fieldValues.QC_under_slab}
                      checked={fieldValues.QC_under_slab}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.FC_under_slab
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Fixture Count Under Slab
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="FC_under_slab"
                      value={fieldValues.FC_under_slab}
                      checked={fieldValues.FC_under_slab}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.QC_rough_in
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Quality Check Rough-in
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="QC_rough_in"
                      value={fieldValues.QC_rough_in}
                      checked={fieldValues.QC_rough_in}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.FC_rough_in
                          ? "line-through text-red-700"
                          : ""
                      }`}
                    >
                      Fixture Count Rough-in
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="FC_rough_in"
                      value={fieldValues.FC_rough_in}
                      checked={fieldValues.FC_rough_in}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col  my-4 ">
                <div className="flex  gap-4">
                  <div className="w-1/2 xs:w-full">
                    <label
                      className={`mb-2 ${
                        fieldValues.QC_trim ? "line-through text-red-700" : ""
                      }`}
                    >
                      Quality Check Trim
                    </label>
                  </div>
                  <div className="w-1/2 xs:w-full text-right mr-2">
                    <input
                      type="checkbox"
                      className=" scale-150 shadow-sm w-4 h-4 text-blue-600 bg-gray-100 border-red-300 rounded  "
                      name="QC_trim"
                      value={fieldValues.QC_trim}
                      checked={fieldValues.QC_trim}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="px-8 xs:px-5 flex justify-center gap-8 xs:justify-between ">
          <div className="flex justify-end mt-6">
            <button
              type="button"
              className={FORM_ASSIGN_CANCEL_BTN}
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
          <div className="flex justify-end mt-6">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleFormStatus}
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormAlign;
