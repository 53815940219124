/** @format */
import React, { useEffect, useState } from "react";
import {
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  FORM_ASSIGN_CANCEL_BTN,
  RADIO_DESIGN,
  FORM_MAIN_WRAPPER,
  FORM_INNER_PADDING,
  STEPPER_RADIOS_OPTIONS,
  STEPPER_LABLE_WRAPPER,
  STEPPER_RADIO_WRAPPER,
  FORM_ASSIGN_SKIP_BTN,
} from "../../../src/components/generals/common";
import Spinner from "../../component/Spinner";
import { QC_UNDER_SLAB_STATE, validationOfQCUnderslab } from "./formState";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import { BASE_URL_PORT_LOCAL_5000, SWAL_CANCEL_DESIGN, SWAL_CONFIRM_DESIGN } from "../../generals/common";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Swal from "sweetalert2";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
const QCUnderSlab = ({ onNext }) => {
  const [fieldValues, setFieldValues] = useState(QC_UNDER_SLAB_STATE);
  const [avaibleUsers, setAvailableUser] = useState([]);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const [editMode, setEditMode] = useState(false);
  const [FC_UNDER_SLAB, setFC_UNDER_SLAB] = useState([]);
  const [FC_ROUGH_IN, setFC_ROUGH_IN] = useState([]);
  const [QC_TRIM, setQC_TRIM] = useState([]);
  const [FC_TRIM, setFC_TRIM] = useState([]);
  const [QC_ROUGH_IN, setQC_ROUGH_IN] = useState([]);
  const formCondition = JSON.parse(localStorage.getItem("formAlign"));
  console.log("🚀 ~ QCUnderSlab ~ formCondition:", formCondition);
  const navigate = useNavigate();
  const fetchUserList = async () => {
    setLoading(true);
    fetch(`https://server-beige-delta.vercel.app/api/user/get-user-list`)
      .then((response) => response.json())
      .then((json) => setUser(json?.user));
    getJobByJobNo(formCondition.Job_id);
  };
  const getJobByJobNo = async (jobNo) => {
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/form-by-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/form-by-job", {
        jobNo,
      })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        let msg = response?.data?.message;
        if (response?.data?.status === 20022) {
          const data = response?.data?.foundForm?.form[0]?.QC_UNDER_SLAB;
          setFC_UNDER_SLAB(response?.data?.foundForm?.form[0]?.FC_UNDER_SLAB);
          setFC_ROUGH_IN(response?.data?.foundForm?.form[0]?.FC_ROUGH_IN);
          setQC_ROUGH_IN(response?.data?.foundForm?.form[0]?.QC_ROUGH_IN);
          setQC_TRIM(response?.data?.foundForm?.form[0]?.QC_TRIM);
          setFC_TRIM(response?.data?.foundForm?.form[0]?.FC_TRIM);
          const _id = response?.data?.foundForm?._id;
          setFieldValues({
            ...data,
          });
          localStorage.setItem("formMDBId", _id);
          if (data && Object.keys(data && data).length > 0) {
            setEditMode(true);
          }
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const updateForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      _id: fieldValues._id,
      QC_UNDER_SLAB: fieldValues,
      FC_UNDER_SLAB: FC_UNDER_SLAB,
      FC_ROUGH_IN: FC_ROUGH_IN,
      QC_ROUGH_IN: QC_ROUGH_IN,
      QC_TRIM: QC_TRIM,
      FC_TRIM: FC_TRIM,
    };
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/update-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .put("https://server-beige-delta.vercel.app/api/form/update-job", {
        form,
      })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40001) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Successful",
              text: "Form has been Updated!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Want to Stay on Page?",
              cancelButtonText: "No!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setFieldValues({
                  ...initialState,
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate("/form");
              }
            });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const addForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      QC_UNDER_SLAB: fieldValues,
    };
    console.log("payload,", form);
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/add-form",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/add-form", { form })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40002) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Successful",
              text: "Form has been Updated!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Want to Stay on Page?",
              cancelButtonText: "No!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setFieldValues({
                  ...initialState,
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate("/form");
              }
            });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    fetchUserList();
  }, []);
  useEffect(() => {
    setErrors(validationOfQCUnderslab(fieldValues));
  }, [fieldValues]);
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      if (editMode) {
        updateForm();
      } else {
        addForm();
      }
      // setTimeout(() => {
      //   localStorage.setItem(
      //     "QC_under_slab",
      //     JSON.stringify({ QC_under_slab: fieldValues })
      //   );
      //   onNext();
      //   setLoading(false);
      // }, 500);
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const handleNext = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      if (fieldValues) {
        localStorage.setItem("QC_under_slab", JSON.stringify(fieldValues));
      }
      onNext();
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  return (
    <div className={FORM_MAIN_WRAPPER}>
      {loading ? <Spinner /> : null}
      <div className="px-8 pb-4 text-center border-b border-#ebedf3">
        <h1 className="font-bold text-base mb-1">
          Quality Control Checklist - Under Slab
        </h1>
        <p className="underline text-sm justify-center flex gap-1">
          <u>Note:-</u>
          <span className="flex gap-1">
            <b className="text-green-800">QC=Quality Control</b>,
            <b className="text-green-800">FC=Fixture Count</b>,
            <b className="text-yellow-500">
              Please Hover To BUTTONS To Check Their Behavior
            </b>
          </span>
        </p>
      </div>
      <div className={FORM_INNER_PADDING}>
        <div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Date<span className="text-red-800">*</span>
            </label>
            <div>
              <input
                className={FORM_ASSIGN_INPUT_FIELD}
                type="date"
                name="QC_UNDER_SLAB_DATE"
                value={fieldValues.QC_UNDER_SLAB_DATE}
                onChange={handleFieldChange}
              />
            </div>
            {showError && errors.QC_UNDER_SLAB_DATE && (
              <small className="text-red-700	">
                {errors.QC_UNDER_SLAB_DATE}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              QC inspector<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="QC_UNDER_SLAB_QC_INSPECTOR"
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {user?.map((data, idx) => {
                  return (
                    <option key={idx} value={data._id}>
                      {data.reg_name}
                    </option>
                  );
                })}{" "}
              </select>
            </div>
            {showError && errors.QC_UNDER_SLAB_QC_INSPECTOR && (
              <small className="text-red-700	">
                {errors.QC_UNDER_SLAB_QC_INSPECTOR}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Whose being inspected<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="QC_UNDER_SLAB_BEING_INSPECTED"
                value={fieldValues.QC_UNDER_SLAB_BEING_INSPECTED}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {user?.map((data, idx) => {
                  if (data._id != fieldValues.QC_UNDER_SLAB_QC_INSPECTOR)
                    return (
                      <option key={idx} value={data._id}>
                        {data.reg_name}
                      </option>
                    );
                })}
              </select>
            </div>
            {showError && errors.QC_UNDER_SLAB_BEING_INSPECTED && (
              <small className="text-red-700	">
                {errors.QC_UNDER_SLAB_BEING_INSPECTED}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">Sub Contractor</label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="QC_UNDER_SLAB_SUBCONTRACTOR"
                value={fieldValues.QC_UNDER_SLAB_SUBCONTRACTOR}
                onChange={handleFieldChange}
              >
                <option value={0}>Please select</option>
                {user?.map((data, idx) => {
                  return (
                    <option key={idx} value={data._id}>
                      {data.reg_name}
                    </option>
                  );
                })}{" "}
              </select>
            </div>
            {showError && errors.QC_UNDER_SLAB_SUBCONTRACTOR && (
              <small className="text-red-700	">
                {errors.QC_UNDER_SLAB_SUBCONTRACTOR}
              </small>
            )}
          </div>
          <div className="mt-8">
            <h2 className="font-bold mb-8 text-center text-base ">
              Confirm the following{" "}
            </h2>
            <div className={STEPPER_RADIOS_OPTIONS}>
              <div className={STEPPER_LABLE_WRAPPER}>
                <label className="text-ellipsis">DRAIN TEST</label>
              </div>
              <ul className={STEPPER_RADIO_WRAPPER}>
                <li>
                  <div className="flex items-center ps-3 xs:p-0 ">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_TEST_FAIL"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_TEST"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_TEST == "0"
                          ? true
                          : false
                      }
                      value={0}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_TEST_FAIL"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      PASS
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      className={RADIO_DESIGN}
                      id="QC_UNDER_SLAB_BEING_DRAIN_TEST_PASS"
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_TEST"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_TEST == "1"
                          ? true
                          : false
                      }
                      value={1}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_TEST_PASS"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      FAIL
                    </label>
                  </div>
                </li>
                <li className="">
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_TEST_NA"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_TEST"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_TEST == "2"
                          ? true
                          : false
                      }
                      value={2}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_TEST_NA"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      N/A
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className={STEPPER_RADIOS_OPTIONS}>
              <div className={STEPPER_LABLE_WRAPPER}>
                <label className="text-ellipsis">
                  ALL WATER LINES SLEEVED WITH PVC
                </label>
              </div>
              <ul className={STEPPER_RADIO_WRAPPER}>
                <li>
                  <div className="flex items-center ps-3 xs:p-0 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC_FAIL"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC == "0"
                          ? true
                          : false
                      }
                      value={0}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC_FAIL"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      PASS
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      className={RADIO_DESIGN}
                      id="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC_PASS"
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC == "1"
                          ? true
                          : false
                      }
                      value={1}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC_PASS"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      FAIL
                    </label>
                  </div>
                </li>
                <li className="">
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC_NA"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC == "2"
                          ? true
                          : false
                      }
                      value={2}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_SLEEVED_WITH_PVC_NA"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      N/A
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className={STEPPER_RADIOS_OPTIONS}>
              <div className={STEPPER_LABLE_WRAPPER}>
                <label className="text-ellipsis">
                  DOUBLE-CHECK SELECTION SHEET FOR OPTIONS
                </label>
              </div>
              <ul className={STEPPER_RADIO_WRAPPER}>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK_FAIL"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK ==
                        "0"
                          ? true
                          : false
                      }
                      value={0}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK_FAIL"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      PASS
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      className={RADIO_DESIGN}
                      id="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK_PASS"
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK ==
                        "1"
                          ? true
                          : false
                      }
                      value={1}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK_PASS"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      FAIL
                    </label>
                  </div>
                </li>
                <li className="">
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK_NA"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK ==
                        "2"
                          ? true
                          : false
                      }
                      value={2}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_DOUBLE_CHECK_NA"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      N/A
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className={STEPPER_RADIOS_OPTIONS}>
              <div className={STEPPER_LABLE_WRAPPER}>
                <label className="text-ellipsis">CLEAN OUTS PLUGGED</label>
              </div>
              <ul className={STEPPER_RADIO_WRAPPER}>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT_FAIL"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT == "0"
                          ? true
                          : false
                      }
                      value={0}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT_FAIL"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      PASS
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      className={RADIO_DESIGN}
                      id="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT_PASS"
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT == "1"
                          ? true
                          : false
                      }
                      value={1}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT_PASS"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      FAIL
                    </label>
                  </div>
                </li>
                <li className="">
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT_NA"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT == "2"
                          ? true
                          : false
                      }
                      value={2}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_CLEAN_OUT_NA"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      N/A
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className={STEPPER_RADIOS_OPTIONS}>
              <div className={STEPPER_LABLE_WRAPPER}>
                <label className="text-ellipsis">
                  FOUNDATION CHECKED FOR ACCURACY
                </label>
              </div>
              <ul className={STEPPER_RADIO_WRAPPER}>
                <li>
                  <div className="flex items-center ps-3 xs:p-0 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK_FAIL"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK ==
                        "0"
                          ? true
                          : false
                      }
                      value={0}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK_FAIL"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      PASS
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      className={RADIO_DESIGN}
                      id="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK_PASS"
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK ==
                        "1"
                          ? true
                          : false
                      }
                      value={1}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK_PASS"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      FAIL
                    </label>
                  </div>
                </li>
                <li className="">
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK_NA"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK ==
                        "2"
                          ? true
                          : false
                      }
                      value={2}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_FOUNDATION_CHECK_NA"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      N/A
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className={STEPPER_RADIOS_OPTIONS}>
              <div className={STEPPER_LABLE_WRAPPER}>
                <label className="text-ellipsis">
                  ALL PIPES TRIPLED CHECKED FOR ACCURACY
                </label>
              </div>
              <ul className={STEPPER_RADIO_WRAPPER}>
                <li>
                  <div className="flex items-center ps-3 xs:p-0 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK_FAIL"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK ==
                        "0"
                          ? true
                          : false
                      }
                      value={0}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK_FAIL"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      PASS
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      className={RADIO_DESIGN}
                      id="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK_PASS"
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK ==
                        "1"
                          ? true
                          : false
                      }
                      value={1}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK_PASS"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      FAIL
                    </label>
                  </div>
                </li>
                <li className="">
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK_NA"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK ==
                        "2"
                          ? true
                          : false
                      }
                      value={2}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_TRIPLE_CHECK_NA"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      N/A
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div className={STEPPER_RADIOS_OPTIONS}>
              <div className={STEPPER_LABLE_WRAPPER}>
                <label className="text-ellipsis">
                  SEWER MAIN STUBBED OUTSIDE FOUNDATION
                </label>
              </div>
              <ul className={STEPPER_RADIO_WRAPPER}>
                <li>
                  <div className="flex items-center ps-3 xs:p-0 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION_FAIL"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION ==
                        "0"
                          ? true
                          : false
                      }
                      value={0}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION_FAIL"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      PASS
                    </label>
                  </div>
                </li>
                <li>
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      className={RADIO_DESIGN}
                      id="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION_PASS"
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION ==
                        "1"
                          ? true
                          : false
                      }
                      value={1}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION_PASS"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      FAIL
                    </label>
                  </div>
                </li>
                <li className="">
                  <div className="flex items-center ps-3 xs:p-0">
                    <input
                      id="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION_NA"
                      className={RADIO_DESIGN}
                      type="radio"
                      name="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION"
                      checked={
                        fieldValues.QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION ==
                        "2"
                          ? true
                          : false
                      }
                      value={2}
                      onChange={handleFieldChange}
                    />
                    <label
                      for="QC_UNDER_SLAB_BEING_DRAIN_MAIN_STUBBED_OUTSIDE_FOUNDATION_NA"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      N/A
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8">
            <h2 className="font-bold mb-8 text-center text-base ">
              ADDITIONAL NOTES
            </h2>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className={`${FORM_ASSIGN_INPUT_FIELD} h-auto`}
            ></textarea>
          </div>
        </div>
      </div>
      {/* <div className="px-8 xs:px-5 flex justify-center gap-8 xs:justify-between ">
        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={handleSubmit}
          >
            {isEditMode ? "Update" : "Submit"}
          </button>
        </div>
      </div> */}
      <div className="px-8 xs:px-5 flex justify-center mt-6 gap-6 xs:justify-between ">
        <div>
          <Tooltip title="This will add/update record">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleSubmit}
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </Tooltip>
        </div>
        {/* <div>
          <Tooltip title="This will store data and jump to next form">
            <button onClick={handleNext} className={FORM_ASSIGN_CANCEL_BTN}>
              Next
            </button>
          </Tooltip>
        </div> */}
        <div>
          <Tooltip title="This Will Skip to next step with out storing current form">
            <button
              className={`bg-opacity-90 ${FORM_ASSIGN_SKIP_BTN}`}
              onClick={onNext}
            >
              Skip <ArrowForwardIcon />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default QCUnderSlab;
