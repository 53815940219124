export const userCredentials = {
  reg_name: "",
  reg_email: "",
  reg_password: "",
  reg_confirm_password: "",
  login_email: "",
  login_password: "",
  forgot_email: "",
};

export const errorValidationForLoginUser = (values) => {
  let errors = {};
  if (!values.login_email) {
    errors.login_email = "This Field is Required";
  }
  if (!values.login_password) {
    errors.login_password = "This Field is Required";
  }

  return errors;
};
export const errorValidationForRegisterUser = (values) => {
  let errors = {};
  if (!values.reg_name) {
    errors.reg_name = "asThis Field is Required";
  }
  if (!values.reg_email) {
    errors.reg_email = "This Field is Required";
  }
  if (!values.reg_password) {
    errors.reg_password = "This Field is Required";
  }
  if (!values.reg_confirm_password) {
    errors.reg_confirm_password = "This Field is Required";
  }
  if (values.reg_password !== values.reg_confirm_password) {
    errors.reg_confirm_password = "Password does not match";
  }

  return errors;
};

export const errorValidationForForgotPassword = (values) => {
  let errors = {};
  if (!values.forgot_email) {
    errors.forgot_email = "This Field is Required";
  }

  return errors;
};
