/** @format */
import React, { useState, useEffect } from "react";
import {
  FORM_ASSIGN_HEADING_WRAPPER,
  FORM_ASSIGN_INPUT_FIELD,
  FORM_ASSIGN_SUBMIT_BTN,
  FORM_ASSIGN_CANCEL_BTN,
  FORM_MAIN_WRAPPER,
  FORM_INNER_PADDING,
  STEPPER_LABLE_WRAPPER,
  STEPPER_FORM_INPUT_FIELD,
  PLUS_BUTTON_CSS,
  FORM_ASSIGN_SKIP_BTN,
  // PLUS_BUTTON,
} from "../generals/common";
import { fcRoughin } from "./data";
import { FC_ROUGH_IN, validationOfFCroughIn } from "./formState";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import Spinner from "../Spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { SWAL_CANCEL_DESIGN, SWAL_CONFIRM_DESIGN } from "../../generals/common";
const FixtureCountRough = ({ onNext, onBack }) => {
  const [fieldValues, setFieldValues] = useState(FC_ROUGH_IN);
  const [error, setError] = useState();
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const isEditMode = localStorage.getItem("FC_rough_in") ? true : false;
  const [inputs, setInputs] = useState(["", ""]);
  const [editMode, setEditMode] = useState(false);
  const [formState, setFormState] = useState(false);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const formCondition = JSON.parse(localStorage.getItem("formAlign"));
  const [FC_UNDER_SLAB, setFC_UNDER_SLAB] = useState([]);
  const [QC_UNDER_SLAB, setQC_UNDER_SLAB] = useState([]);
  const [QC_ROUGH_IN, setQC_ROUGH_IN] = useState([]);
  const [QC_TRIM, setQC_TRIM] = useState([]);
  const [FC_TRIM, setFC_TRIM] = useState([]);
  const navigate = useNavigate();
  const fetchUserList = async () => {
    setLoading(true);
    fetch(`https://server-beige-delta.vercel.app/api/user/get-user-list`)
      .then((response) => response.json())
      .then((json) => setUser(json?.user));
    getJobByJobNo(formCondition.Job_id);
  };
  const getJobByJobNo = async (jobNo) => {
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/form-by-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/form-by-job", {
        jobNo,
      })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        let msg = response?.data?.message;
        if (response?.data?.status === 20022) {
          const data = response?.data?.foundForm?.form[0]?.FC_ROUGH_IN;
          const _id = response?.data?.foundForm?._id;
          const formState = response?.data?.foundForm != null;
          setFormState(formState);
          localStorage.setItem("formMDBId", _id);
          setFC_UNDER_SLAB(response?.data?.foundForm?.form[0]?.FC_UNDER_SLAB);
          setQC_UNDER_SLAB(response?.data?.foundForm?.form[0]?.QC_UNDER_SLAB);
          setQC_ROUGH_IN(response?.data?.foundForm?.form[0]?.QC_ROUGH_IN);
          setQC_TRIM(response?.data?.foundForm?.form[0]?.QC_TRIM);
          setFC_TRIM(response?.data?.foundForm?.form[0]?.FC_TRIM);
          setFieldValues({
            ...data,
          });
          if (data && Object.keys(data && data).length > 0) {
            setEditMode(true);
          }
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const updateForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      FC_UNDER_SLAB: FC_UNDER_SLAB,
      QC_UNDER_SLAB: QC_UNDER_SLAB,
      QC_ROUGH_IN: QC_ROUGH_IN,
      QC_TRIM: QC_TRIM,
      FC_TRIM: FC_TRIM,
      FC_ROUGH_IN: fieldValues,
      _id: localStorage.getItem("formMDBId"),
    };
    console.log("🚀 ~ updateForm ~ form:", form);
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/update-job",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .put("https://server-beige-delta.vercel.app/api/form/update-job", {
        form,
      })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40001) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Successful",
              text: "Form has been Updated!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Want to Stay on Page?",
              cancelButtonText: "No!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setFieldValues({
                  ...initialState,
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate("/form");
              }
            });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  const addForm = async (uid) => {
    setLoading(true);
    let form = {
      job_id: formCondition?.Job_id,
      company: formCondition?.company,
      FC_ROUGH_IN: fieldValues,
    };
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/add-form",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .post("https://server-beige-delta.vercel.app/api/form/add-form", { form })
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 40002) {
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: SWAL_CONFIRM_DESIGN,
              cancelButton: SWAL_CANCEL_DESIGN,
            },
            buttonsStyling: false,
          });
          swalWithBootstrapButtons
            .fire({
              title: "Successful",
              text: "Form has been Updated!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Want to Stay on Page?",
              cancelButtonText: "No!",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setFieldValues({
                  ...initialState,
                });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                navigate("/form");
              }
            });
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    fetchUserList();
  }, []);
  const handleFieldChange = (event) => {
    console.log("🚀 ~ handleFieldChange ~ event:", event.target.value);
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);
  };
  const handleFieldChangeNotANumber = (event) => {
    const { name, value } = event.target;
    if (!isNaN(value) || value === "") {
      setFieldValues({
        ...fieldValues,
        [name]: value,
      });
    } else {
      setFieldValues({
        ...fieldValues,
        [name]: 0,
      });
    }
  };
  const handleKeyDown = (e, inputName) => {
    if (e.key === "Backspace" && fieldValues[inputName].length > 0) {
      setFieldValues((prevValues) => ({
        ...prevValues,
        [inputName]: prevValues[inputName].slice(0, -1),
      }));
    }
  };
  const handleButtonClick = () => {
    setInputs([...inputs, "", ""]);
  };
  const handleSubmit = () => {
    setShowError(true);
    // if (Object.keys(error).length === 0) {
    if (formState) {
      updateForm();
    } else {
      addForm();
    }
    // setTimeout(() => {
    //   localStorage.setItem(
    //     "FC_rough_in",
    //     JSON.stringify({ FC_rough_in: fieldValues })
    //   );
    //   onNext();
    //   setLoading(false);
    // }, 500);
    // } else {
    //   errorFormFieldsNotComplete(
    //     `${"Invalid Field"}`,
    //     `${"Please enter required field"}`
    //   );
    // }
  };
  const handleNext = () => {
    setShowError(true);
    if (Object.keys(error).length === 0) {
      if (fieldValues) {
        localStorage.setItem("FC_rough_in", JSON.stringify(fieldValues));
      }
      onNext();
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  const updatedFcRoughin = fcRoughin.map((item) => {
    const modifiedLabel = item.label.replace(/[\s-]/g, "_"); // Remove spaces and dashes and replace with underscore
    return {
      ...item,
      name: modifiedLabel,
    };
  });
  const handleBack = () => {
    onBack();
  };
  const actualTotalDeducted = fcRoughin.reduce((total, item) => {
    const value =
      (parseFloat(fieldValues[item.deductedFieldName]) || 0) * item.unitPrice;
    return total + value;
  }, 0);
  const actualTotalSubPay = fcRoughin.reduce((total, item) => {
    const value =
      (parseFloat(fieldValues[item.subPayFieldName]) || 0) * item.unitPrice;
    return total + value;
  }, 0);
  return (
    <div className={FORM_MAIN_WRAPPER}>
      {loading ? <Spinner /> : null}
      <div className="px-8 pb-4 text-center border-b border-#ebedf3">
        <h1 className="font-bold text-base mb-1">Fixture Count Rough In</h1>
        <p className="underline text-sm justify-center flex gap-1">
          <u>Note:-</u>
          <span className="flex gap-1">
            <b className="text-green-800">QC=Quality Control</b>,
            <b className="text-green-800">FC=Fixture Count</b>
            <b className="text-yellow-500">
              Please Hover To BUTTONS To Check Their Behavior
            </b>
          </span>
        </p>
      </div>
      <div className={FORM_INNER_PADDING}>
        <div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Date<span className="text-red-800">*</span>
            </label>
            <div>
              <input
                className={FORM_ASSIGN_INPUT_FIELD}
                type="date"
                name="FC_ROUGH_IN_DATE"
                value={fieldValues.FC_ROUGH_IN_DATE}
                onChange={handleFieldChange}
              />
            </div>
            {showError && error.FC_ROUGH_IN_DATE && (
              <small className="text-red-700	">{error.FC_ROUGH_IN_DATE}</small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">
              Whose being inspected<span className="text-red-800">*</span>
            </label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="FC_ROUGH_IN_BEING_INSPECTED"
                value={fieldValues.FC_ROUGH_IN_BEING_INSPECTED}
                onChange={handleFieldChange}
              >
                <option value={0}>Please Select</option>
                {user.map((data, idx) => {
                  return (
                    <option key={idx} value={data._id}>
                      {data.reg_name}
                    </option>
                  );
                })}{" "}
              </select>
            </div>
            {showError && error.FC_ROUGH_IN_BEING_INSPECTED && (
              <small className="text-red-700	">
                {error.FC_ROUGH_IN_BEING_INSPECTED}
              </small>
            )}
          </div>
          <div className="flex flex-col my-4 ">
            <label className="mb-2">Sub Contractor(if used)</label>
            <div>
              <select
                className={FORM_ASSIGN_INPUT_FIELD}
                name="FC_ROUGH_IN_BEING_SUB_CONTRACTOR"
                value={fieldValues.FC_ROUGH_IN_BEING_SUB_CONTRACTOR}
                onChange={handleFieldChange}
              >
                <option value={0}>Please Select</option>
                {user.map((data, idx) => {
                  return (
                    <option key={idx} value={data._id}>
                      {data.reg_name}
                    </option>
                  );
                })}{" "}
              </select>
            </div>
            {showError && error.FC_ROUGH_IN_BEING_SUB_CONTRACTOR && (
              <small className="text-red-700	">
                {error.FC_ROUGH_IN_BEING_SUB_CONTRACTOR}
              </small>
            )}
          </div>
          <div className="mt-8">
            <h2 className="font-bold mb-8 text-center text-base ">
              Confirm the following{" "}
            </h2>
            <tr className="bg-primary p-4 w-full flex text-sm gap-0 justify-between text-white rounded-lg mb-8">
              <td className=" font-extrabold w-2/4">Fixtures(qty)</td>
              <td className=" font-extrabold">Total Quantity</td>
              <td className=" font-extrabold">Deducted From Sub Pay</td>
            </tr>
          </div>
          {/* Fixture thing */}
          <div className="h-[500px] overflow-y-auto  ">
            {fcRoughin?.map((item, index) => (
              <div key={index} className="flex gap-4 items-center">
                <table className="w-full">
                  <tbody>
                    <div className="flex gap-4 mb-4 w-full">
                      <div className={STEPPER_LABLE_WRAPPER}>
                        <label className="text-ellipsis text-sm block">
                          {item.label}{" "}
                          <span className="text-green-800">
                            {item.unitPrice !== null
                              ? `$${item.unitPrice} EA`
                              : ""}
                          </span>
                        </label>
                      </div>
                      <div>
                        <input
                          className={`${STEPPER_FORM_INPUT_FIELD} w-28 `}
                          type="text"
                          name={`${item.subPayFieldName}`}
                          value={fieldValues[`${item.subPayFieldName}`] || ""}
                          onChange={handleFieldChangeNotANumber}
                          maxLength="2"
                          onKeyDown={(e) =>
                            handleKeyDown(e, `${item.subPayFieldName}`)
                          }
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          className={`${STEPPER_FORM_INPUT_FIELD} w-28`}
                          value={fieldValues[item.deductedFieldName] || ""}
                          name={item.deductedFieldName}
                          onChange={handleFieldChangeNotANumber}
                          maxLength="2"
                          onKeyDown={(e) =>
                            handleKeyDown(e, item.deductedFieldName)
                          }
                        />
                      </div>
                    </div>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <div className="flex gap-4 py-3  items-center border-t border-b ">
            <tr className="flex gap-4 items-center  w-full">
              <td className={STEPPER_LABLE_WRAPPER}>
                <div>
                  <label className="text-ellipsis text-primary  text-base font-black  block mb-2	">
                    TOTAL
                  </label>
                </div>
              </td>
              <td className="w-40 font-bold text-base flex items-center px-4 border-2 border-secondary rounded-lg h-12">
                {isNaN(actualTotalSubPay)
                  ? "Invalid Input"
                  : `$${actualTotalSubPay}`}
              </td>
              <td className="w-40 font-bold text-base flex items-center px-4 border-2 border-secondary rounded-lg h-12">
                {isNaN(actualTotalDeducted)
                  ? "Invalid Input"
                  : `$${actualTotalDeducted}`}
              </td>
            </tr>
          </div>
          <div className="mt-8">
            <table className="w-full flex justify-around text-white rounded-lg mb-8">
              <tbody className="w-full">
                <tr className="bg-primary w-full flex justify-around text-white rounded-lg mb-8">
                  <td className="p-4 font-extrabold">
                    List Any Extra Installation(s) By Sub
                  </td>
                  <td className="p-4 font-extrabold">Extra Amount To Pay</td>
                </tr>
                <div className="grid grid-cols-2  px-4 mb-4">
                  {inputs.map((input, index) => (
                    <tr key={index} className="px-4 xs:p-0">
                      <td className="p-4  block">
                        <input
                          type="text"
                          value={input}
                          className={`${FORM_ASSIGN_INPUT_FIELD} text-neutral-950`}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </div>
                <td className="text-right flex   w-full px-4">
                  <td className="w-full"></td>
                  <td className="w-full flex justify-center ">
                    <button
                      onClick={handleButtonClick}
                      className={PLUS_BUTTON_CSS}
                      // className={""}
                    >
                      <span className="-mt-1.5">+</span>
                    </button>
                  </td>
                </td>
              </tbody>
            </table>
          </div>
          {/* end of Fixture */}
          {/* additional installation  */}
          {/* <div className="mt-8">
            <tr className="bg-primary w-full flex justify-around text-white rounded-lg mb-8">
              <td className="p-4 font-extrabold">
                List Any Extra Installation(s) By Sub
              </td>
              <td className="p-4 font-extrabold">Extra Amount To Pay</td>
            </tr>
          </div>
          {Array(4)
            .fill(1)
            .map((data, idx) => {
              return (
                <div>
                  <tr className="flex justify-around px-4 mb-4">
                    <td className="w-2/5">
                      <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
                    </td>
                    <td className="w-2/5">
                      <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
                    </td>
                  </tr>
                </div>
              );
            })} */}
          {/* <hr className="my-4" />
          <tr className="flex justify-around px-4 mb-8">
            <td className="w-2/5">
              <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
            </td>
            <td className="w-2/5">
              <input type="text" className={FORM_ASSIGN_INPUT_FIELD} />
            </td>
          </tr> */}
          {/* end of additional installation */}
          <tr className="bg-primary w-full flex justify-between text-white rounded-lg mb-8 ">
            <td className="p-4">
              <h2 className="font-bold  text-center text-base ">
                ADDITIONAL NOTES
              </h2>
            </td>
            <td className="p-4">
              {" "}
              <h2 className="font-bold text-center text-base ">AMEND FORM </h2>
            </td>
          </tr>
          <tr className="flex items-center flex-wrap ">
            <td className="w-2/4">
              {" "}
              <div className="mt-8">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className={`${FORM_ASSIGN_INPUT_FIELD} h-auto w-full`}
                ></textarea>
              </div>
            </td>
            <td className="w-2/5">
              <input type="checkbox" className="ml-32 scale-150 shadow-sm" />
            </td>
          </tr>
        </div>
      </div>
      {/* <div className="px-8 flex justify-center gap-8">
        <div className="flex justify-end mt-6">
          <button
            type="button"
            className={FORM_ASSIGN_CANCEL_BTN}
            onClick={handleBack}
          >
            Cancel
          </button>
        </div>
        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={handleSubmit}
          >
            {isEditMode ? "Update" : "Submit"}
          </button>
        </div>
      </div> */}
      <div className="px-8 xs:px-5 flex justify-center mt-6 gap-6 xs:justify-between ">
        <div>
          <Tooltip title="This will take back">
            <button
              type="button"
              className={`bg-opacity-75 flex items-center justify-center gap-2 ${FORM_ASSIGN_CANCEL_BTN}`}
              onClick={handleBack}
            >
              <ArrowBackIcon style={{ marginTop: 2 }} /> Back
            </button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="This will add/update record">
            <button
              type="submit"
              className={FORM_ASSIGN_SUBMIT_BTN}
              onClick={handleSubmit}
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </Tooltip>
        </div>
        {/* <div>
          <Tooltip title="This will store data and jump to next form">
            <button onClick={handleNext} className={FORM_ASSIGN_CANCEL_BTN}>
              Next
            </button>
          </Tooltip>
        </div> */}
        <div>
          <Tooltip title="This Will Skip to next step with out storing current form">
            <button
              className={`bg-opacity-90 ${FORM_ASSIGN_SKIP_BTN}`}
              onClick={onNext}
            >
              Skip <ArrowForwardIcon />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default FixtureCountRough;
