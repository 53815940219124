/** @format */
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { faEye, faXmark } from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertToTitleCase } from "../../../src/components/generals/common";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };
const TransitionsModal = ({ data, title,showModal,setshowModal }) => {

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const [menu, setMenu] = useState({});
  const handleClose = () => setshowModal(false);
  // const convertedData = {};
  // useEffect(() => {
  //   for (const key in data) {
  //     if (key === "status") {
  //       // Convert 'true' or 'false' to actual boolean
  //       convertedData[key] = data[key] === "true";
  //     } else {
  //       // Keep other values as they are
  //       convertedData[key] = data[key];
  //     }
  //   }
  //   setMenu(convertedData);
  // }, [data]);
  // const roleName = "role_name";
  // const formattedRoleName = ;
  // console.log(formattedRoleName);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showModal}>
          <Box
            style={{
              minHeight: "300px",
            }}
            className=" border-0 outline-none absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 rounded-lg bg-white max-w-xl xs:w-11/12 w-full p-8  min-h-max max-h-max flex items-start pt-12 justify-center "
          >
            <div className="w-11/12">
              <div className="bg-primary w-8 h-8 flex items-center justify-center rounded-full text-white absolute top-4 right-4 hover:bg-opacity-80 cursor-pointer hover:scale-110 transition-all">
                <FontAwesomeIcon
                  onClick={handleClose}
                  className="text-xl"
                  icon={faXmark}
                />
              </div>
              <Typography
                id="transition-modal-title"
                variant="h4"
                component="h4"
                className="text-center bg-primary text-white py-2 font-bold mt-4"
              >
                {title !== "" ? title + data.LineId : "Role Details"}
              </Typography>
              <div className="flex border w-full">
                <table className="table-data">
                  <tbody>
                    {/* {Object.entries(data).map(([key, value]) => {
                      return (
                        <tr>
                          {convertToTitleCase(key)}
                          <td>{value}</td>
                        </tr>
                      );
                    })} */}
                    
                    <tr>
                          <td>{data}</td>
                        </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
