import { options } from "../../../src/components/generals/data.js";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  errorValidationForRoleAssigningAdmin,
  roleAssigningAdmin,
} from "./formState";
import { errorFormFieldsNotComplete } from "../../../src/components/generals/swalMessages";
import { FORM_ASSIGN_HEADING_WRAPPER, FORM_ASSIGN_INPUT_FIELD, FORM_ASSIGN_SUBMIT_BTN } from "../../../src/components/generals/common";

const AssignRolesToUsers = () => {
  const [fieldValues, setFieldValues] = useState(roleAssigningAdmin);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    setErrors(errorValidationForRoleAssigningAdmin(fieldValues));
  }, [fieldValues]);
  const handleFieldChangeForMultiSelector = (event) => {
    // const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      dashboard_role: event,
    });
    setErrors({
      ...errors,
      dashboard_role: "",
    });
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      // if (isEditMode) {
      //   updateMenuApi();
      // } else {
      //   addMenuApi();
      // }
    } else {
      errorFormFieldsNotComplete(
        "Invalid Field",
        "Please enter required field"
      );
    }
  };
  return (
    <>
      {/* <div className="roleForm-container">
        <div className="roleForm-header radius0">
          <div className="roleForm-title">
            <h3>Assign Roles To User</h3>
          </div>
        </div>
        <div className="roleForm-body ">
          <div className="field">
            <label htmlFor="name">Dashboard(s)</label>
            <Select
              // defaultValue={[options[2], options[3]]}
              isMulti
              name="dashboard_role"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleFieldChangeForMultiSelector}
            />
          </div>
          {showError && errors.dashboard_role && (
            <small className="text-red-700	">{errors.dashboard_role}</small>
          )}
        </div>
        <div className="roleForm-body ">
          <div className="field">
            <label htmlFor="name">Name</label>
            <select
              className="w-full"
              onChange={handleFieldChange}
              name="user_name"
            >
              <option selected>Please Select</option>
              {options.map((data) => {
                return <option value={data.label}>{data.label}</option>;
              })}
            </select>
          </div>
          {showError && errors.user_name && (
            <small className="text-red-700	">{errors.user_name}</small>
          )}
        </div>
        <div className="roleForm-footer m-0 flex justify-end">
          <button
            type="submit"
            className="btn-submit  w-40"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
 */}

      <div className="max-w-3xl mx-auto shadow-lg mt-8 rounded-lg">
      <div className={FORM_ASSIGN_HEADING_WRAPPER}>
          <h3 className="font-bold text-base">Assign Roles To User</h3>
        </div>
        <div className="pt-8 px-8 xs:py-2 xs:px-5 ">
          <div className="field">
            <label
              htmlFor="name"
              className="font-bold text-base block mb-2"
            >
              Dashboard(s)
            </label>
            <Select
              // defaultValue={[options[2], options[3]]}
              isMulti
              name="dashboard_role"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleFieldChangeForMultiSelector}
            />
          </div>
          {showError && errors.dashboard_role && (
            <small className="text-red-700	">{errors.dashboard_role}</small>
          )}
        </div>
        <div className="pt-8 pb-14 px-8 border-b border-#ebedf3">
          <div>
            <label
              htmlFor="name"
              className="font-bold text-base block mb-2"
            >
              Name
            </label>
            <select
              className={FORM_ASSIGN_INPUT_FIELD}
              onChange={handleFieldChange}
              name="user_name"
            >
              <option selected>Please Select</option>
              {options.map((data) => {
                return <option value={data.label}>{data.label}</option>;
              })}
            </select>
          </div>
          {showError && errors.user_name && (
            <small className="text-red-700	">{errors.user_name}</small>
          )}
        </div>
        <div className="flex justify-end py-6 px-8 xs:p-4">
          <button
            type="submit"
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default AssignRolesToUsers;
