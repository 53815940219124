/** @format */
import url from "../core";
/** @format */
const apiUrl = `${url}/api`;
export function createUser(userData) {
  return new Promise(async (resolve) => {
    const { email, password } = userData;
    const response = await fetch(
      "https://erver-dun-beta.vercel.app/auth/signup",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      }
    );
    const data = await response.json();
    resolve({ data });
  });
}
export function addUser(userData) {
  return new Promise(async (resolve) => {
    const { reg_name, reg_email, reg_password } = userData;
    const response = await fetch(
      "https://server-beige-delta.vercel.app/api/user/add-user",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ reg_name, reg_email, reg_password }),
      }
    );
    const data = await response.json();
    resolve({ data });
  });
}
export function checkUser(loginInfo) {
  return new Promise(async (resolve, reject) => {
    const { login_email, login_password } = loginInfo;
    const response = await fetch(
      `https://server-beige-delta.vercel.app/api/login/user-auth`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ login_email, login_password }),
      }
    );
    const data = await response.json();
    if (data) {
      if (data?.status == 2000) {
        resolve({ data: data });
      } else {
        reject({ message: "wrong credentials" });
      }
    } else {
      reject({ message: "user not found" });
    }
  });
}
