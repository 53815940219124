/** @format */
import url from "../core";
/** @format */
const apiUrl = `${url}/api`;
export function createJob(jobData) {
  return new Promise(async (resolve) => {
    const { email, password } = jobData;
    const response = await fetch(
      "https://server-beige-delta.vercel.app/auth/signup",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      }
    );
    const data = await response.json();
    resolve({ data });
  });
}
export function createAddress(userData) {
  return new Promise(async (resolve) => {
    const { email, password } = userData;
    const response = await fetch(
      "https://server-beige-delta.vercel.app/auth/signup",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      }
    );
    const data = await response.json();
    resolve({ data });
  });
}
export function createCostCode(userData) {
  return new Promise(async (resolve) => {
    const { email, password } = userData;
    const response = await fetch(
      "https://server-beige-delta.vercel.app/auth/signup",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      }
    );
    const data = await response.json();
    resolve({ data });
  });
}
export function createContractIem(userData) {
  return new Promise(async (resolve) => {
    const { email, password } = userData;
    const response = await fetch(
      "https://server-beige-delta.vercel.app/auth/signup",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      }
    );
    const data = await response.json();
    resolve({ data });
  });
}
export function checkUser(loginInfo) {
  return new Promise(async (resolve, reject) => {
    const { login_email, login_password } = loginInfo;
    const response = await fetch(`${apiUrl}/login/user-auth`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ login_email, login_password }),
    });
    const data = await response.json();
    if (data) {
      if (data?.status == 2000) {
        resolve({ data: data });
      } else {
        reject({ message: "wrong credentials" });
      }
    } else {
      reject({ message: "user not found" });
    }
  });
}
