import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink, useLocation } from "react-router-dom";
import { navMenu } from "../../generals/menu";
import { useEffect, useState } from "react";

const Aside = ({ isOpen }) => {
  const location = useLocation();
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight);
  const tabFromRoute = location.pathname.split("/")[1];
  const tabFromRoutessss = location.pathname.split("/")[2];

  useEffect(() => {
    const handleResize = () => {
      setSidebarHeight(window.innerHeight);
    };

    const handleScroll = () => {
      setSidebarHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sidebarHeight]);
  return (
    <>
      <aside
        style={{ height: sidebarHeight }}
        className={` bg-white  py-1 border-r transition-all overflow-hidden  ${
          isOpen ? "w-24 aside" : "w-65"
        }`}
      >
        <div
          className={`h-16 bg-white sticky top-0 z-10 ${
            isOpen ? "px-2" : "px-4"
          }`}
        >
          <h2 className="font-extrabold text-lg bg-primary translate-y-2 py-3 px-1 rounded-xl w-full text-center text-white">
            {isOpen ? "CPNH" : "CPNHForms"}
          </h2>
        </div>
        <nav className="my-4">
          <ul  style={{ maxHeight: "calc(100vh - 70px)" }} className={`overflow-y-scroll ${isOpen ? "px-0" : "px-2"}`}>
            {navMenu.map((item) => (
              <li key={item.id} className="m-2 rounded-lg overflow-hidden">
                {item.child && item.child.length ? (
                  <Accordion
                    className={`shadow-none  ${
                      item.label.toLowerCase().replace(/\s+/g, "-") ===
                      tabFromRoute
                        ? " border rounded-lg"
                        : ""
                    }`}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          className={` ${
                            item.label.toLowerCase().replace(/\s+/g, "-") ===
                            tabFromRoute
                              ? "invert"
                              : ""
                          } `}
                        />
                      }
                      aria-controls="content"
                      id="header"
                      className={`shadow-none ${
                        item.label.toLowerCase().replace(/\s+/g, "-") ===
                        tabFromRoute
                          ? "bg-primary rounded-lg"
                          : ""
                      }`}
                    >
                      <>
                        {item.href ? (
                          <NavLink
                            to={item.href}
                            className="flex items-center gap-4 "
                          >
                            <img
                              src={item.icon}
                              width={20}
                              height={20}
                              className={`${
                                item.label
                                  .toLowerCase()
                                  .replace(/\s+/g, "-") === tabFromRoute
                                  ? "icon-white"
                                  : ""
                              }`}
                              alt={item.label}
                            />
                            <Typography
                              className={`text-base ${
                                item.label
                                  .toLowerCase()
                                  .replace(/\s+/g, "-") === tabFromRoute
                                  ? "text-white"
                                  : "text-secondary"
                              }`}
                            >
                              {item.label}
                            </Typography>
                          </NavLink>
                        ) : (
                          <div className="flex items-center gap-4 ">
                            <img
                              src={item.icon}
                              width={20}
                              height={20}
                              className={`${
                                item.label
                                  .toLowerCase()
                                  .replace(/\s+/g, "-") === tabFromRoute
                                  ? "icon-white"
                                  : ""
                              }`}
                              alt={item.label}
                            />
                            <Typography
                              className={`text-base ${
                                item.label
                                  .toLowerCase()
                                  .replace(/\s+/g, "-") === tabFromRoute
                                  ? "text-white"
                                  : "text-secondary"
                              }`}
                            >
                              {item.label === "View" ? "Views" : item.label}
                            </Typography>
                          </div>
                        )}
                      </>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.child.map((itemChild) => (
                        <NavLink
                          to={itemChild.href || "/"}
                          key={itemChild.id}
                          className={`flex items-center gap-2 my-0 shadow-none py-3 px-1 rounded-lg  ${
                            itemChild.labelChild
                              .toLowerCase()
                              .replace(/\s+/g, "-")
                              .toLowerCase() === tabFromRoutessss
                              ? "bg-primary opacity-90 text-white"
                              : ""
                          }`}
                        >
                          <img
                            src={itemChild.icon}
                            width={20}
                            height={20}
                            alt={itemChild.labelChild}
                            className={`${
                              itemChild.labelChild
                                .toLowerCase()
                                .replace(/\s+/g, "-")
                                .toLowerCase() === tabFromRoutessss
                                ? "icon-white"
                                : ""
                            }`}
                          />
                          <Typography
                            className={`text-base text-secondary ${
                              itemChild.labelChild
                                .toLowerCase()
                                .replace(/\s+/g, "-")
                                .toLowerCase() === tabFromRoutessss
                                ? "text-white"
                                : ""
                            }`}
                          >
                            {itemChild.labelChild}
                          </Typography>
                        </NavLink>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <NavLink
                    to={item.href || "/"}
                    className={`flex items-center gap-4 pl-4 my-2 h-12 ${
                      item.label.toLowerCase().replace(/\s+/g, "-") ===
                      tabFromRoute
                        ? "bg-primary rounded-lg"
                        : ""
                    }`}
                  >
                    <img
                      src={item.icon}
                      width={20}
                      height={20}
                      alt={item.label}
                      className={`${
                        item.label.toLowerCase().replace(/\s+/g, "-") ===
                        tabFromRoute
                          ? "icon-white"
                          : ""
                      }`}
                    />
                    <h3
                      className={`text-base ${
                        item.label.toLowerCase().replace(/\s+/g, "-") ===
                        tabFromRoute
                          ? "text-white"
                          : "text-secondary"
                      }`}
                    >
                      {item.label}
                    </h3>
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </nav>{" "}
      </aside>
    </>
  );
};

export default Aside;
