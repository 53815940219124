import React, { useState } from "react";
import { sideBar } from "../../components/generals/data.js.js";
import {
  BUTTON_CARD,
  BUTTON_CARD_LG,
  INPUT_TYPE_CHECKBOX,
} from "../../components/generals/common.js";
import { createAssignRole } from "./formState.js";
const CreateAdminRole = () => {
  const [fieldValues, setFieldValues] = useState(createAssignRole);
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  return (
    <div className="shadow-lg py-6 px-8 bg-white rounded-lg">
      <ul className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {sideBar?.map((data, idx) => {
          return (
            <li className="max-w-sm rounded overflow-hidden shadow-lg w-full mt-5">
              <div className="font-bold text-base px-6 py-3 bg-primary  text-white">
                {data.label}
              </div>
              <hr
                style={{
                  border: "none",
                  height: "1px",
                  color: "#dfe6e9",
                  backgroundColor: "#dfe6e9",
                }}
              />
              <div className="h-36 overflow-y-scroll">
                {data?.child?.map((data, idx) => {
                  return (
                    <ul className="px-5">
                      <li>
                        <tr>
                          <td>
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              name={data.label}
                              value={data.label}
                              className={INPUT_TYPE_CHECKBOX}
                              onChange={handleFieldChange}
                            />
                          </td>
                          <td>
                            <p className="text-secondary text-base  p-2">
                              {data.label}
                            </p>
                          </td>
                        </tr>
                      </li>
                    </ul>
                  );
                })}
              </div>
            </li>
          );
        })}
      </ul>
      <button className="bg-primary hover:bg-opacity-90 w-full py-4  text-white font-bold rounded-lg mt-5">
        Submit
      </button>
    </div>
  );
};

export default CreateAdminRole;
