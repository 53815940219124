/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { errorFormFieldsNotComplete } from "../generals/swalMessages";
import { createContractItemAsync } from "./jobSlice";
import {
  contractItemFields,
  validationOfContractItemFields,
} from "./formConfig";

const ContractItem = () => {
  const [fieldValues, setFieldValues] = useState(contractItemFields);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [initialValue, setInitialValue] = useState({ ...fieldValues });
  const loading = false;
  const dispatch = useDispatch();
  useEffect(() => {
    setErrors(validationOfContractItemFields(fieldValues));
  }, [fieldValues]);
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleSubmit = () => {
    setShowError(true);
    if (Object.keys(errors).length === 0) {
      // if (isEditMode) {
      //   updateMenuApi();
      // } else {
      // dispatch(createContractItemAsync(fieldValues));
      // }
    } else {
      errorFormFieldsNotComplete(
        `${"Invalid Field"}`,
        `${"Please enter required field"}`
      );
    }
  };
  return (
    <div className="shadow-lg mt-8 rounded-lg px-8 pt-6">ContractItem</div>
  );
};

export default ContractItem;
