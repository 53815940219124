export const roleAssigningUser = {
  user_name: "",
  dashboard_role: "",
};
export const roleAssigningAdmin = {
  admin_name: "",
  dashboard_role: "",
};
export const createRoleState = {
  role_name: "",
};
export const createAssignRole = {};

export const errorValidationForRoleAssigningUser = (values) => {
  let errors = {};
  if (!values.user_name) {
    errors.user_name = "asThis Field is Required";
  }
  if (!values.dashboard_role) {
    errors.dashboard_role = "This Field is Required";
  }

  return errors;
};
export const errorValidationForRoleCreateRoleState = (values) => {
  let errors = {};
  if (!values.role_name) {
    errors.role_name = "This Field is Required";
  }

  return errors;
};
export const errorValidationForRoleAssigningAdmin = (values) => {
  let errors = {};
  if (!values.admin_name) {
    errors.admin_name = "This Field is Required";
  }
  if (!values.dashboard_role) {
    errors.dashboard_role = "This Field is Required";
  }

  return errors;
};
