/** @format */
import React, { useEffect, useState } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import logo from "../../../src/assets/images/logo.png";
import emailIcon from "../../../src/assets/images/email_icon.svg";
import passIcon from "../../../src/assets/images/password_icon.svg";
import registerIcon from "../../../src/assets/images/register_icon.svg";
import loginIcon from "../../../src/assets/images/login_icon.svg";
import userIcon from "../../../src/assets/images/loginuser_icon.svg";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  errorValidationForForgotPassword,
  errorValidationForLoginUser,
  errorValidationForRegisterUser,
  userCredentials,
} from "./formConfig";
import Swal from "sweetalert2";
import Spinner from "../Spinner";
import {
  INPUT_FIELD_AUTH,
  INPUT_FIELD_WRAPPER,
  INPUT_WRAPPER,
  Input_Field,
  // PORT_LOCAL_5000,
  PORT_LOCAL_5001,
  PORT_PRODUCTION,
} from "../../components/generals/common";
import axios from "axios";
import url from "../../components/core";
import { checkUserAsync } from "./authSlice";
import { addUserAsync } from "./registerUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectError, selectLoggedInUser } from "./authSlice";
import { useNavigate } from "react-router-dom";
const Auth = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isForget, setIsForget] = useState(false);
  const [fieldValues, setFieldValues] = useState(userCredentials);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [initialState, setInitialState] = useState({ ...fieldValues });
  const dispatch = useDispatch();
  const { loading, loggedInUser, error } = useSelector((state) => state?.user);
  const { registerUser, loadingRegister, errorRegister } = useSelector(
    (state) => state?.registerUser
  );
  const navigate = useNavigate();
  if (loggedInUser != null && loggedInUser?.status == 2000) {
    navigate("/dashboard");
  } else if (registerUser != null && registerUser?.status == 40002) {
    // setIsLogin(true);
  }
  useEffect(() => {
    if (error != null) {
      Swal.fire({
        title: "Something went wrong",
        text: ` ${error?.message}`,
        icon: "error",
        confirmButtonColor: "#fe1626",
        confirmButtonText: "OK",
      });
    }
  }, [loggedInUser]);
  console.log("🚀 ~ Auth ~ loggedInUser:", loggedInUser);
  useEffect(() => {
    if (registerUser?.status == 40002) {
      Swal.fire({
        title: "Added Successfully",
        text: ` ${registerUser?.message}`,
        icon: "success",
        confirmButtonColor: "#fe1626",
        confirmButtonText: "OK",
      });
      setIsForget(false);
      setIsRegister(false);
      setIsLogin(true);
      setFieldValues({ ...initialState });
    } else if (registerUser?.status == 40001) {
      Swal.fire({
        title: "Ops!",
        text: ` ${registerUser?.message}`,
        icon: "error",
        confirmButtonColor: "#fe1626",
        confirmButtonText: "OK",
      });
      setIsForget(false);
      setIsRegister(false);
      setIsLogin(true);
      setFieldValues({ ...initialState });
    } else if (errorRegister !== null) {
      Swal.fire({
        title: "Something went wrong",
        text: ` ${error?.message}`,
        icon: "error",
        confirmButtonColor: "#fe1626",
        confirmButtonText: "OK",
      });
    }
  }, [registerUser]);
  useEffect(() => {
    if (isLogin) {
      setErrors(errorValidationForLoginUser(fieldValues));
    } else if (isRegister) {
      setErrors(errorValidationForRegisterUser(fieldValues));
    } else if (isForget) {
      setErrors(errorValidationForForgotPassword(fieldValues));
    }
  }, [isRegister, isLogin, isForget]);
  const handleForgot = () => {
    setIsRegister(false);
    setIsLogin(false);
    setIsForget(true);
  };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setFieldValues({
      ...fieldValues,
      [name]: value,
    });
  };
  const handleLogin = () => {
    setIsForget(false);
    setIsRegister(false);
    setIsLogin(true);
  };
  const handleRegister = () => {
    setIsForget(false);
    setIsLogin(false);
    setIsRegister(true);
  };
  const handleSubmit = () => {
    if (isRegister) {
      // registerUser();
      let payload = {
        reg_name: fieldValues.reg_name,
        reg_email: fieldValues.reg_email,
        reg_password: fieldValues.reg_password,
      };
      dispatch(addUserAsync(payload));
    } else if (isLogin) {
      // logInAPI();
      let payload = {
        login_email: fieldValues.login_email,
        login_password: fieldValues.login_password,
      };
      dispatch(checkUserAsync(payload));
    } else if (isForget) {
    }
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      {loadingRegister || loading ? <Spinner /> : null}
      <div
        className="bg-lightRed bg-opacity-10"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Container
          className="shadow-lg max-w-xl xs:w-11/12 bg-white rounded-2xl py-8"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <img src={logo} alt="logo" className="xs:w-32 w-48" />
          </div>
          <div>
            <Typography
              variant="h2"
              className="text-3xl mt-8 mb-4 xs:mt-4 font-bold text-primary text-center xs:text-2xl"
            >
              {isRegister
                ? "Registration Into Dashboard"
                : isForget
                ? "Forgot Password"
                : " Login Into Dashboard"}
            </Typography>
            <Typography
              variant="body1"
              className="text-sm mt-2 mb-6 text-center text-secondary"
            >
              Please enter the following information for login
            </Typography>
          </div>
          {isLogin ? (
            <div className={INPUT_WRAPPER}>
              <div className={INPUT_FIELD_WRAPPER}>
                <img
                  src={emailIcon}
                  alt="email"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "1",
                    left: "10px",
                  }}
                />
                <input
                  type="email"
                  className={INPUT_FIELD_AUTH}
                  placeholder="email"
                  name="login_email"
                  value={fieldValues.login_email}
                  onChange={handleFieldChange}
                  style={{
                    color: "#000",
                    paddingLeft: "3em",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className={INPUT_FIELD_WRAPPER}>
                <img
                  src={passIcon}
                  alt="password"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "1",
                    left: "10px",
                  }}
                />
                <input
                  type={showPassword ? "text" : "password"}
                  className={INPUT_FIELD_AUTH}
                  placeholder="Password"
                  name="login_password"
                  value={fieldValues.login_password}
                  onChange={handleFieldChange}
                  style={{
                    color: "#000",
                    paddingLeft: "3em",
                    borderRadius: "5px",
                  }}
                />
                <FontAwesomeIcon
                  className="absolute right-4 fas fa-trash ms-2 mouseOverLink  fafaIconSize"
                  title="Delete"
                  onClick={handleShowPassword}
                  // toggle faEye
                  icon={showPassword ? faEye : faEyeSlash}
                />
              </div>
            </div>
          ) : null}
          {isRegister && (
            <div className={INPUT_WRAPPER}>
              <div className={INPUT_FIELD_WRAPPER}>
                {" "}
                <img
                  src={userIcon}
                  alt="user"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "1",
                    left: "10px",
                  }}
                />
                <input
                  type="text"
                  className={INPUT_FIELD_AUTH}
                  placeholder="Name"
                  name="reg_name"
                  value={fieldValues.reg_name}
                  onChange={handleFieldChange}
                  style={{
                    color: "#000",
                    paddingLeft: "3em",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className={INPUT_FIELD_WRAPPER}>
                <img
                  src={emailIcon}
                  alt="email"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "1",
                    left: "10px",
                  }}
                />
                <input
                  type="email"
                  className={INPUT_FIELD_AUTH}
                  placeholder="Email"
                  name="reg_email"
                  value={fieldValues.reg_email}
                  onChange={handleFieldChange}
                  style={{
                    color: "#000",
                    paddingLeft: "3em",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className={INPUT_FIELD_WRAPPER}>
                <img
                  src={passIcon}
                  alt="password"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "1",
                    left: "10px",
                  }}
                />
                <input
                  type={showPassword ? "text" : "password"}
                  className={INPUT_FIELD_AUTH}
                  placeholder="Password"
                  name="reg_password"
                  value={fieldValues.reg_password}
                  onChange={handleFieldChange}
                  style={{
                    color: "#000",
                    paddingLeft: "3em",
                    borderRadius: "5px",
                  }}
                />
                <FontAwesomeIcon
                  className="absolute right-4 fas fa-trash ms-2 mouseOverLink  fafaIconSize"
                  title="Delete"
                  onClick={handleShowPassword}
                  // toggle faEye
                  icon={showPassword ? faEye : faEyeSlash}
                />
              </div>
            </div>
          )}
          {isForget && (
            <div className={INPUT_WRAPPER}>
              <div className={INPUT_FIELD_WRAPPER}>
                <img
                  src={emailIcon}
                  alt="email"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "1",
                    left: "10px",
                  }}
                />
                <input
                  type="email"
                  className={INPUT_FIELD_AUTH}
                  placeholder="Enter Email"
                  name="forgot_email"
                  value={fieldValues.forgot_email}
                  onChange={handleFieldChange}
                  style={{
                    color: "#000",
                    paddingLeft: "3em",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </div>
          )}
          {isRegister && (
            <div className={INPUT_WRAPPER}>
              <div className={INPUT_FIELD_WRAPPER}>
                <img
                  src={passIcon}
                  alt="password"
                  style={{
                    width: "20px",
                    height: "20px",
                    position: "absolute",
                    zIndex: "1",
                    left: "10px",
                  }}
                />
                <input
                  type="password"
                  className={INPUT_FIELD_AUTH}
                  placeholder="Confirm Password"
                  name="reg_confirm_password"
                  value={fieldValues.reg_confirm_password}
                  onChange={handleFieldChange}
                  style={{
                    color: "#000",
                    paddingLeft: "3em",
                    borderRadius: "5px",
                  }}
                />
              </div>
            </div>
          )}
          <Box
            className="w-11/12"
            marginTop={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
              className="w-full text-center"
              style={{
                background: "linear-gradient(to right, #EF4444, #f12)",
                display: "flex",
                gap: "10px",
                padding: "12px 42px",
              }}
            >
              <img src={loginIcon} alt="email" width={20} height={20} />
              Submit
            </Button>
          </Box>
          <div
            className="w-11/12"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 20,
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              className="w-3/6 xs:w-3/6 "
              onClick={handleRegister}
              style={{
                background: "linear-gradient(to right, #4B5563, #1F2937)",
                display: "flex",
                gap: "10px",
                padding: "10px 20px",
              }}
            >
              <img src={registerIcon} alt="email" width={20} height={20} />
              REGISTER
            </Button>
            <Button
              // onClick={() => router.push("/dashboard")}
              onClick={handleLogin}
              variant="contained"
              className="w-3/6 xs:w-3/6 "
              style={{
                background: "linear-gradient(to right, #DC2626, #EF4444)",
                display: "flex",
                gap: "10px",
                padding: "10px 20px",
              }}
            >
              <img src={loginIcon} alt="email" width={20} height={20} />
              LOGIN
            </Button>
          </div>
          <Typography
            onClick={handleForgot}
            variant="body1"
            className="w-11/12 text-base mt-6 cursor-pointer font-extrabold text-left  text-primary"
          >
            Forgot Password?
          </Typography>
        </Container>
      </div>
    </>
  );
};
export default Auth;
