/** @format */
export const jobFields = {
  job_no: "",
  job_name: "",
  company: "",
  WCB: "",
  job: "",
  zone: "",
  start_date: "",
  end_date: "",
  job_type: "",
  location: "N/A",
  job_name: "",
};
export const validationOfJobFields = (values) => {
  let errors = {};
  if (values.job_no == "") {
    errors.job_no = "This field is Required";
  } else if (values.job_no.length !== 9) {
    errors.job_no = "Must be 9 Digits";
  }
  if (values.job_name == "") {
    errors.job_name = "This field is Required";
  }
  if (values.company == "" || values.company == "0") {
    errors.company = "This field is Required";
  }
  // if (values.WCB == "" || values.WCB == 0) {
  //   errors.WCB = "This field is Required";
  // }
  if (values.zone == "" || values.zone == 0) {
    errors.zone = "This field is Required";
  }
  if (values.start_date == "") {
    errors.start_date = "This field is Required";
  }
  if (values.end_date == "") {
    errors.end_date = "This field is Required";
  }
  if (values.end_date < values.start_date) {
    errors.end_date = "End date cannot be past to start date";
  }
  if (values.job_type == "" || values.job_type == 0) {
    errors.job_type = "This field is Required";
  }

  if (values.job_name == "") {
    errors.job_name = "This field is Required";
  }
  return errors;
};
export const addressFields = {
  company_name: "",
  address_code: "",
  address_line: "",
  contact_number: "",
  zip_code: "",
  country: "USA",
  state: "",
  city: "",
};
export const validationOfAddressFields = (values) => {
  let errors = {};
  if (values.address_code == "" || values.address_code == 0) {
    errors.address_code = "This field is Required";
  }
  if (values.contact_number == "") {
    errors.contact_number = "This field is Required";
  }
  if (values.contact_number != "") {
    if (values.contact_number.length < 10) {
      errors.contact_number = "Invalid Phone Number(e.g (555) 555-1234)";
    }
  }
  if (values.state == "" || values.state == 0) {
    errors.state = "This field is Required";
  }
  if (values.city == "" || values.city == 0) {
    errors.city = "This field is Required";
  }
  if (values.zip_code == "" || values.zip_code == 0) {
    errors.zip_code = "This field is Required";
  }
  return errors;
};
export const costFields = {
  cost_code: "",
};
export const validationOfCostField = (values) => {
  let errors = {};
  if (values.cost_code == "") {
    errors.cost_code = "This field is Required";
  }
  return errors;
};
export const contractItemFields = {
  company_name: "",
  address_code: "",
  address_line: "",
  contact_number: "",
  zip_code: "",
  zone: "",
  country: "",
  city: "",
};
export const validationOfContractItemFields = (values) => {
  let errors = {};
  if (values.cost_code == "" || values.cost_code == "0") {
    errors.cost_code = "This field is Required";
  }
  return errors;
};
