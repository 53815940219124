/** @format */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  FORM_ASSIGN_SUBMIT_BTN,
  UPLOAD_BTN,
  customStylesForDatatable,
  formatTimestampToDate,
} from "../generals/common";
import Spinner from "../Spinner";
import uploadImage from "../../../src/assets/images/fetchJobsSVG.svg";
import { useNavigate } from "react-router-dom";
import {
  errorFormFieldsNotComplete,
  errorSwal,
} from "../generals/swalMessages";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import TransitionsModal from "../modal/TransitionsModal";
const Form = () => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [formList, setFormList] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const getFormList = async () => {
    setLoading(true);
    const authAxios = Axios.create({
      baseURL: "https://server-beige-delta.vercel.app/api/form/get-form-list",
      headers: {
        // "api-token": `Bearer ${currentValue && currentValue.token}`,
      },
    });
    await authAxios
      .get("https://server-beige-delta.vercel.app/api/form/get-form-list")
      .then((response) => {
        let msg = response?.data?.message;
        if (response?.data?.status === 20023) {
          setFormList(response.data.foundForm);
        } else if (
          response?.data?.status == 404 ||
          response?.data?.status == 405
        ) {
          errorSwal(response?.data?.message);
        } else {
          errorFormFieldsNotComplete(`${"Error"}`, msg);
        }
      });
    setLoading(false);
  };
  useEffect(() => {
    getFormList();
  }, []);

  const handleView = (row) => {
    setshowModal(true);
    setRowData(row);
  };

  const handleRoute = (id) => {
    navigate(`/form/form-align/${id}`);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row._id,
    },
    {
      name: "Form Code",
      selector: (row) => row.formCode,
    },
    {
      name: "Job No",
      selector: (row) => row.jobNo,
    },
    {
      name: "Company",
      selector: (row) => row.company,
    },
    {
      name: "Created At",
      selector: (row) => formatTimestampToDate(row.createdAt),
    },
    {
      name: "Actions",
      selector: (row) => (
        <tr>
          <td>
            <FontAwesomeIcon
              className="fas fa-edit ms-2 mouseOverLink  fafaIconSize"
              title="Edit"
              onClick={() => handleRoute(row._id)}
              icon={faEdit}
            />
          </td>
          <td>
            <FontAwesomeIcon
              className="fas fa-trash ms-2 mouseOverLink  fafaIconSize"
              title="Delete"
              // onClick={() => handleDeleteReport(row)}
              icon={faTrash}
            />
          </td>
          <td>
            <FontAwesomeIcon
              className="fas fa-trash ms-2 mouseOverLink  fafaIconSize"
              title="View"
              onClick={() => handleView(row._id)}
              icon={faEye}
            />
          </td>{" "}
          {/* <td>
            <TransitionsModal data={row} title="" />
          </td> */}
        </tr>
      ),
      width: "10%",
    },
  ];

  return (
    <div className="max-w-6xl p-8 xs:p-6 mx-auto shadow-lg mt-8 rounded-lg">
      {loading ? <Spinner /> : null}
      {showModal ? (
        <TransitionsModal
          data={rowData}
          showModal={showModal}
          setshowModal={setshowModal}
          title=""
        />
      ) : null}
      <div className="flex gap-6 items-center mb-8 flex-wrap xs:gap-2 xs:justify-between ">
        <div className="text-secondary text-xl lg:text-2xl font-bold xs:text-lg">
          Forms
        </div>
        <div className="flex items-end  flex-col gap-6 ml-auto  w-80 xs:w-full  mr-2">
          <form className="xs:w-full">
            <label
              for="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative ">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="xs:w-full xs:h-12 xs:py-0 block w-64 p-4 ps-10 text-sm text-secondary  border-primary rounded-lg focus:outline-primary bg-white   focus:border-lightRed border-2 dark:placeholder-gray-40 "
                placeholder="Search..."
                // onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </form>
          <button
            className={FORM_ASSIGN_SUBMIT_BTN}
            onClick={() => navigate("/form/form-align")}
          >
            Create
          </button>
        </div>
      </div>
      <div className="shadow-lg dataTable overflow-scroll ">
        <DataTable
          columns={columns}
          // data={excelData}
          data={formList}
          customStyles={customStylesForDatatable}
          // data={searchParam(excelData)}
          selectableRows
          pagination
          // onSelectedRowsChange={handleChange}
        />
      </div>
      <label
        for="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
    </div>
  );
};
export default Form;
