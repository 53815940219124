import React from 'react';

const DashboardCard = ({
  title,
  subtitle,
  children,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
}) => {
  return (
    <div style={{ padding: 0 }} className="MuiPaper-root" elevation={9} variant={undefined}>
      {cardheading ? (
        <div>
          <h5>{headtitle}</h5>
          <p style={{ color: 'textSecondary' }}>{headsubtitle}</p>
        </div>
      ) : (
        <div style={{ padding: '30px' }}>
          {title ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '3px',
              }}
            >
              <div>
                {title ? <h5>{title}</h5> : null}

                {subtitle ? (
                  <p style={{ color: 'textSecondary' }}>{subtitle}</p>
                ) : null}
              </div>
              {action}
            </div>
          ) : null}

          {children}
        </div>
      )}

      {middlecontent}
      {footer}
    </div>
  );
};

export default DashboardCard;
